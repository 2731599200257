import { useState } from 'react';

import { MultiselectClass } from '../../../../utils/filter/multiselect-class';
import { ITransferColumnData, ITransferMap } from './transfer-types';

import AppTransferListSide from './AppTransferListSide';

export const structureMap: ITransferMap = {
  grouping: {
    sGroupName: 'title',
    items: 'items',
    key: '@key',
  },
  items: {
    value: 'value',
    title: 'fullName',
    key: '@key',
  },
};

interface ITransferListProps {
  options: Record<string, { title?: string; items?: Record<string, any> }>;
  value: number[];
  onChange: any;
  optionsMap?: ITransferMap;
}

const AppTransferList = ({ value, onChange, options, optionsMap }: ITransferListProps) => {
  const [leftQuery, setLeftQuery] = useState('');
  const [rightQuery, setRightQuery] = useState('');
  const transferForm = new MultiselectClass(options || {}, optionsMap ?? structureMap);
  const leftColumnData: ITransferColumnData[] = transferForm.getLeftColumnMap(leftQuery, value);
  const rightColumnData: ITransferColumnData[] = transferForm.getRightColumnMap(rightQuery, value);

  const addItemHandler = (id: number) => {
    const newValue = [...value, id];
    onChange(newValue);
  };

  const removeItemHandler = (id: number) => {
    const newValue = value.filter((el: number) => el !== id);
    onChange(newValue);
  };
  const addGroupHandler = (key: string) => {
    const newValue = transferForm.selectLeftGrouping(key, value);
    onChange(newValue);
  };

  const removeGroupHandler = (key: string) => {
    const newValue = transferForm.selectRightGrouping(key, value);
    onChange(newValue);
  };

  const selectAllItems = () => {
    const newValue = transferForm.selectAll().map(Number);
    onChange(newValue);
  };

  const removeAllItemsHandler = () => {
    onChange([]);
  };

  return (
    <div className="row">
      <AppTransferListSide
        query={leftQuery}
        setQuery={setLeftQuery}
        columnData={leftColumnData}
        groupHandler={addGroupHandler}
        itemHandler={addItemHandler}
        allItemHandler={selectAllItems}
        buttonTitle="Выбрать всё"
      />
      <AppTransferListSide
        query={rightQuery}
        setQuery={setRightQuery}
        columnData={rightColumnData}
        groupHandler={removeGroupHandler}
        itemHandler={removeItemHandler}
        allItemHandler={removeAllItemsHandler}
        buttonTitle="Сбросить"
      />
    </div>
  );
};

export default AppTransferList;
