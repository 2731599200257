import { IAppDropdownGroupOption, IAppDropdownOption } from '../app_dropdown.types';
import { IDropdownOption } from '../../../../../store/filter/FilterOptionsStore';

export const useDropdownOptions = (
  options: IDropdownOption[] | IAppDropdownGroupOption[],
  groupOptions?: boolean,
) => {
  const initialOptions = groupOptions
    ? (options as IAppDropdownGroupOption[]).flatMap((groupOption) => groupOption.items)
    : (options as IAppDropdownOption[]);
  const groupInitialOptions = groupOptions ? (options as IAppDropdownGroupOption[]) : [];

  return { initialOptions, groupInitialOptions };
};
