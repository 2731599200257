import { useEffect } from 'react';

export const useDebounceEffect = (
  callback: (prop?: any) => void,
  timer: number,
  dependencies: any,
) => {
  useEffect(() => {
    const debounce = setTimeout(() => callback(), timer);

    return () => {
      clearTimeout(debounce);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(dependencies || []), timer]);
};
