import React from 'react';

export const handleMouseScroll = (e: React.MouseEvent<HTMLDivElement>) => {
  const { clientY } = e; // Позиция курсора по вертикали
  const windowHeight = window.innerHeight;

  // Задать пороги для прокрутки
  const scrollThreshold = 80; // пиксели от верхнего и нижнего краев экрана

  // Прокрутка вверх
  if (clientY < scrollThreshold) {
    window.scrollBy(0, -15); // Прокрутка вверх на 15 пикселей
  }
  // Прокрутка вниз
  else if (clientY > windowHeight - scrollThreshold) {
    window.scrollBy(0, 15); // Прокрутка вниз на 15 пикселей
  }
};
