import { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';

import { DatepickerContext } from '../datepicker-context/DatepickerContext';

import AppButton from '../../../buttons/appButton/AppButton';

const DatepickerButtonsBar = () => {
  const { timeFormat, momentValue } = useContext(DatepickerContext);
  const { setDateValues, setIsMenuOpen, setNowDate } = useContext(DatepickerContext);

  const handleStartOrEndDayTime = (type: 'start' | 'end') => {
    const hours = type === 'start' ? 0 : 23;
    const minutes = type === 'start' ? 0 : 59;
    const seconds = type === 'start' ? 0 : 59;

    const currentTime = momentValue?.clone().set({ hours, minutes, seconds }) || null;

    setDateValues(currentTime);
  };

  const handleRemoveDate = () => {
    setDateValues(null);
    setIsMenuOpen(false);
  };

  return (
    <div className="d-flex align-items-center justify-content-between">
      <AppButton variant="outline-primary" className="border-0" onClick={setNowDate}>
        Сегодня
      </AppButton>

      {timeFormat && (
        <Dropdown>
          <Dropdown.Toggle className="border-0 rounded-1" variant="outline-secondary">
            Выбрать
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleStartOrEndDayTime('start')}>
              Начало дня
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleStartOrEndDayTime('end')}>Конец дня</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}

      <AppButton variant="outline-danger" className="border-0" onClick={handleRemoveDate}>
        Сбросить
      </AppButton>
    </div>
  );
};

export default DatepickerButtonsBar;
