import { observer } from 'mobx-react-lite';
import { MdPerson } from 'react-icons/md';
import { LuClock } from 'react-icons/lu';

import { taskStore } from '../../../../store/tasks/TaskStore';
import { convertFromResponseDate } from '../../../../utils/format-date/convertFromResponseDate';

import TaskCardFileList from '../card-components/TaskCardFileList';

const TaskCardCommentList = observer(() => {
  const { taskCardData } = taskStore;

  return (
    <div className="d-flex flex-column align-items-start gap-2">
      {taskCardData?.cardData.commentData &&
        taskCardData.cardData.commentData.map((comment) => (
          <div key={comment.id} className="border border-primary rounded-1 p-2 w-100">
            <div className="d-flex flex-wrap align-items-center justify-content-between gap-1 mb-1">
              <div className="d-flex align-items-center gap-1">
                <MdPerson className="fs-5 text-primary" />
                <span className="text-primary fw-bold me-5">{comment.manager.fullName}</span>
              </div>

              <div className="d-flex align-items-center gap-1">
                <LuClock className="fs-5" />
                <span className="text-muted">{convertFromResponseDate(comment.date_add)}</span>
              </div>
            </div>

            <div className="d-flex flex-wrap align-items-center justify-content-between gap-1">
              {comment.text_data}

              <TaskCardFileList filesArray={comment.comment_files} />
            </div>
          </div>
        ))}
    </div>
  );
});

export default TaskCardCommentList;
