export const getAttendanceCellData = (cell: Element) => {
  const cellRowIndex = cell.getAttribute('data-rowindex');
  const cellDate = cell.getAttribute('data-date');
  const cellFullDate = cell.getAttribute('date-fulldate');

  const rowIndex = cellRowIndex ? Number(cellRowIndex) : 0;
  const date = cellDate ? Number(cellDate) : 0;
  const fullDate = cellFullDate ? cellFullDate : '';

  return { rowIndex, date, cell, fullDate };
};
