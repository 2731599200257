import { attendanceStore } from '../../../../../store/attendanceStore/AttendanceStore';
import { observer } from 'mobx-react-lite';
import AttendanceMobileMenu from './AttendanceMobileMenu';
import AttendanceMobileItem from './AttendanceMobileItem';

// const ROOT_MARGIN = '200px 0px';

const AttendanceMobileBody = observer(() => {
  const { attendanceDepartments } = attendanceStore;

  return (
    <div>
      <AttendanceMobileMenu />

      <div style={{ userSelect: 'none' }}>
        {Object.values(attendanceDepartments ?? {}).map((department) => (
          <div key={department.title}>
            <h5 className="att__table-department border bg-secondary-subtle">{department.title}</h5>
            <div className="d-flex flex-column gap-1 my-1">
              {department.items.map((employee) => (
                <AttendanceMobileItem employee={employee} key={employee.id} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

export default AttendanceMobileBody;
