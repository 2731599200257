import { SocketApi } from '../socketApi';
import { ETaskSocketKey } from '../../../components/tasks/task-types';
import { taskStore } from '../../../store/tasks/TaskStore';

export enum ESetTaskDataAction {
  setDeadLine = 'setDeadLine',
  setExecutor = 'setExecutor',
  setObserver = 'setObserver',
  setComment = 'setComment',
  changeStatus = 'changeStatus',
}

export interface ISetTaskDeadLineData {
  taskId: number;
  dateDeadLine: string;
}

export interface ISetTaskExecutor {
  taskId: number;
  executorId: number[];
  statusId: 1;
}

export interface ISetTaskComment {
  taskId: number;
  taskComment: string;
  taskFile?: string;
  taskFiles: string[];
}

export interface IChangeTaskStatus {
  taskId: number;
  // prevStatus: number;
  statusId: number;
}

export interface ISetTaskDataProps<T extends ESetTaskDataAction> {
  action: T;
  data: T extends ESetTaskDataAction.setExecutor
    ? ISetTaskExecutor
    : T extends ESetTaskDataAction.setObserver
      ? ISetTaskExecutor
      : T extends ESetTaskDataAction.setDeadLine
        ? ISetTaskDeadLineData
        : T extends ESetTaskDataAction.setComment
          ? ISetTaskComment
          : T extends ESetTaskDataAction.changeStatus
            ? IChangeTaskStatus
            : never;
}

export const setTaskData = <T extends ESetTaskDataAction>({
  action,
  data,
}: ISetTaskDataProps<T>) => {
  SocketApi.getClient()?.emit(ETaskSocketKey.setTaskData, { action, data }, (response: any) => {
    console.log(response);

    if (action === ESetTaskDataAction.setExecutor) {
      taskStore.addExecutors(response);
    }

    if (action === ESetTaskDataAction.setObserver) {
      taskStore.addObserver(response);
    }

    if (action === ESetTaskDataAction.setDeadLine) {
      taskStore.updateDeadLine(response);
    }

    if (action === ESetTaskDataAction.setComment) {
      taskStore.addNewComment(response);
    }

    if (action === ESetTaskDataAction.changeStatus) {
      taskStore.updateExecutorStatus(response);
    }

    taskStore.setIsCardLoading(false);
  });
};
