import { makeAutoObservable } from 'mobx';

class SocketApiStore {
  isSocketConnectError: boolean = false;
  isSocketLoaded: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  public setIsSocketConnectError(value: boolean) {
    this.isSocketConnectError = value;
  }

  public setSocketStatus(value: boolean) {
    this.isSocketLoaded = value;
  }
}

export const socketApiStore = new SocketApiStore();
