import { useContext } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';

import { EDatepickerChangeType } from '../datepicker.types';

import { DatepickerContext } from '../datepicker-context/DatepickerContext';

import AppButton from '../../../buttons/appButton/AppButton';

import styles from '../AppDatepicker.module.scss';

const DatepickerYearButtons = () => {
  const { momentValue, setMomentValue, yearPickerShowToggle } = useContext(DatepickerContext);
  const changeYearHandler = (type: EDatepickerChangeType) => {
    const operation = type === EDatepickerChangeType.increase ? 1 : -1;
    const newYear = momentValue?.clone().add(operation, 'year') || null;

    setMomentValue(newYear);
  };

  return (
    <div className={styles.buttons__wrapper}>
      <AppButton
        variant="outline-dark"
        className="border-0"
        onClick={() => changeYearHandler(EDatepickerChangeType.decrease)}
      >
        <FaAngleLeft />
      </AppButton>
      <AppButton variant="outline-secondary" className="border-0" onClick={yearPickerShowToggle}>
        <span className={styles.calendar__title}>{momentValue?.format('YYYY')}</span>
      </AppButton>
      <AppButton
        variant="outline-dark"
        className="border-0"
        onClick={() => changeYearHandler(EDatepickerChangeType.increase)}
      >
        <FaAngleRight />
      </AppButton>
    </div>
  );
};

export default DatepickerYearButtons;
