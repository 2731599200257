import { observer } from 'mobx-react-lite';

const TaskInfo = observer(() => {
  // const { taskList } = taskStore;
  return (
    <div>{/*<pre style={{ maxWidth: '700px' }}>{JSON.stringify(taskList, null, 2)}</pre>*/}</div>
  );
});

export default TaskInfo;
