import { io, Socket } from 'socket.io-client';

import { getAuthTokens, isTokensExist } from '../apiClient';
import { socketApiStore } from '../../store/socketApiStore/SocketApiStore';
import { WS_DEFAULT_URL } from '../api.constants';
// import { WS_NODE_URL } from '../api.constants';

const registry = new FinalizationRegistry((heldValue: { obj: SocketApiClient }) => {
  heldValue.obj.disconnectClient();
});

class SocketApiClient {
  static instance: SocketApiClient;
  protected socketConnection: Socket | null = null;

  constructor() {
    if (SocketApiClient.instance) {
      return SocketApiClient.instance;
    }
    SocketApiClient.instance = this;
    // this.reconnectToServer();
    registry.register(this, { obj: this });
  }

  public reconnectToServer() {
    const isActiveTab = !document.hidden;

    const uri = WS_DEFAULT_URL;
    // const uri = WS_NODE_URL;
    const path = '/gateway/v1/events/';

    if (this.socketConnection) {
      this.disconnectClient();
    }

    if (isTokensExist()) {
      const rIoClient = io(uri, {
        path,
        auth: { ...getAuthTokens(), isActiveTab },
        autoConnect: true,
        forceNew: false,
        upgrade: true,
        multiplex: true,
        transports: ['websocket', 'polling'],
        rememberUpgrade: true,
      });

      rIoClient.on('connect', () => {
        this.socketConnection = rIoClient;
        socketApiStore.setSocketStatus(true);
        socketApiStore.setIsSocketConnectError(false);
      });

      rIoClient.on('disconnect', () => {
        socketApiStore.setSocketStatus(false);
      });

      rIoClient.on('connect_error', () => {
        console.error('Попытка переподключения сокетов');
        socketApiStore.setSocketStatus(false);
        socketApiStore.setIsSocketConnectError(true);
      });
    }
  }

  public disconnectClient() {
    if (this.socketConnection) {
      this.socketConnection.close();
      socketApiStore.setSocketStatus(false);
      this.socketConnection = null;
    }
  }

  public getClient(): Socket | null {
    return this.socketConnection;
  }
}

export const SocketApi = new SocketApiClient();
