import { MAX_SIZE_REQUEST } from './attendance-constants';

export const getChunkRequestSize = (requestArray: any[]) => {
  if (requestArray && requestArray.length > 0) {
    const requestItemSize = JSON.stringify(requestArray[0]).length;
    const requestDataSize = Math.ceil(
      (requestItemSize * requestArray.length * 1.1) / MAX_SIZE_REQUEST,
    );

    return Math.round(requestArray.length / requestDataSize) + 1;
  } else return 0;
};
