import { EFinanceTabKeys, ICompanyFinanceTabs } from '../components/company/types/company.types';

export const companyFinanceTabs: ICompanyFinanceTabs = {
  gantTable: {
    eventKey: EFinanceTabKeys.gantTable,
    title: 'Взаиморасчёты',
    icon: '',
  },
  salesCalendar: {
    eventKey: EFinanceTabKeys.salesCalendar,
    title: 'Платёжный календарь',
    icon: '',
  },
  commonDocsList: {
    eventKey: EFinanceTabKeys.commonDocsList,
    title: 'Документы 1С',
    icon: '',
  },
  clientSalesList: {
    eventKey: EFinanceTabKeys.clientSalesList,
    title: 'Реализации',
    icon: '',
  },
};
