import AppButton from './appButton/AppButton';
import { GrClose } from 'react-icons/gr';
import { modalStore } from '../../../store/modal/ModalStore';

const CloseModalButton = () => {
  const closeHandler = () => modalStore.closeModal();

  return (
    <AppButton variant="outline-dark" className="border-0 p-2" onClick={closeHandler}>
      <GrClose />
    </AppButton>
  );
};

export default CloseModalButton;
