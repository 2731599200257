import { Card, ListGroup } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { IPageConfig } from '../../api/requests/pagesRequest';
import { useSetCurrentPage } from '../../hooks/useSetCurrentPage';
import { userStore } from '../../store/userStore/UserStore';

const ServicesPage = observer(
  ({ config, params }: { config?: IPageConfig; params?: Record<string, any> }) => {
    const { pages } = userStore;
    const pagesList = Object.values(pages ?? {});
    useSetCurrentPage(config);

    const renderCurrentLink = (page: IPageConfig) => {
      return (
        <ListGroup>
          {pagesList
            .filter((value) => value.sParentUid === page.sUid)
            .map((page) => (
              <Link
                key={page.sUid}
                to={page.sUri}
                className="text-decoration-none text-dark list-group-item list-group-item-action rounded-1"
              >
                {page.sPageTitle}
              </Link>
            ))}
        </ListGroup>
      );
    };

    return (
      <>
        {params &&
          pagesList
            .filter(
              (page) =>
                (params.level === 'double' && config?.sUid === page.sParentUid) ||
                (params.level === 'single' && config?.sUid === page.sUid),
            )
            .map((page) => (
              <Card key={page.sUid} className="mb-2 rounded-1">
                <Card.Header>
                  <h4 className="text-primary">{page.sPageTitle}</h4>
                </Card.Header>
                <Card.Body>{renderCurrentLink(page)}</Card.Body>
              </Card>
            ))}
      </>
    );
  },
);

export default ServicesPage;
