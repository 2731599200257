import { useEffect } from 'react';

import { SocketApi } from '../socketApi';
import { attendanceStore } from '../../../store/attendanceStore/AttendanceStore';
import { socketApiStore } from '../../../store/socketApiStore/SocketApiStore';

export const useAttendanceTableInit = (viewType: 'department' | 'personal' = 'department') => {
  const { currentDate, setInitialData } = attendanceStore;
  const { isSocketLoaded } = socketApiStore;

  useEffect(() => {
    if (isSocketLoaded) {
      attendanceStore.setIsLoading(true);
      SocketApi.getClient()?.emit(
        'attendanceTableInit',
        {
          currentDate,
          viewType,
        },
        setInitialData,
      );
    }
  }, [currentDate, isSocketLoaded, setInitialData, viewType]);
};
