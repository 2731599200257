import moment from 'moment';
import { DEFAULT_TIME_FORMAT } from '../../constants/time';

export const convertSecondsToTime = (value?: number, formant: string = DEFAULT_TIME_FORMAT) => {
  if (value) {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value % 3600) / 60);
    const seconds = value % 60;

    let time = moment()
      .startOf('day')
      .add(hours, 'hours')
      .add(minutes, 'minutes')
      .add(seconds, 'seconds');

    return hours ? time.format(formant) : time.format('mm:ss');
  } else return 0;
};
