export interface IEventData {
  aData: IEvenTabDataItem<EEventItemType>[];
  aUserDetails: { [key: string]: IEventUser };
}

export interface IEventUser {
  aStaffPhotoCache: {
    '80x80': string;
    '50x50': string;
  };
  bUserActive: true;
  iStaffId: number;
  iUserId: number;
  sDepartmentTitle: string;
  sUserLastName: string;
  sUserName: string;
  sUserSecondName: string;
  sUserUid: string;
}

export interface IEvenTabDataItem<T extends EEventItemType> {
  aJsonDetail: TEventDetail<T>;
  iIdElement: number;
  iIdManager: number;
  rEventDate: any;
  sType: EEventItemType;
}

export type TEventDetail<T extends EEventItemType> = T extends EEventItemType.email
  ? IEventEmailDetail
  : T extends EEventItemType.call
    ? IEventCallDetail
    : T extends EEventItemType.note
      ? IEventNoteDetails
      : never;

export interface IEventEmailDetail {
  cnt_files: number;
  folder: EEventMailItemFolder;
  subject: string;
  user_email: string;
}

export enum EEventMailItemFolder {
  'incoming' = 'Входящие',
  'outgoing' = 'Отправленные',
}

export interface IEventCallDetail {
  client_number: number;
  manager_phone: number;
  record: {
    has_record: boolean;
    allow_access: boolean;
    link: string;
    uid: string;
  };
  status_id: number;
  status_txt: EEventCallStatus;
  type_id: number;
  type_txt: EEventCallType;
  time_duration: number;
  time_wait: number;
  iIdElement: number;
  iIdManager: number | null;
  rEventDate: { date: string; timezone_type: number; timezone: string };
  sType: EEventItemType;
}

export interface IEventNoteDetails {
  ext: any;
  files: any;
  reminder: string;
  text: string;
}

export enum EEventCallType {
  outgoing = 'Исходящий',
  incoming = 'Входящий',
}

export enum EEventCallStatus {
  accepted = 'Принят',
  missed = 'Пропущен',
  success = 'Совершён',
}

export enum EEventItemType {
  call = 'call',
  email = 'email',
  note = 'note',
}
