import { convertSecondsToTime } from './format-date/convertSecondsToTitme';
import {
  EEventItemType,
  IEvenTabDataItem,
  IEventUser,
} from '../components/company/types/eventTab.types';

interface IGetFormattedTracksProps {
  userData: { [key: string]: IEventUser };
  tabData: IEvenTabDataItem<EEventItemType.call>[];
}

export const getFormattedTracks = ({ tabData, userData }: IGetFormattedTracksProps) =>
  tabData
    .filter((data) => data.aJsonDetail.record?.link)
    .map((data) => {
      const trackUserName = `${userData[data.iIdManager]?.sUserLastName} ${userData[data.iIdManager]?.sUserName[0]}.`;
      const trackPhone = data.aJsonDetail.manager_phone.toString();
      const trackType = data?.aJsonDetail?.type_txt;
      const trackDuration = convertSecondsToTime(data.aJsonDetail.time_duration);
      const dynamicTrackTitle = userData[data.iIdManager] ? trackUserName : trackPhone;
      const defaultTrackTitle = ` - ${trackType} - ${trackDuration}`;
      const splitTrackLink = data.aJsonDetail?.record?.link.split('/').pop();
      const decodedTrack = atob(splitTrackLink ?? '');
      const isTrackCached = JSON.parse(decodedTrack)?.is_cached;
      const linkQueryParam = isTrackCached ? '?download=t' : '';

      return {
        link: data.aJsonDetail?.record?.link + linkQueryParam,
        title: `${dynamicTrackTitle}${defaultTrackTitle}`,
        trackUid: data.aJsonDetail.record.uid,
        isTrackCached,
      };
    });
