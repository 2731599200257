import moment from 'moment';

import { IAttendanceDataValue, IAttendanceStatus } from '../attendanceTable.types';

import { DATE_FORMAT, LOADING_INDEX } from './attendance-constants';
import { attendanceStore } from '../../../../store/attendanceStore/AttendanceStore';
import { getAttendanceCellRange } from './getAttendanceCellRange';

export const setAttendanceRequestData = (option: IAttendanceStatus) => {
  const { employeesData, attendanceData, currentDate } = attendanceStore;
  const { startCellPosition, endCellPosition } = attendanceStore;
  const baseDate = moment(currentDate);

  const requestDataList: IAttendanceDataValue[] = [];
  const loadingDataList: IAttendanceDataValue[] = [];

  if (startCellPosition && endCellPosition) {
    const { startRowIndex, endRowIndex, startDate, endDate } = getAttendanceCellRange(
      startCellPosition,
      endCellPosition,
    );

    if (employeesData) {
      for (let i = startRowIndex; i <= endRowIndex; i++) {
        const employeeId = employeesData[i].id;

        for (let j: number = startDate; j <= endDate; j++) {
          const date = baseDate.clone().date(j).format(DATE_FORMAT);
          const employeeData = attendanceData?.[employeeId];
          const employeeDataValue = employeeData?.[date];

          const data: IAttendanceDataValue = {
            id_personal: employeeId,
            id_status: option.id,
            obs_date: date,
            uid: employeeDataValue?.uid,
          };
          const loadingData = {
            ...data,
            id_status: LOADING_INDEX,
            v3_attendance_statuses: {
              id: LOADING_INDEX,
              title: 'loading',
              short_title: 'L',
              id_category: 1,
              bg_color: '',
              font_color: '',
            },
          };

          requestDataList.push(data);
          loadingDataList.push(loadingData);
        }
      }
    }
  }

  attendanceStore.setAttendanceDataValues(loadingDataList);

  return { requestDataList };
};
