import AttendanceTable from '../../components/tables/attendanceTable/AttendanceTable';
import { useSetCurrentPage } from '../../hooks/useSetCurrentPage';
import { IPageConfig } from '../../api/requests/pagesRequest';

const AttendanceServicePage = ({ config }: { config?: IPageConfig }) => {
  useSetCurrentPage(config);

  return <AttendanceTable />;
};

export default AttendanceServicePage;
