import { Form } from 'react-bootstrap';
import React, { ChangeEvent } from 'react';

import AppDropdownResetButton from './AppDropdownResetButton';

interface ISearchProps {
  searchQuery: string;
  searchHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
}

const AppDropdownSearch = ({ searchQuery, searchHandler, setSearchQuery }: ISearchProps) => {
  const handleResetSearchQuery = () => {
    if (searchQuery.length > 0) {
      setSearchQuery('');
    }
  };

  return (
    <Form.Label className="px-2 w-100 d-flex position-relative">
      <Form.Control
        className="app__dropdown-search"
        value={searchQuery}
        onChange={searchHandler}
        placeholder="Поиск..."
      />

      {searchQuery.length > 0 && (
        <div className="app__dropdown-reset_button">
          <AppDropdownResetButton handler={handleResetSearchQuery} />
        </div>
      )}
    </Form.Label>
  );
};
export default AppDropdownSearch;
