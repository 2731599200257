import { useContext } from 'react';

import { MONTH_TITLES_RU } from '../datepicker-constants';

import styles from '../AppDatepicker.module.scss';
import AppButton from '../../../buttons/appButton/AppButton';
import { DatepickerContext } from '../datepicker-context/DatepickerContext';

const DatepickerMonthPicker = () => {
  const { momentValue, setMomentValue, monthPickerShowToggle } = useContext(DatepickerContext);

  const setMonthHandler = (index: number) => {
    const newMonth = momentValue?.clone().set({ month: index }) || null;

    setMomentValue(newMonth);
  };

  const monthClickHandler = (index: number) => {
    setMonthHandler(index);
    monthPickerShowToggle();
  };

  return (
    <div className={styles.cell__wrapper}>
      {MONTH_TITLES_RU.map((month, index) => {
        const currentMonth = momentValue?.format('MMMM') === month.toLowerCase();

        return (
          <div key={month} className={styles.picker__cell}>
            <AppButton
              variant="outline-dark"
              className={`w-100 ${currentMonth ? 'bg-dark text-white' : ''}`}
              onClick={() => monthClickHandler(index)}
            >
              <span className="w-100 text-center">{month}</span>
            </AppButton>
          </div>
        );
      })}
    </div>
  );
};

export default DatepickerMonthPicker;
