import { useContext } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';

import { EDatepickerChangeType } from '../datepicker.types';

import { DatepickerContext } from '../datepicker-context/DatepickerContext';
import { DATEPICKER_MONTH_FORMAT } from '../datepicker-constants';

import AppButton from '../../../buttons/appButton/AppButton';

import styles from '../AppDatepicker.module.scss';

const DatepickerMonthButtons = () => {
  const { momentValue, setMomentValue, monthPickerShowToggle } = useContext(DatepickerContext);
  const changeMonthHandler = (type: EDatepickerChangeType) => {
    const option = type === EDatepickerChangeType.increase ? 1 : -1;
    const newMonth = momentValue?.clone().add(option, 'month') || null;

    setMomentValue(newMonth);
  };

  return (
    <div className={styles.buttons__wrapper}>
      <AppButton
        variant="outline-dark"
        className="border-0"
        onClick={() => changeMonthHandler(EDatepickerChangeType.decrease)}
      >
        <FaAngleLeft />
      </AppButton>
      <AppButton variant="outline-secondary" className="border-0" onClick={monthPickerShowToggle}>
        <span className={styles.calendar__title}>
          {momentValue?.format(DATEPICKER_MONTH_FORMAT)}
        </span>
      </AppButton>
      <AppButton
        variant="outline-dark"
        className="border-0"
        onClick={() => changeMonthHandler(EDatepickerChangeType.increase)}
      >
        <FaAngleRight />
      </AppButton>
    </div>
  );
};

export default DatepickerMonthButtons;
