import { observer } from 'mobx-react-lite';
import { BsChevronRight } from 'react-icons/bs';

import { companyStore } from '../../../store/companyStore/CompanyStore';

import styles from './AppBreadCrumbs.module.scss';

const ActiveCrumb = observer(function ActiveCrumb() {
  const { mainCompany } = companyStore;

  return (
    <>
      {mainCompany && (
        <div className="d-flex">
          <div className="mx-2 text-muted">
            <BsChevronRight className={styles.crumb} />
          </div>
          <div className="text-muted text-nowrap">{mainCompany.rInfo.sFullTitle}</div>
        </div>
      )}
    </>
  );
});

export default ActiveCrumb;
