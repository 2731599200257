import { observer } from 'mobx-react-lite';

import { IAttendanceStatus } from '../attendanceTable.types';

import { attendanceStore } from '../../../../store/attendanceStore/AttendanceStore';
import { setAttendanceValue } from '../../../../api/socketApi/attendanceTable/setAttendanceValue';
import { setAttendanceRequestData } from '../attendance-tools/setAttendanceRequestData';
import { getChunkRequestSize } from '../attendance-tools/getChunkRequestSize';

const AttendanceMenu = observer(function AttendanceTableMenu() {
  const { attendanceStatuses, resetPosition } = attendanceStore;

  const handleOptionClick = async (option: IAttendanceStatus) => {
    const { requestDataList } = setAttendanceRequestData(option);

    const chunkSize = getChunkRequestSize(requestDataList);

    for (let i = 0; i < requestDataList.length; i += chunkSize) {
      const chunk = requestDataList.slice(i, i + chunkSize);

      await setAttendanceValue({ id_status: option.id, items: chunk });
    }

    resetPosition();
  };

  return (
    <div className="shadowed__box att__table-menu">
      {attendanceStatuses.map((option) => (
        <div
          key={option.id}
          onClick={() => handleOptionClick(option)}
          className="att__table-menu-item"
        >
          <div
            style={{
              backgroundColor: option.bg_color,
              color: option.font_color,
            }}
            className="att__table-menu-icon"
          >
            {option.short_title}
          </div>
          {option.title}
        </div>
      ))}
    </div>
  );
});

export default AttendanceMenu;
