import { observer } from 'mobx-react-lite';

import { taskStore } from '../../../../store/tasks/TaskStore';

import AppButton from '../../../ui/buttons/appButton/AppButton';
import CloseModalButton from '../../../ui/buttons/CloseModalButton';
import { screenStore } from '../../../../store/ScreenStore';
import { userStore } from '../../../../store/userStore/UserStore';
import { ETaskStatusId, TASK_STATUSES_FOR_END, TASK_STATUSES_FOR_START } from '../../task-types';
import { handleChangeTaskStatus } from '../../task-utils/handleChangeTaskStatus';

const TaskCardTitle = observer(() => {
  const { taskCardData } = taskStore;
  const { isMobile } = screenStore;
  const { userInfo } = userStore;
  const isExecutor = taskCardData?.cardData.task_executors?.find(
    (executor) => executor.id_crm === userInfo?.id,
  );

  const isBeginToExecute = TASK_STATUSES_FOR_START.includes(isExecutor?.status.id ?? 0);
  const isExecuting = TASK_STATUSES_FOR_END.includes(isExecutor?.status.id ?? 0);

  return (
    <>
      <div className="d-flex gap-2 align-items-start">
        <div className="d-flex flex-wrap justify-content-between gap-1 mb-2 flex-grow-1">
          <h4 style={{ color: '#2f5379' }}>
            {`Задача #${taskCardData?.cardData?.id}: «${taskCardData?.cardData?.title}»`}
          </h4>
          {isExecutor && isBeginToExecute && (
            <AppButton
              variant="success"
              onClick={() => handleChangeTaskStatus(ETaskStatusId.inProgress, taskCardData)}
            >
              Начать выполнение
            </AppButton>
          )}

          {isExecuting && isExecuting && (
            <AppButton
              variant="danger"
              onClick={() => handleChangeTaskStatus(ETaskStatusId.completed, taskCardData)}
            >
              Завершить выполнение
            </AppButton>
          )}
        </div>
        {isMobile && <CloseModalButton />}
      </div>

      <div
        dangerouslySetInnerHTML={{ __html: taskCardData?.cardData?.body_html ?? '' }}
        className="mb-2"
      />
    </>
  );
});

export default TaskCardTitle;
