import { IFilterRequestFields } from '../../components/company/types/companyFilter.types';

import { apiClient, IBaseResponse } from '../apiClient';
import { CLIENT_URL } from '../api.constants';

export interface ICompanyListData {
  aData: Record<string, ICompanyListItem>;
  iAllClients: number;
  iCurrentPage: number;
  iNextPage: number;
  iQueryTotalCount: number;
  iTotalPages: number;
}

export interface ICompanyListItem {
  bHasChilds?: boolean;
  bHasSync1C?: boolean;
  bIsDeletedMark?: boolean;
  bIsMarkVip?: boolean;
  bIsSubsidiary?: boolean;
  iIdElement?: number;
  iStatusId?: number;
  rDkpDate?: IDkpDate | null;
  sAbcdChar?: null | string;
  sFullTitle?: string;
  sManagerFirstName: string;
  sManagerLastName: string | null;
  sOblTitle?: string;
  sSellerCode?: string;
  sTypeChar?: string;
  sUidElement: string;
}

interface IDkpDate {
  date: string;
  timezone: string;
  timezone_type: number;
}

export const companyListRequest = async (
  nextPage: number | null,
  filterData: IFilterRequestFields | null = null,
  sort: number = 0,
): Promise<IBaseResponse<ICompanyListData>> =>
  await apiClient(CLIENT_URL, {
    data: {
      sPresetName: 'clientsList',
      iLoadPage: nextPage,
      rFilterData: filterData,
      iSortPreset: sort,
      // iDebugSleepSec: 2,
    },
  });
