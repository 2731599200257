import './app_dropdown.scss';
import { Dropdown } from 'react-bootstrap';

import { IAppDropdownProps } from './app_dropdown.types';

import AppDropdownMenu from './dropdown-components/AppDropdownMenu';
import AppDropdownToggle from './dropdown-components/AppDropdownToggle';
import DropdownProvider from './dropdown-context/DropdownProvider';

const AppDropdown = ({
  value,
  onChange,
  options,
  placeholder,
  dropDownClick,
  groupOptions = false,
  isInvalid = false,
  optionsLoader = false,
  disabledList,
  name,
  ...rest
}: IAppDropdownProps) => {
  return (
    <DropdownProvider {...{ value, onChange, options, groupOptions, disabledList }}>
      <Dropdown onClick={dropDownClick}>
        <AppDropdownToggle name={name} placeholder={placeholder} isInvalid={isInvalid} {...rest} />
        <AppDropdownMenu optionsLoader={optionsLoader} />
      </Dropdown>
    </DropdownProvider>
  );
};

export default AppDropdown;
