import {
  ESetTaskDataAction,
  ISetTaskDataProps,
  setTaskData,
} from '../../../api/socketApi/tasks/setTaskData';
import { ETaskStatusId, ITaskCardData } from '../task-types';

// TODO Как задефать изменение статуса?
export const handleChangeTaskStatus = (
  status: ETaskStatusId,
  taskCardData: ITaskCardData | null,
) => {
  if (taskCardData) {
    const changeStatusData: ISetTaskDataProps<ESetTaskDataAction.changeStatus> = {
      action: ESetTaskDataAction.changeStatus,
      data: {
        taskId: taskCardData?.cardData.id,
        statusId: status,
      },
    };

    setTaskData(changeStatusData);
  }
};
