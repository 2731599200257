import { ATTENDANCE_CELL_SIZE } from '../attendance-tools/attendance-constants';
import { attendanceStore } from '../../../../store/attendanceStore/AttendanceStore';

export const useAttendanceMaskPosition = () => {
  const {
    startCellCoordinates,
    endCellCoordinates,
    startCellPosition,
    attendanceData,
    employeesData,
  } = attendanceStore;
  const isMaskShow = startCellCoordinates && endCellCoordinates;
  const isSingleCell =
    startCellCoordinates?.y === endCellCoordinates?.y &&
    startCellCoordinates?.x === endCellCoordinates?.x;
  const isHasValue =
    attendanceData?.[employeesData?.[startCellPosition?.rowIndex ?? 0]?.id ?? 0]?.[
      startCellPosition?.fullDate ?? 0
    ];

  if (startCellCoordinates && endCellCoordinates) {
    const top = Math.min(startCellCoordinates.y, endCellCoordinates.y);
    const left = Math.min(startCellCoordinates.x, endCellCoordinates.x);
    const width = Math.abs(endCellCoordinates.x - startCellCoordinates.x) + ATTENDANCE_CELL_SIZE;
    const height = Math.abs(endCellCoordinates.y - startCellCoordinates.y) + ATTENDANCE_CELL_SIZE;

    return { top, left, width, height, isSingleCell, isMaskShow, isHasValue };
  }

  return { top: 0, left: 0, width: 0, height: 0, isSingleCell, isMaskShow, isHasValue };
};
