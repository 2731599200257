import { useContext } from 'react';

import { IAppDropdownOption } from '../app_dropdown.types';
import { AppDropdownContext } from '../dropdown-context/DropdownContext';

interface IOptionListProps {
  searchQuery: string;
  filteredOptions: IAppDropdownOption[];
}

const AppDropdownOptionList = ({ searchQuery, filteredOptions }: IOptionListProps) => {
  const { renderFunction, groupInitialOptions } = useContext(AppDropdownContext);

  return (
    <ul className="app__dropdown-list">
      {groupInitialOptions.length > 0 && searchQuery.length === 0
        ? groupInitialOptions.map((groupOption) => (
            <li key={groupOption.label}>
              <h6 className="my-1 fw-bold text-muted px-2">{groupOption.label}</h6>
              <ul>{renderFunction(groupOption.items)}</ul>
            </li>
          ))
        : renderFunction(filteredOptions)}
    </ul>
  );
};

export default AppDropdownOptionList;
