import '../../../ui/buttons/print-button.scss';
import { observer } from 'mobx-react-lite';
import { Dropdown } from 'react-bootstrap';

import { attendanceStore } from '../../../../store/attendanceStore/AttendanceStore';
import { attendancePrint } from '../attendance-hooks/attendancePrint';
import { transformToPersonalStructure } from '../attendance-tools/transformToPersonalStructure';

import 'moment/locale/ru';
import AppToggleButton from '../../../ui/buttons/appToggleButton/AppToggleButton';

const AttendancePrintButton = observer(() => {
  const { attendanceDepartments } = attendanceStore;

  return (
    <Dropdown
      onSelect={(eventKey) => {
        if (eventKey === 'alphabet' && attendanceDepartments) {
          const formatted = transformToPersonalStructure(attendanceDepartments);
          const { handlePrint } = attendancePrint(formatted);

          handlePrint();
        } else {
          const { handlePrint } = attendancePrint(attendanceDepartments);
          handlePrint();
        }
      }}
    >
      <Dropdown.Toggle className="rounded-1 pe-5 app__toggle-button">
        Печать документа
        <AppToggleButton color="light" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item eventKey="standart">Обычный формат</Dropdown.Item>
        <Dropdown.Item eventKey="alphabet">В алфавитном порядке</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
});

export default AttendancePrintButton;
