import './appToggleButton.scss';

const AppToggleButton = ({
  backgroundColor = 'transparent',
  color = 'white',
}: {
  backgroundColor?: string;
  color?: string;
}) => {
  return <div className={`toggle-button_trigger bg-${backgroundColor} text-${color}`}></div>;
};

export default AppToggleButton;
