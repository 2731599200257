import { Form } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';

import { attendanceStore } from '../../../../store/attendanceStore/AttendanceStore';
import { generateMonthOptions } from '../../../../utils/format-date/generateMonthOptions';
import { generateYearsOptions } from '../../../../utils/format-date/generateYearsOptions';

import AppDropdown from '../../../ui/inputs/AppDropdown/AppDropdown';
import AttendancePrintButton from './AttendancePrintButton';
import moment from 'moment';

const AttendanceDateBar = observer(() => {
  const { startYear, startMonth, setStartYear, setStartMonth, isAdvance, isAdvanceToggle } =
    attendanceStore;
  const yearOptions = generateYearsOptions();
  const monthOptions = generateMonthOptions(startYear ?? moment().year());

  return (
    <>
      <div className="row align-items-end">
        <div className="col-12 col-sm-6 col-lg-4 col-xl-2  mb-2">
          <Form.Label htmlFor="attendanceYear" className="fw-bold">
            Выберите год:
          </Form.Label>
          <AppDropdown
            value={startYear}
            onChange={setStartYear}
            options={yearOptions}
            placeholder="Год"
            name="attendanceYear"
          />
        </div>

        <div className="col-12 col-sm-6 col-lg-4 col-xl-2  mb-2">
          <Form.Label htmlFor="attendanceMonth" className="fw-bold">
            Выберите месяц:
          </Form.Label>
          <AppDropdown
            value={startMonth}
            onChange={setStartMonth}
            options={monthOptions}
            placeholder="Месяц"
            name="attendanceMonth"
          />
        </div>

        <div className="col d-flex gap-3 align-items-center  mb-2">
          <AttendancePrintButton />
          <Form.Label className="d-flex align-items-center gap-2 mb-0" role="button">
            <Form.Check.Input
              checked={isAdvance}
              onChange={isAdvanceToggle}
              className="shadow-none border border-dark m-0"
              style={{ width: '1rem', height: '1rem' }}
              role="button"
            />
            <span>Для расчёта аванса</span>
          </Form.Label>
        </div>
      </div>
    </>
  );
});

export default AttendanceDateBar;
