import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { BiSolidDownArrow, BiSolidUpArrow } from 'react-icons/bi';

import { EDatepickerChangeType, EDatepickerTimeType, IDatepickerTime } from '../datepicker.types';

import { DatepickerContext } from '../datepicker-context/DatepickerContext';
import { useTimePickerHandlers } from '../datepicker-hooks/useTimePickerHandlers';
import { useTimePickerTouchHandlers } from '../datepicker-hooks/useTimePickerTouchHandlers';

import AppButton from '../../../buttons/appButton/AppButton';

import styles from '../AppDatepicker.module.scss';

const DatepickerTimePicker = observer(function CalendarTimePicker() {
  const { momentValue, timeList, setDateValues } = useContext(DatepickerContext);
  const { handleTouchStart, handleTouchMove } = useTimePickerTouchHandlers(changeTimeHandler);
  const { onEnterTimePicker, onLeaveTimePicker } = useTimePickerHandlers();

  function changeTimeHandler(type: EDatepickerChangeType, target: EDatepickerTimeType) {
    const operation = type === EDatepickerChangeType.increase ? 1 : -1;
    const currentValue = momentValue?.get(target) ?? 0;
    let newValue = currentValue + operation;

    // Корректировка значений для часов, минут и секунд
    if (target === EDatepickerTimeType.hour) {
      newValue = (newValue + 24) % 24; // Ограничение для часов (0–23)
    } else if (target === EDatepickerTimeType.minutes || target === EDatepickerTimeType.seconds) {
      newValue = (newValue + 60) % 60; // Ограничение для минут и секунд (0–59)
    }

    const newTime = momentValue?.clone().set(target, newValue) || null;

    setDateValues(newTime);
  }

  const handleWheelMove = (event: React.WheelEvent<HTMLDivElement>, el: IDatepickerTime) => {
    const type = event.deltaY < 0 ? EDatepickerChangeType.decrease : EDatepickerChangeType.increase;
    changeTimeHandler(type, el.type);
  };

  return (
    <div onMouseEnter={onEnterTimePicker} onMouseLeave={onLeaveTimePicker} className="d-flex">
      {timeList.map((timeButton) => (
        <div
          key={timeButton.id}
          className={styles.timepicker__item}
          onTouchStart={handleTouchStart}
          onTouchMove={(event) => handleTouchMove(event, timeButton)}
          onWheel={(event) => handleWheelMove(event, timeButton)}
        >
          <AppButton
            onClick={() => changeTimeHandler(EDatepickerChangeType.decrease, timeButton.type)}
            variant="outline-dark"
            className="border-0"
          >
            <BiSolidUpArrow />
          </AppButton>

          <div className={styles.timepicker__item_outer}>
            {momentValue?.clone().add(-1, timeButton.type).format(timeButton.format)}
          </div>

          <span className={styles.timepicker__item_middle}>
            <span className="position-relative">
              {momentValue?.clone().get(timeButton.type).toString().padStart(2, '0')}
              <span className={styles.timepicker__item_middle_title}>{timeButton.shortTitle}</span>
            </span>
          </span>

          <div className={styles.timepicker__item_outer}>
            {momentValue?.clone().add(1, timeButton.type).format(timeButton.format)}
          </div>

          <AppButton
            onClick={() => changeTimeHandler(EDatepickerChangeType.increase, timeButton.type)}
            variant="outline-dark"
            className="border-0"
          >
            <BiSolidDownArrow />
          </AppButton>
        </div>
      ))}
    </div>
  );
});

export default DatepickerTimePicker;
