import { observer } from 'mobx-react-lite';
import { Alert } from 'react-bootstrap';

import { taskStore } from '../../../store/tasks/TaskStore';
import { useTaskCardData } from '../../../api/socketApi/tasks/useTaskCardData';

import TaskCardTitle from './card-components/TaskCardTitle';
import TaskCardStatuses from './card-components/TaskCardStatuses';
import TaskCardExecutors from './card-components/TaskCardExecutors';
import TaskCardFileList from './card-components/TaskCardFileList';
import TaskCardExecutorsSelect from './card-components/TaskCardExecutorsSelect';
import TaskCardTabs from './card-components/TaskCardTabs';
import CardFallbackSpinner from '../../ui/spinners/CardFallbackSpinner';

import styles from '../Tasks.module.scss';

const TaskCard = observer(() => {
  const { taskCardData, isCardLoading } = taskStore;

  useTaskCardData();

  return (
    <>
      {isCardLoading && <CardFallbackSpinner />}

      {!isCardLoading && (
        <div className={styles.task__card}>
          {taskCardData?.hasErrors && <Alert variant="danger">{taskCardData?.errorMessage}</Alert>}

          {taskCardData && !taskCardData.hasErrors && (
            <>
              <TaskCardTitle />
              <TaskCardStatuses />

              {taskCardData.cardData.task_files.length > 0 && (
                <div className="mb-3">
                  <TaskCardFileList filesArray={taskCardData.cardData.task_files} />
                </div>
              )}

              <TaskCardExecutors />
              <TaskCardExecutorsSelect />

              <TaskCardTabs />

              <pre style={{ overflowY: 'auto', overflowX: 'hidden', maxWidth: '500px' }}>
                {JSON.stringify(taskCardData, null, 2)}
              </pre>
            </>
          )}
        </div>
      )}
    </>
  );
});

export default TaskCard;
