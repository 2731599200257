import { Tab, Table } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import moment from 'moment';

import { ETaskTab } from '../../task-types';

import { DEFAULT_TIME_FORMAT } from '../../../../constants/time';
import { taskStore } from '../../../../store/tasks/TaskStore';
import { screenStore } from '../../../../store/ScreenStore';

const TaskCardHistoryTab = observer(() => {
  const { taskCardData } = taskStore;
  const { isMobile } = screenStore;

  return (
    <Tab.Pane eventKey={ETaskTab.history}>
      {isMobile && (
        <div className="d-flex flex-column gap-1">
          {taskCardData?.cardData.historyData &&
            taskCardData.cardData.historyData.length > 0 &&
            taskCardData.cardData.historyData.map((history) => (
              <div key={history.id} className="border border-primary p-2 rounded-1">
                <div>
                  <span className="fw-bold me-1">Время:</span>
                  <span>{moment(history.date_create).format(DEFAULT_TIME_FORMAT)}</span>
                </div>
                <div>
                  <span className="fw-bold me-1">Вызвал событие:</span>
                  <span>{history.manager_initiator?.fullName}</span>
                </div>
                <div>
                  <span className="fw-bold me-1">Участник:</span>
                  <span>{history.manager_participant?.fullName}</span>
                </div>
                <div className="fw-bold">Описание события:</div>
                <div>{history.preset.title}</div>
              </div>
            ))}
        </div>
      )}

      {!isMobile && (
        <Table bordered hover striped size="sm">
          <thead>
            <tr>
              <th>Время</th>
              <th>Вызвал событие</th>
              <th>Участник</th>
              <th>Описание события</th>
            </tr>
          </thead>
          <tbody>
            {taskCardData?.cardData.historyData &&
              taskCardData.cardData.historyData.length > 0 &&
              taskCardData.cardData.historyData.map((history) => (
                <tr key={history.id}>
                  <td>{moment(history.date_create).format(DEFAULT_TIME_FORMAT)}</td>
                  <td>{history.manager_initiator?.fullName}</td>
                  <td>{history.manager_participant?.fullName}</td>
                  <td>{history.preset.title}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </Tab.Pane>
  );
});

export default TaskCardHistoryTab;
