import React, { useEffect, useRef, useState } from 'react';

import styles from './AppCollapse.module.scss';
interface CollapseProps {
  children: React.ReactNode;
  isOpen: boolean;
}
const AppCollapse = ({ children, isOpen }: CollapseProps) => {
  const [height, setHeight] = useState<number | undefined>(0);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      // Устанавливаем высоту блока для анимации
      setHeight(contentRef.current?.scrollHeight);
    } else {
      // Сбрасываем высоту для скрытия
      setHeight(0);
    }
  }, [isOpen]);

  return (
    <div
      className={`${styles.app__collapse} ${isOpen ? styles.show : styles.hide}`}
      style={{
        height: `${height}px`,
        opacity: isOpen ? 1 : 0,
        visibility: isOpen ? 'visible' : 'hidden',
        willChange: 'auto',
      }}
    >
      <div ref={contentRef}>{children}</div>
    </div>
  );
};

export default AppCollapse;
