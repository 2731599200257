import { IAppDropdownOption } from '../app_dropdown.types';

export const getAppDropdownLabel = (initialOptions: IAppDropdownOption[], value: any) => {
  const formattedValue = JSON.stringify(value);
  const dropdownValue = initialOptions.find((option) => {
    const formattedOption = JSON.stringify(option?.value);

    return formattedOption === formattedValue;
  });
  return dropdownValue?.label ?? '';
};
