export interface ITasksItemsResponse {
  page: number;
  items: ITaskItem[];
}

export interface ITaskItem {
  body_html: string;
  body_text: string;
  date_create: string;
  date_deadline: string;
  enable_control: boolean;
  id: number;
  id_creator: number;
  id_response: number;
  id_special_type: number;
  in_progress: boolean;
  is_fire: boolean;
  title: string;
  cnt_files: number;
  manager: {
    fullName: string;
    id_crm: number;
  };
  task_executors?: ITaskExecutor[];
  task_observers?: { fullName: string; id_crm: number }[];
  task_files: ITaskFile[];
  commentData: ITaskComment[];
  reportData: ITaskReport[];
  historyData: ITaskHistory[];
}

export interface ITaskExecutor extends ITaskManager {
  status: ITaskStatus;
}

export interface IChangeTaskExecutorResponse {
  executors: ITaskExecutor[];
  newDepartmentData: Record<string, ITaskDepartment>;
}

export interface IChangeTaskObserverResponse {
  observers: { fullName: string; id_crm: number }[];
  newDepartmentData: Record<string, ITaskDepartment>;
}

export interface ITaskStatus {
  id: number;
  title: ETaskStatusTitle;
  sort: number;
}

export enum ETaskStatusId {
  new = 1, // Новая
  viewed = 5, // Просмотрена
  inProgress = 7, // Выполняется
  pendingReview = 9, // Ожидает контроля постановщика
  completed = 11, // Выполнена
  sentForRevision = 13, // Отправлена на доработку
  canceled = 15, // Отменена
}

export const TASK_STATUSES_FOR_START = [ETaskStatusId.new, ETaskStatusId.viewed];
export const TASK_STATUSES_FOR_END = [
  ETaskStatusId.inProgress,
  ETaskStatusId.pendingReview,
  ETaskStatusId.sentForRevision,
];

export enum ETaskStatusTitle {
  'Просмотрена' = 'Просмотрена',
  'Выполняется' = 'Выполняется',
  'Ожидает контроля постановщика' = 'Ожидает контроля постановщика',
  'Выполнена' = 'Выполнена',
  'Отправлена на доработку' = 'Отправлена на доработку',
  'Отменена' = 'Отменена',
  'Новая' = 'Новая',
}

export enum ETaskFilterPresets {
  incoming = 'incoming',
  waitingControl = 'waitingControl',
  done = 'done',
  delegate = 'delegate',
  observe = 'observe',
  closed = 'closed',
}

export interface ITaskItemsFilterProps {
  presets: ETaskFilterPresets;
  searchQuery?: string;
}

export interface ITaskCardData {
  cardData: ITaskItem;
  departmentsData: Record<string, ITaskDepartment>;
  hasErrors: boolean;
  errorMessage?: string;
}

export interface ITaskDepartment {
  title: string;
  items: Record<string, ITaskDepartmentItem>;
}

export interface ITaskDepartmentItem {
  id_crm: number;
  name: string;
  last_name: string;
  fullName: string;
  value: number;
}

export interface ITaskModalManager {
  id_crm: number;
  name: string;
  last_name: string;
  uid: string;
  fullName: string;
}

export interface IAddTaskInitial {
  taskName: string;
  taskDescriptionHtml: string;
  taskDeadLine?: string;
  taskApprove?: boolean;
  taskIsUrgent?: boolean;
  taskExecutors: number[];
  taskObservers?: number[];
  taskSpecialType?: number;
  taskFiles?: string[];
  taskResponse?: number | null;
}

export enum ETaskSocketKey {
  'getTaskItemsData' = 'getTaskItemsData',
  'getTaskCardData' = 'getTaskCardData',
  'setTaskData' = 'setTaskData',
  'getTaskModalData' = 'getTaskModalData',
  'createNewTask' = 'createNewTask',
}

export enum ETaskTab {
  'comments' = 'comments',
  'reports' = 'reports',
  'history' = 'history',
}

interface ITaskManager {
  fullName: string;
  id_crm: number;
}

export interface ITaskComment {
  id: number;
  id_task: number;
  id_user: number;
  date_add: string;
  text_data: string;
  manager: ITaskManager;
  comment_files: ICommentFile[];
}

export interface ITaskHistory {
  id: number;
  id_task: number;
  date_create: string;
  id_iniciator: number;
  id_member: number;
  id_preset: number;
  append_text: null;
  manager_initiator?: ITaskManager;
  manager_participant?: ITaskManager;
  preset: {
    id: number;
    title: string;
    sort: number;
  };
}

export interface ITaskReport {
  id: number;
  id_task: number;
  id_from: number;
  id_to: number | null;
  date_create: string;
  message: string;
  managerFrom: ITaskManager;
  report_files: IReportFile[];
}

interface IFile {
  id: number;
  filename: string;
  file_uid: string;
}

export interface ITaskFile extends IFile {
  id_task: number;
}

export interface IReportFile extends IFile {
  id_report: number;
}

export interface ICommentFile extends IFile {
  id_comment: number;
}
