import { useContext } from 'react';
import { Dropdown, Form } from 'react-bootstrap';

import { AppDropdownContext } from '../dropdown-context/DropdownContext';

import AppDropdownResetButton from './AppDropdownResetButton';
import AppToggleButton from '../../../buttons/appToggleButton/AppToggleButton';

interface IToggleProps {
  name?: string;
  placeholder?: string;
  isInvalid?: boolean;
  [key: string]: any;
}
const AppDropdownToggle = ({ name, placeholder, isInvalid, ...rest }: IToggleProps) => {
  const { inputLabel, isInputActive, onChange } = useContext(AppDropdownContext);
  const handleResetValue = () => {
    onChange(null);
  };

  return (
    <div className="position-relative">
      <Dropdown.Toggle className="app__dropdown-toggle app__toggle-button" {...rest}>
        <Form.Control
          id={name}
          className={`app__dropdown-control ${isInputActive ? 'active' : ''}`}
          value={inputLabel}
          placeholder={placeholder}
          isInvalid={isInvalid}
          onChange={() => {}}
        />

        <AppToggleButton color="secondary" />
      </Dropdown.Toggle>

      {isInputActive && (
        <span className="app__dropdown-reset_button reset-toggle">
          <AppDropdownResetButton handler={handleResetValue} />
        </span>
      )}
    </div>
  );
};

export default AppDropdownToggle;
