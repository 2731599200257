import React, { useState } from 'react';
import { EDatepickerChangeType, EDatepickerTimeType, IDatepickerTime } from '../datepicker.types';

export const useTimePickerTouchHandlers = (
  changeTimeHandler: (type: EDatepickerChangeType, target: EDatepickerTimeType) => void,
) => {
  const [lastY, setLastY] = useState(0);
  const swipeThreshold = 15;
  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    const touch = event.touches[0];
    setLastY(touch.clientY);
  };

  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>, el: IDatepickerTime) => {
    const touch = event.touches[0];
    const deltaY = touch.clientY - lastY;

    if (Math.abs(deltaY) >= swipeThreshold) {
      if (deltaY < 0) {
        changeTimeHandler(EDatepickerChangeType.increase, el.type);
      } else {
        changeTimeHandler(EDatepickerChangeType.decrease, el.type);
      }
      setLastY(touch.clientY);
    }
  };

  return { handleTouchStart, handleTouchMove };
};
