import React from 'react';
import {
  IEditAddressInitialState,
  IEditContactInitialState,
  IEditDataInitialState,
  IEditGeneralInitialState,
  IEditManagerInitialState,
  IEditTechnicInitialState,
} from './change.types';

export interface ICompanyProps {
  company: ICompanyData;
}

export interface ICompanyDataResponse {
  aData: ICompanyData;
  hasErrors?: boolean;
}

export enum ECompanyError {
  mainDataError = 'mainDataError',
  tabDataError = 'tabDataError',
  financeDataError = 'financeDataError',
}

export enum ECompanyExistField {
  mainDataExist = 'mainDataExist',
  tabDataExist = 'tabDataExist',
  financeDataExist = 'financeDataExist',
  isTabDataLoading = 'isTabDataLoading',
  isFinanceDataLoading = 'isFinanceDataLoading',
  isFinanceDataMoreLoading = 'isFinanceDataMoreLoading',
  isEventDataLoading = 'isEventDataLoading',
  isEventDataMoreLoading = 'isEventDataMoreLoading',
  eventDataExist = 'eventDataExist',
  isEdit = 'isEdit',
  isModal = 'isModal',
}

export interface IFetchCompanyMainDataProps {
  id: string;
  uid: string;
  isMain?: boolean;
  isModal?: boolean;
  tabKey?: ECompanyTabKeys | undefined;
  financeTabKey?: EFinanceTabKeys | undefined;
}

export interface IFetchCompanyTabDataProps {
  uid: string;
  id: string;
  tabKey: ECompanyTabKeys;
  tabParams?: any;
}

export interface IFetchEventData {
  id: string;
  uid: string;
  filterParams?: any;
  isMoreData?: boolean;
}

export interface ICompanyData {
  state: ECompanyError | null;
  isEdit: boolean;
  isModal: boolean;
  mainDataExist: boolean;
  tabDataExist: boolean;
  financeDataExist: boolean;
  eventDataExist: boolean;
  isTabDataLoading: boolean;
  isFinanceDataLoading: boolean;
  isFinanceDataMoreLoading: boolean;
  isEventDataLoading: boolean;
  isEventDataMoreLoading: boolean;
  id: string;
  rAccess: {
    bBtnEditCard: boolean;
    bBtnSetMeAsMainManager: boolean;
    bHasUniqueSchemas: boolean;
    bTabContacts: boolean;
    bTabFinance: boolean;
    bTabHandlers: boolean;
    bTabHolding: boolean;
    bTabZp: boolean;
    iTotalBlockType: number;
  };
  rInfo: {
    iCntAllContacts: number;
    sClientInn: string;
    sClientKpp: string;
    sFullTitle: string;
    sUidElement: string;
  };
  companyTabs: {
    [key: string]: ICompanyTab;
  };
  currentTab: ECompanyTabKeys;
  financeTab: EFinanceTabKeys;
  eventTabState: {
    email: {};
    calls: {};
    logs: {};
  };
  eventTabPage: number | null;
  tabData: any;
  changeData: any;
  generalInitialState: IEditGeneralInitialState;
  addressInitialState: IEditAddressInitialState;
  dataInitialState: IEditDataInitialState;
  technicInitialState: IEditTechnicInitialState;
  managerInitialState: IEditManagerInitialState;
  contactInitialState: IEditContactInitialState;
}

export interface ICompanyTab {
  eventKey: ECompanyTabKeys;
  title: string;
  icon: React.ReactNode;
  length?: number;
}

export interface ICompanies {
  [key: string]: ICompanyData;
}

export interface ICompanyFinanceTabs {
  [key: string]: {
    eventKey: EFinanceTabKeys;
    title: string;
    icon: string;
  };
}

export enum ECompanyTabKeys {
  'main' = 'main',
  'holding' = 'holding',
  'contacts' = 'contacts',
  'events' = 'events',
  'finance' = 'finance',
  'salary' = 'salary',
}

export enum EFinanceTabKeys {
  'gantTable' = 'gantTable',
  'salesCalendar' = 'salesCalendar',
  'commonDocsList' = 'commonDocsList',
  'clientSalesList' = 'clientSalesList',
}
