import { ChangeEvent, useEffect, useState } from 'react';

import { IAppDropdownOption } from '../app_dropdown.types';

import { useDebounceEffect } from '../../../../../hooks/useDebounceEffect';

export const useDropdownSearch = (
  initialOptions: IAppDropdownOption[],
  type: 'dropdown' | 'multiselect',
  optionsLoader?: boolean,
) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<IAppDropdownOption[]>(initialOptions);

  useDebounceEffect(() => getFilteredOptions(), 350, [searchQuery]);

  const searchHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!isSearchLoading) setIsSearchLoading(true);
    setSearchQuery(value);
  };

  const getFilteredOptions = () => {
    if (isSearchLoading) {
      const filtered = initialOptions.filter((option) =>
        option.label.toLowerCase().includes(searchQuery.toLowerCase()),
      );
      setFilteredOptions(filtered);
    }

    setIsSearchLoading(false);
  };

  useEffect(() => {
    // Для обновления загружаемых опций
    if (/*type === 'dropdown' && */ initialOptions.length > 0 && searchQuery.length === 0) {
      setFilteredOptions(initialOptions);
    }
  }, [initialOptions, searchQuery.length, setFilteredOptions, type]);

  useEffect(() => {
    // Для отображения загрузки опций
    if (type === 'dropdown' && optionsLoader !== undefined) {
      setIsSearchLoading(optionsLoader);
    }
  }, [optionsLoader, setIsSearchLoading, type]);

  return {
    searchHandler,
    getFilteredOptions,
    isSearchLoading,
    setIsSearchLoading,
    searchQuery,
    setSearchQuery,
    filteredOptions,
    setFilteredOptions,
  };
};
