import { useContext } from 'react';
import moment from 'moment/moment';

import { DatepickerContext } from '../datepicker-context/DatepickerContext';

import AppButton from '../../../buttons/appButton/AppButton';

import styles from '../AppDatepicker.module.scss';

const DatepickerYearPicker = () => {
  const { momentValue, setMomentValue, yearArray, yearPickerShowToggle } =
    useContext(DatepickerContext);

  const setYearHandler = (date: moment.Moment | null) => {
    const newMonth = momentValue?.clone().set({ year: date?.clone().year() }) || null;
    setMomentValue(newMonth);
  };

  const yearClickHandler = (date: moment.Moment | null) => {
    setYearHandler(date);
    yearPickerShowToggle();
  };

  return (
    <div className={styles.cell__wrapper}>
      {yearArray.map((year, index) => {
        const currentYear = momentValue?.year() === year.year();

        return (
          <div key={index} className={styles.picker__cell}>
            <AppButton
              variant="outline-dark"
              className={`w-100 ${currentYear ? 'bg-dark text-white' : ''}`}
              onClick={() => yearClickHandler(year)}
            >
              <span className="w-100 text-center">{year.format('YYYY')}</span>
            </AppButton>
          </div>
        );
      })}
    </div>
  );
};

export default DatepickerYearPicker;
