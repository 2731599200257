import { observer } from 'mobx-react-lite';
import { Table } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';
import { FaPencil } from 'react-icons/fa6';
import { GrClose } from 'react-icons/gr';

import { taskStore } from '../../../../store/tasks/TaskStore';

import AppButton from '../../../ui/buttons/appButton/AppButton';

import styles from '../../Tasks.module.scss';
import { handleChangeTaskStatus } from '../../task-utils/handleChangeTaskStatus';
import { ETaskStatusId } from '../../task-types';

const TaskCardExecutors = observer(() => {
  const { taskCardData } = taskStore;

  return (
    <div className="d-flex flex-wrap flex-lg-nowrap align-items-start gap-2 mb-3">
      <Table bordered hover size="sm" className="border border-3 border-primary mb-0">
        <thead>
          <tr>
            <th className="bg-primary text-white text-center">Исполнители</th>
            <th className="bg-primary text-white text-center">Статус</th>
            <th className="bg-primary text-white text-center" style={{ width: '90px' }}>
              Действие
            </th>
          </tr>
        </thead>
        <tbody>
          {taskCardData?.cardData.task_executors?.map((executor) => (
            <tr key={executor.id_crm}>
              <td className="bg-primary-subtle">{executor.fullName}</td>
              <td className="bg-primary-subtle">{executor.status.title}</td>
              <td className="bg-primary-subtle">
                <AppButton variant="success" size="sm" className="p-1 me-1">
                  <FaCheck />
                </AppButton>
                <AppButton
                  title="Изменить ход выполнения"
                  variant="warning"
                  size="sm"
                  className="p-1 me-1"
                >
                  <FaPencil />
                </AppButton>
                <AppButton
                  title="Отменить выполнение"
                  variant="danger"
                  size="sm"
                  className="p-1"
                  onClick={() => handleChangeTaskStatus(ETaskStatusId.canceled, taskCardData)}
                >
                  <GrClose />
                </AppButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div style={{ width: '350px', flexGrow: 1 }}>
        <Table bordered hover size="sm" className="border border-3 border-primary mb-0">
          <thead>
            <tr>
              <th className="bg-primary text-white">Наблюдатели</th>
            </tr>
          </thead>
          <tbody>
            {taskCardData?.cardData.task_observers?.map((observer) => (
              <tr key={observer.id_crm} className={styles.table_row}>
                <td className="bg-primary-subtle">{observer.fullName}</td>
              </tr>
            ))}
            {taskCardData?.cardData.task_observers?.length === 0 && (
              <tr className={styles.table_row}>
                <td className="bg-primary-subtle">Наблюдатели отсутствуют</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
});

export default TaskCardExecutors;
