import { useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { DEFAULT_TIME_FORMAT } from '../../../../constants/time';
import { ESetTaskDataAction, setTaskData } from '../../../../api/socketApi/tasks/setTaskData';
import { convertToRequestDate } from '../../../../utils/format-date/convertToRequestDate';
import { convertFromResponseDate } from '../../../../utils/format-date/convertFromResponseDate';

import AppCollapse from '../../../ui/AppCollapse';
import AppDatepicker from '../../../ui/inputs/AppDatepicker/AppDatepicker';
import AppButton from '../../../ui/buttons/appButton/AppButton';
import { taskStore } from '../../../../store/tasks/TaskStore';
import { observer } from 'mobx-react-lite';

const STATUS_IN_PROGRESS = 'в работе';
const STATUS_DONE = 'завершено';

const TaskCardStatuses = observer(() => {
  const [show, setIsShow] = useState(false);
  const [dateError, setDateError] = useState(false);
  const { taskCardData } = taskStore;
  const { taskId } = useParams();

  const handleSetDeadLine = (values: any) => {
    const oneHourInMilliseconds = 60 * 60 * 1000;
    const nowDate = moment();
    const newDeadlineDate = moment(values.dateDeadLine, DEFAULT_TIME_FORMAT);
    const isNewDeadlineValid = newDeadlineDate.diff(nowDate) >= oneHourInMilliseconds;

    if (!isNewDeadlineValid) {
      setDateError(true);
    } else {
      setTaskData({
        action: ESetTaskDataAction.setDeadLine,
        data: {
          taskId: Number(taskId),
          dateDeadLine: convertToRequestDate(values.dateDeadLine),
        },
      });

      setIsShow(false);
    }
  };

  return (
    <>
      <Alert variant="info">
        <div className="row">
          <div className="col-sm-12 col-lg-6">
            <span className="fw-bold me-1">Глобальный статус задачи:</span>
            <span>{taskCardData?.cardData.in_progress ? STATUS_IN_PROGRESS : STATUS_DONE}</span>
          </div>

          <div className="col-sm-12 col-lg-6">
            <span className="fw-bold me-1">Дата создания:</span>
            {taskCardData?.cardData.date_create && (
              <span>{convertFromResponseDate(taskCardData?.cardData.date_create)}</span>
            )}
          </div>

          {taskCardData?.cardData.date_deadline && (
            <div className="col-sm-12 col-lg-6 d-flex flex-wrap align-items-center gap-1">
              <span className="fw-bold">Крайний срок:</span>
              <span>{convertFromResponseDate(taskCardData?.cardData.date_deadline)}</span>
              <Button variant="link" className="p-0 border-0" onClick={() => setIsShow(!show)}>
                (изменить)
              </Button>
            </div>
          )}

          <AppCollapse isOpen={show}>
            <Form
              onSubmit={(values) => handleSetDeadLine(values)}
              initialValues={{
                dateDeadLine: convertFromResponseDate(taskCardData?.cardData.date_deadline ?? ''),
              }}
              render={({ handleSubmit }) => (
                <form
                  onSubmit={handleSubmit}
                  className="d-flex flex-wrap align-items-center gap-2 mt-2"
                >
                  <div className="col-12 col-lg-6 d-flex flex-wrap flex-sm-nowrap gap-2">
                    <Field name="dateDeadLine">
                      {({ input }) => (
                        <AppDatepicker
                          value={input.value}
                          onChange={(value: any) => {
                            setDateError(false);
                            input.onChange(value);
                          }}
                          timeFormat="time"
                        />
                      )}
                    </Field>

                    <AppButton variant="success" type="submit">
                      Сохранить
                    </AppButton>
                  </div>
                </form>
              )}
            />
          </AppCollapse>
        </div>
      </Alert>
      {dateError && (
        <small className="d-block text-danger my-3">
          Время должно быть больше текущего минимум на час!
        </small>
      )}
    </>
  );
});

export default TaskCardStatuses;
