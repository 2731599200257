import { FormApi } from 'final-form';
import { useParams } from 'react-router-dom';
import { FormControl, FormLabel } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';

import { ESetTaskDataAction, setTaskData } from '../../../../api/socketApi/tasks/setTaskData';

import AddTaskFilesList from '../card-components/AddTaskFilesList';
import AppButton from '../../../ui/buttons/appButton/AppButton';

interface ICommentInitialState {
  taskComment: string;
  taskFiles: string[];
}

const initialState: ICommentInitialState = {
  taskComment: '',
  taskFiles: [],
};

const TaskCommentTabForm = () => {
  const { taskId } = useParams();
  const handleSubmit = (
    values: ICommentInitialState,
    form: FormApi<ICommentInitialState, ICommentInitialState>,
  ) => {
    console.log({
      taskId: Number(taskId),
      taskComment: values.taskComment,
    });

    setTaskData({
      action: ESetTaskDataAction.setComment,
      data: {
        taskId: Number(taskId),
        taskComment: values.taskComment,
        taskFiles: values.taskFiles,
      },
    });

    form.reset();
  };

  return (
    <Form
      initialValues={initialState}
      onSubmit={(values: ICommentInitialState, form) => handleSubmit(values, form)}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field name="taskComment">
            {({ input }) => (
              <div>
                <FormLabel htmlFor="taskComment" className="mb-1 fw-bold">
                  Комментарий:
                </FormLabel>
                <FormControl
                  id="taskComment"
                  as="textarea"
                  className="rounded-1 mb-2"
                  required
                  {...input}
                />
              </div>
            )}
          </Field>

          <AddTaskFilesList />

          <Field name="taskComment">
            {({ input }) => (
              <AppButton type="submit" disabled={!input.value}>
                Добавить комментарий
              </AppButton>
            )}
          </Field>
        </form>
      )}
    />
  );
};

export default TaskCommentTabForm;
