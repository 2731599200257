import { Dropdown } from 'react-bootstrap';

import { IAppDropdownOption, IAppDropdownProps } from '../app_dropdown.types';

import { AppDropdownContext } from './DropdownContext';
import { useDropdownOptions } from '../dropdown-hooks/useDropdownOptions';
import { getAppDropdownLabel } from '../dropdown-tools/getAppDropdownLabel';

const DropdownProvider = ({
  value,
  options,
  groupOptions,
  children,
  onChange,
  disabledList,
}: IAppDropdownProps) => {
  const { initialOptions, groupInitialOptions } = useDropdownOptions(options, groupOptions);
  const inputLabel = getAppDropdownLabel(initialOptions, value);
  const isInputActive =
    value && typeof value === 'object'
      ? Object.values(value).filter((el) => el).length > 0
      : !!value || value === 0;

  const selectHandler = (selectedValue: any) => {
    const isString = typeof selectedValue === 'string';
    onChange(isString ? JSON.parse(selectedValue) : selectedValue);
  };

  const renderFunction = (optionList: IAppDropdownOption[]) => {
    return optionList.map((option) => (
      <Dropdown.Item
        key={option?.label}
        eventKey={JSON.stringify(option?.value)}
        className="app__dropdown-item"
        active={JSON.stringify(option?.value) === JSON.stringify(value)}
        onClick={() => selectHandler(JSON.stringify(option?.value))}
        disabled={disabledList ? disabledList.includes(option.value) : undefined}
      >
        {option?.label}
      </Dropdown.Item>
    ));
  };

  return (
    <AppDropdownContext.Provider
      value={{
        value,
        onChange,
        inputLabel,
        isInputActive,
        initialOptions,
        groupInitialOptions,
        type: 'dropdown',
        renderFunction,
      }}
    >
      {children}
    </AppDropdownContext.Provider>
  );
};

export default DropdownProvider;
