import { Nav } from 'react-bootstrap';

const TaskCardNav = () => {
  return (
    <Nav variant="tabs" className="mb-2">
      <Nav.Item>
        <Nav.Link eventKey="comments">Комментарии</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="reports">Отчёты</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="history">История</Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default TaskCardNav;
