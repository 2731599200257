import { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Dropdown } from 'react-bootstrap';

import { DatepickerContext } from '../datepicker-context/DatepickerContext';
import { screenStore } from '../../../../../store/ScreenStore';

import DatepickerToggle from './DatepickerToggle';
import DatepickerButtonsBar from './DatepickerButtonsBar';
import DatepickerTimePicker from './DatepickerTimePicker';
import DatepickerMonthButtons from './DatepickerMonthButtons';
import DatepickerMonthPicker from './DatepickerMonthPicker';
import DatepickerCalendar from './DatepickerCalendar';
import DatepickerYearButtons from './DatepickerYearButtons';
import DatepickerYearPicker from './DatepickerYearPicker';
import DatepickerYearRangeButtons from './DatepickerYearRangeButtons';

import styles from '../AppDatepicker.module.scss';

const DatepickerContextWrapper = observer(() => {
  const { isMobile } = screenStore;
  const { isMenuOpen, isMonthPickerShow, isYearPickerShow } = useContext(DatepickerContext);
  const { timeFormat, tableRef } = useContext(DatepickerContext);

  return (
    <div className={`${styles.calendar} ${isMobile && isMenuOpen ? styles.mobile : ''}`}>
      <Dropdown show={isMenuOpen}>
        <Dropdown.Toggle className="w-100 p-0 border-0 bg-white">
          <DatepickerToggle />
        </Dropdown.Toggle>

        <Dropdown.Menu className={`${styles.calendar__menu} shadowed__box`}>
          <div ref={tableRef} className="d-flex flex-column gap-2">
            <DatepickerMonthButtons />

            {isMonthPickerShow && !isYearPickerShow && (
              <>
                <DatepickerYearButtons />
                <DatepickerMonthPicker />
              </>
            )}

            {isMonthPickerShow && isYearPickerShow && (
              <>
                <DatepickerYearRangeButtons />
                <DatepickerYearPicker />
              </>
            )}

            {!isMonthPickerShow && <DatepickerCalendar />}

            <DatepickerButtonsBar />

            {timeFormat && <DatepickerTimePicker />}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
});

export default DatepickerContextWrapper;
