import AttendanceEmployeeRow from './AttendanceEmployeeRow';

import { IAttendanceDepartment, IAttendanceEmployee } from '../attendanceTable.types';

const AttendanceDepartmentSection = ({ department }: { department: IAttendanceDepartment }) => {
  return (
    <>
      {department.title && (
        <h6 className="att__table-department border bg-secondary-subtle">{department.title}</h6>
      )}

      {department.items.map((employee: IAttendanceEmployee, index: number) => {
        return <AttendanceEmployeeRow {...{ employee, index }} key={employee.id} />;
      })}
    </>
  );
};

export default AttendanceDepartmentSection;
