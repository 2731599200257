import { FaHome } from 'react-icons/fa';
import { IoMdBriefcase } from 'react-icons/io';
import { LuListChecks } from 'react-icons/lu';
import { PiRepeat } from 'react-icons/pi';

export const menuConfigs = {
  // Главная
  '85859a48-bed0-4618-8de8-68e78cbe6462': {
    // icon: 'bi bi-house-door',
    icon: <FaHome size={20} />,
    hasBadge: true,
    badgeTemplate: 'new',
    template: 'dashboard',
  },
  //Wiki
  'd409e880-f59f-4ad6-9aef-280df7295aea': {
    icon: 'bi bi-wikipedia',
    hasBadge: false,
    badgeTemplate: '',
    template: '',
  },
  // Администрирование
  // 'bb7ebb60-6e0b-4e46-ab4f-536fbb789804': {
  //  icon: 'bi bi-house-gear',
  //  icon: 'bi bi-bezier',
  //  icon: 'bi bi-bank2',
  //   hasBadge: true,
  //   badgeTemplate: 'ПУ'
  // },
  // Организации
  '93798e82-20ba-460c-851e-ffab99cc6ebc': {
    // icon: 'bi bi-briefcase',
    icon: <IoMdBriefcase size={20} />,
    hasBadge: false,
    badgeTemplate: '',
    template: 'two-columns',
  },
  // Финансы и 1С
  'ee0b4afb-b48e-4d18-b4f6-273f4e0e4599': {
    icon: 'bi bi-coin',
    hasBadge: false,
    badgeTemplate: '',
    template: '',
  },
  // Внешние службы
  '361e168d-b9a5-4157-a40d-83e3c0124add': {
    // icon: 'bi bi-repeat',
    // icon: <CiRepeat size={20} />,
    icon: <PiRepeat size={20} />,
    hasBadge: false,
    badgeTemplate: '',
    template: '',
  },
  // Задачи
  '6a525a66-09a1-4061-ae24-465703924e80': {
    // icon: 'bi bi-ui-checks',
    icon: <LuListChecks size={20} />,
    hasBadge: false,
    badgeTemplate: '',
    template: 'tasks',
  },
  // Отчёты
  // '85859a48-bed0-4618-8de8-68e78cbe6462': {
  //   icon: 'bi bi-graph-up',
  //   hasBadge: true,
  //   badgeTemplate: 'new',
  // },
  // Маркировка
  '2ae40d09-4c4c-45bc-affb-0824fbad61a2': {
    icon: 'bi bi-qr-code-scan',
    hasBadge: false,
    badgeTemplate: '',
    template: '',
  },
  // Оценка качества
  'bf1f8f71-d2a1-430b-b852-91197ac3fbb6': {
    icon: 'bi bi-bar-chart',
    hasBadge: false,
    badgeTemplate: '',
    template: '',
  },
};
