import { SocketApi } from '../../api/socketApi/socketApi';
import { useEffect } from 'react';
import { callStore } from '../../store/callStore/CallStore';
import { socketApiStore } from '../../store/socketApiStore/SocketApiStore';

export const useIncomingCall = () => {
  const { isSocketLoaded } = socketApiStore;

  useEffect(() => {
    if (isSocketLoaded) {
      SocketApi.getClient()?.on('incomingCall', (res) => {
        console.log('incomingCall', res);
        callStore.setCallData(res);
        callStore.setTestCallData(res);
      });
      SocketApi.getClient()?.on('endIncomingCall', (res) => {
        console.log('endIncomingCall', res);
        callStore.setCallData(null);
        callStore.removeCallData(res.callId);
      });
    }
  }, [isSocketLoaded]);
};
