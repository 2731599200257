import { apiClient, IBaseResponse } from '../apiClient';
import { TABS_URL } from '../api.constants';
import { ECompanyTabKeys } from '../../components/company/types/company.types';

import { IContactTabItem } from '../../components/company/types/contactTab.types';
import { IInformationTabData } from '../../components/company/types/informationTab.types';
import { IHoldingData } from '../../components/company/types/holdingTab.types';
import { IEventData } from '../../components/company/types/eventTab.types';

type ECompanyTabResponse<T extends ECompanyTabKeys> = T extends ECompanyTabKeys.main
  ? IInformationTabData
  : T extends ECompanyTabKeys.holding
    ? IHoldingData
    : T extends ECompanyTabKeys.events
      ? IEventData
      : T extends ECompanyTabKeys.contacts
        ? IContactTabItem
        : T extends ECompanyTabKeys.finance
          ? any
          : null;

export const companyTabRequest = async <T extends ECompanyTabKeys>(
  uid: string | undefined,
  tabName: T,
  tabParams?: {},
): Promise<IBaseResponse<ECompanyTabResponse<T>>> =>
  await apiClient(TABS_URL, {
    data: {
      sTabName: tabName,
      sClientUid: uid,
      ...tabParams,
      // iDebugSleepSec: 55,
    },
  });
