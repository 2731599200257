import { observer } from 'mobx-react-lite';

import { attendanceStore } from '../../../../store/attendanceStore/AttendanceStore';

import AttendanceDepartmentSection from './AttendanceDepartmentSection';

const AttendanceDepartmentList = observer(function DepartmentList() {
  const { attendanceDepartments } = attendanceStore;

  return (
    <>
      {Object.values(attendanceDepartments ?? {}).map((department, index) => (
        <AttendanceDepartmentSection key={department.title ?? index} {...{ department }} />
      ))}
    </>
  );
});

export default AttendanceDepartmentList;
