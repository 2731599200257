import moment from 'moment';
import { IAppDropdownOption } from '../../components/ui/inputs/AppDropdown/app_dropdown.types';

export function generateYearsOptions(startYear = 2025): IAppDropdownOption[] {
  const currentYear = moment().year();
  const yearsArray: IAppDropdownOption[] = [];
  const maxYear = moment().month() === 11 ? currentYear + 1 : currentYear;

  for (let year = startYear; year <= maxYear; year++) {
    yearsArray.push({
      label: `${year}`,
      value: year,
    });
  }

  return yearsArray;
}
