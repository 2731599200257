import { Dispatch, SetStateAction } from 'react';
import { FormControl } from 'react-bootstrap';

import { ITransferColumnData } from './transfer-types';

import AppButton from '../../buttons/appButton/AppButton';

import styles from './styles/AppTransferList.module.scss';

interface ITransferListSideProps {
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
  columnData: ITransferColumnData[];
  groupHandler: (key: string) => void;
  itemHandler: (id: number) => void;
  allItemHandler: () => void;
  buttonTitle: string;
}

const AppTransferListSide = ({
  query,
  setQuery,
  columnData,
  groupHandler,
  itemHandler,
  allItemHandler,
  buttonTitle,
}: ITransferListSideProps) => {
  const PLACEHOLDER = 'Поиск...';

  return (
    <div className="col-12 col-lg-6">
      <FormControl
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder={PLACEHOLDER}
        className="rounded-1 mb-2 shadow-none"
      />

      <ul className="overflow-y-auto border rounded-1 p-2" style={{ height: '200px' }}>
        {columnData.map((data) => (
          <li key={data.key} className={styles.transfer__item__block}>
            <h6 role="button" onClick={() => groupHandler(data.key)}>
              {data.sGroupName}
            </h6>

            <ul className="ps-1">
              {data.items.map((el) => (
                <li
                  role="button"
                  key={el.key}
                  onClick={() => itemHandler(el.value)}
                  className={styles.transfer__item}
                >
                  <span>{el.title}</span>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>

      <div className="text-end my-2">
        <AppButton variant="outline-dark" onClick={allItemHandler}>
          {buttonTitle}
        </AppButton>
      </div>
    </div>
  );
};

export default AppTransferListSide;
