import { FormControl, FormLabel } from 'react-bootstrap';
import { Field, Form, FormSpy } from 'react-final-form';
import { observer } from 'mobx-react-lite';

import { IAbsenceDataItem } from '../attendanceTable.types';
import { attendanceStore } from '../../../../store/attendanceStore/AttendanceStore';
import { setAttendanceAbsenceDuration } from '../../../../api/socketApi/attendanceTable/setAttendanceAbsenceDuration';

import AppButton from '../../../ui/buttons/appButton/AppButton';
import { screenStore } from '../../../../store/ScreenStore';

const AttendanceAbsenceMenu = observer(() => {
  const { startCellPosition, employeesData, absenceData } = attendanceStore;
  const { isMobile } = screenStore;
  const employeeId = employeesData?.[startCellPosition?.rowIndex ?? 0]?.id;
  const date = startCellPosition?.fullDate;
  const absenceValue = employeeId && date && absenceData ? absenceData?.[employeeId]?.[date] : null;
  return (
    <Form
      onSubmit={(values) => {
        const hourSeconds = Number(values?.hours ?? 0) * 3600;
        const minuteSeconds = Number(values?.minutes ?? 0) * 60;

        if (employeesData && startCellPosition) {
          const requestValue: IAbsenceDataItem = {
            date_absence: startCellPosition?.fullDate ?? '',
            duration_absence: hourSeconds + minuteSeconds,
            id_personal: employeesData[startCellPosition.rowIndex].id,
            comment: values.comment,
          };

          setAttendanceAbsenceDuration(requestValue);

          attendanceStore.setCellCoordinates('start', null);
          attendanceStore.setCellCoordinates('end', null);

          if (isMobile) {
            attendanceStore.setIsMobileMenuOpen(false);
          }
        }
      }}
      initialValues={{
        hours: absenceValue
          ? absenceValue?.duration_absence >= 3600
            ? Math.floor(absenceValue?.duration_absence / 3600)
            : undefined
          : undefined,
        minutes: absenceValue
          ? absenceValue?.duration_absence % 3600 === 0
            ? undefined
            : (absenceValue?.duration_absence % 3600) / 60
          : undefined,
        comment: absenceValue?.comment,
      }}
      render={({ handleSubmit }) => (
        <form
          className="shadowed__box att__table-menu absence__menu p-2"
          style={{ width: '300px' }}
          onSubmit={handleSubmit}
        >
          <h5 className="mb-2">Добавить время отсутствия</h5>

          <div className="mb-2">
            <FormLabel className="mb-0 fw-bold">Часы:</FormLabel>
            <Field name="hours">
              {({ input }) => (
                <FormControl
                  type="number"
                  min={0}
                  max={9}
                  className="shadow-none rounded-1 text-end"
                  {...input}
                />
              )}
            </Field>
          </div>

          <div className="mb-2">
            <FormLabel className="mb-0 fw-bold">Минуты:</FormLabel>
            <Field name="minutes">
              {({ input }) => (
                <FormControl
                  type="number"
                  min={0}
                  max={59}
                  className="shadow-none rounded-1 text-end"
                  {...input}
                />
              )}
            </Field>
          </div>

          <div className="mb-2">
            <FormLabel className="mb-0 fw-bold">Комментарий:</FormLabel>
            <Field name="comment">
              {({ input }) => (
                <FormControl className="shadow-none rounded-1" {...input} autoComplete="off" />
              )}
            </Field>
          </div>

          <FormSpy>
            {({ dirtyFields }) => (
              <div className="text-end">
                <AppButton
                  variant="success"
                  className="mt-2"
                  type="submit"
                  disabled={!dirtyFields.hours && !dirtyFields.minutes && !dirtyFields.comment}
                >
                  Сохранить
                </AppButton>
              </div>
            )}
          </FormSpy>
        </form>
      )}
    />
  );
});

export default AttendanceAbsenceMenu;
