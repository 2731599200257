import { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';

import { useDropdownSearch } from '../dropdown-hooks/useDropdownSearch';
import { LOADER_DIV_CENTER } from '../../../../../constants/loaderTemplate';
import { AppDropdownContext } from '../dropdown-context/DropdownContext';

import BaseLoader from '../../../../helpers/Loader/BaseLoader';
import AppDropdownSearch from './AppDropdownSearch';
import AppDropdownSearchWarning from './AppDropdownSearchWarning';
import AppDropdownOptionList from './AppDropdownOptionList';
import AppMultiselectMenuButtons from './AppMultiselectMenuButtons';

export interface IAppDropdownMenuProps {
  optionsLoader?: boolean;
}

const AppDropdownMenu = ({ optionsLoader }: IAppDropdownMenuProps) => {
  const { initialOptions, type } = useContext(AppDropdownContext);
  const { searchQuery, isSearchLoading, filteredOptions, setSearchQuery, searchHandler } =
    useDropdownSearch(initialOptions, type, optionsLoader);

  return (
    <Dropdown.Menu className="app__dropdown-menu shadowed__box">
      {initialOptions.length > 5 && (
        <>
          <AppDropdownSearch {...{ searchQuery, searchHandler, setSearchQuery }} />
          {type === 'multiselect' && <AppMultiselectMenuButtons {...{ filteredOptions }} />}
        </>
      )}

      {isSearchLoading && <BaseLoader template={LOADER_DIV_CENTER} />}

      {!isSearchLoading && <AppDropdownOptionList {...{ searchQuery, filteredOptions }} />}

      {!isSearchLoading && searchQuery.length > 0 && filteredOptions.length === 0 && (
        <AppDropdownSearchWarning {...{ searchQuery }} />
      )}
    </Dropdown.Menu>
  );
};

export default AppDropdownMenu;
