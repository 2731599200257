import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { taskStore } from '../../../../store/tasks/TaskStore';
import { ESetTaskDataAction, setTaskData } from '../../../../api/socketApi/tasks/setTaskData';

import AppCollapse from '../../../ui/AppCollapse';
import AppButton from '../../../ui/buttons/appButton/AppButton';
import AppTransferList from '../../../ui/inputs/AppTransferList/AppTransferList';

const TaskCardExecutorsSelect = () => {
  const { taskId } = useParams();
  const { taskCardData } = taskStore;
  const [selectedManagers, setSelectedManagers] = useState(
    // taskCardData?.cardData.task_executors?.map((executor) => executor.id_crm) ?? [],
    [],
  );
  const [isShow, setIsShow] = useState(false);
  const [isObserverShow, setIsObserverShow] = useState(false);

  const handleIsShowToggle = () => {
    setIsShow(!isShow);
    setIsObserverShow(false);
    setSelectedManagers([]);
  };
  const handleIsObserverShowToggle = () => {
    setIsObserverShow(!isObserverShow);
    setIsShow(false);
    setSelectedManagers([]);
  };
  const handleSetExecutors = (
    action: ESetTaskDataAction.setExecutor | ESetTaskDataAction.setObserver,
  ) => {
    setTaskData({
      action: action,
      data: { taskId: Number(taskId), executorId: selectedManagers, statusId: 1 },
    });

    setIsShow(false);
    setIsObserverShow(false);
    setSelectedManagers([]);
  };

  return (
    <div className="d-flex flex-column gap-2 mb-2">
      <div className="d-flex justify-content-between gap-2">
        <div className="d-flex gap-3">
          <AppButton variant="warning" size="sm" onClick={handleIsShowToggle}>
            <i className="bi bi-plus me-1 lh-sm"></i>
            Добавить исполнителей
          </AppButton>

          {isShow && (
            <AppButton
              variant="success"
              onClick={() => handleSetExecutors(ESetTaskDataAction.setExecutor)}
            >
              Сохранить
            </AppButton>
          )}
        </div>

        <div className="d-flex gap-3">
          <AppButton variant="primary" size="sm" onClick={handleIsObserverShowToggle}>
            Добавить наблюдателей
            <i className="bi bi-plus ms-1 lh-sm"></i>
          </AppButton>

          {isObserverShow && (
            <AppButton
              variant="success"
              onClick={() => handleSetExecutors(ESetTaskDataAction.setObserver)}
            >
              Сохранить
            </AppButton>
          )}
        </div>
      </div>

      <div className="flex-grow-1">
        <AppCollapse isOpen={isShow || isObserverShow}>
          <AppTransferList
            options={taskCardData?.departmentsData ?? {}}
            value={selectedManagers}
            onChange={setSelectedManagers}
          />
        </AppCollapse>
      </div>
    </div>
  );
};

export default TaskCardExecutorsSelect;
