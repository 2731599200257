import './waiting-dots.scss';
const WaitingDots = () => {
  return (
    <div className="dots">
      <span className="dot">.</span>
      <span className="dot">.</span>
      <span className="dot">.</span>
    </div>
  );
};

export default WaitingDots;
