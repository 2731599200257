import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { BsChevronRight } from 'react-icons/bs';

import { userStore } from '../../../store/userStore/UserStore';
import { ROUTES } from '../../../routes/routes';

import ActiveCrumb from './ActiveCrumb';

import styles from './AppBreadCrumbs.module.scss';

type CurrentLink = {
  key: string;
  path: string;
  title: string;
};

const AppBreadCrumbs = observer(function AppBreadCrumbs() {
  const { currentPage, pages } = userStore;
  const [staticCrumbs, setStaticCrumbs] = useState<CurrentLink[]>([]);

  useEffect(() => {
    const result: CurrentLink[] = [];

    if (currentPage && pages) {
      let currentItem = currentPage;
      do {
        result.push({
          key: currentItem.sUid,
          title: currentItem.sTitle,
          path: currentItem.sUri,
        });

        currentItem = pages[currentItem.sParentUid];
      } while (currentItem);
    }

    setStaticCrumbs(result.reverse());
  }, [currentPage, pages]);

  return (
    <div className="p-2 my-2">
      {currentPage?.sUri !== ROUTES.ROOT && staticCrumbs.length > 0 && (
        <nav className={`${styles.container} bg-body-secondary container_horizontal-scroll`}>
          {staticCrumbs.map((crumb: any, index: number) => (
            <div key={index} className="d-flex">
              <Link to={crumb.path} className={styles.main__item}>
                {crumb.title}
              </Link>
              {index !== staticCrumbs.length - 1 && (
                <div className="mx-2 text-muted">
                  <BsChevronRight className={styles.crumb} />
                </div>
              )}
            </div>
          ))}
          <ActiveCrumb />
        </nav>
      )}
    </div>
  );
});

export default AppBreadCrumbs;
