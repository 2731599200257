import { Alert } from 'react-bootstrap';

const CompanyInfo = () => {
  return (
    <Alert variant="secondary" className="rounded-1">
      Продам гараж! 8 800 555 35 35
    </Alert>
  );
};

export default CompanyInfo;
