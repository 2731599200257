import moment from 'moment';
import { DATE_FORMATS, DATE_FORMATS_SECONDS, DATE_FORMATS_TIME } from '../datepicker-constants';
import { EDatepickerTimeFormat } from '../datepicker.types';

export const getInputDate = (
  timeFormat: keyof typeof EDatepickerTimeFormat,
  inputValue: string,
) => {
  const formatWithTime = [
    ...DATE_FORMATS,
    ...DATE_FORMATS_TIME,
    ...(timeFormat === EDatepickerTimeFormat.timeWithSeconds ? DATE_FORMATS_SECONDS : []),
  ];

  const formats = timeFormat ? formatWithTime : DATE_FORMATS;

  for (const format of formats) {
    const inputDate = moment(inputValue?.trim(), format, true);
    if (inputDate.isValid()) {
      return inputDate;
    }
  }
};
