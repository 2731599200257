import { observer } from 'mobx-react-lite';
import { autoUpdate, flip, offset, useFloating, useInteractions } from '@floating-ui/react';
import { useAttendanceMaskPosition } from '../attendance-hooks/useAttendanceMaskPosition';

import { attendanceStore } from '../../../../store/attendanceStore/AttendanceStore';

import AttendanceMenu from './AttendanceMenu';
import AttendanceAbsenceMenu from './AttendanceAbsenceMenu';

const AttendanceMask = observer(function AttendanceTableMask() {
  const { isStartSelect } = attendanceStore;
  const { top, left, width, height, isSingleCell, isMaskShow, isHasValue } =
    useAttendanceMaskPosition();
  const { getReferenceProps, getFloatingProps } = useInteractions();
  const { refs, floatingStyles } = useFloating({
    open: !isStartSelect,
    placement: 'right-start',
    whileElementsMounted: autoUpdate,
    middleware: [offset(5), flip()],
  });

  return (
    isMaskShow && (
      <>
        <div
          className="att__table-mask"
          style={{ top: `${top}px`, left: `${left}px`, width: `${width}px`, height: `${height}px` }}
          ref={refs.setReference}
          {...getReferenceProps()}
        />

        {!isStartSelect && (
          <div
            className="att__table-menu__wrapper"
            ref={refs.setFloating}
            style={{ ...floatingStyles }}
            {...getFloatingProps()}
          >
            <AttendanceMenu />
            {isSingleCell && isHasValue && <AttendanceAbsenceMenu />}
          </div>
        )}
      </>
    )
  );
});

export default AttendanceMask;
