import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { modalStore } from '../../store/modal/ModalStore';
import { screenStore } from '../../store/ScreenStore';

import AppModal from '../helpers/Modal/AppModal/AppModal';
import useScrollbarSize from 'react-scrollbar-size';

const ModalList = observer(function ModalList() {
  const { modalList } = modalStore;
  const { isMobile } = screenStore;
  const { width } = useScrollbarSize();

  useEffect(() => {
    document.body.style.overflow = modalList.length > 0 ? 'hidden' : 'auto';

    if (isMobile) {
      document.body.style.overflow = modalList.length > 0 ? 'hidden' : 'auto';
    }

    if (!isMobile) {
      document.body.style.marginRight = modalList.length > 0 ? `${width}px` : '0';
    }
  }, [isMobile, modalList.length, width]);

  return (
    <>
      {modalList.length > 0 &&
        modalList.map((modal, index) => (
          <AppModal key={index} closeOverflow={modal.closeOverflow}>
            {modal.children}
          </AppModal>
        ))}
    </>
  );
});

export default ModalList;
