import { makeAutoObservable } from 'mobx';

interface ISourceTracks {
  [key: string]: ITrack;
}

interface ITrack {
  link: string;
  title: string;
  trackUid: string;
  isTrackCached?: boolean;
}

class PlayerStore {
  playerId: string | null = null;
  sourceTracks: ISourceTracks = {};
  currentTrack: ITrack | null = null;
  isPlaying: boolean = false;
  isSmallPlayerSize: boolean = true;
  isTrackFilterChange: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setIsTrackFilterChange(value: boolean) {
    // Триггер для клика по треку, для перезаписи текущего плейлиста если изменились фильтры и текущий плейлист может оказаться не актуальным
    this.isTrackFilterChange = value;
  }

  setIsSmallPlayerSize(value: boolean) {
    this.isSmallPlayerSize = value;
  }

  setSourceTracks(value: ITrack[]) {
    this.removeCurrentTrack();
    this.removeSourceTracks();
    value.forEach((track) => {
      this.sourceTracks[track.trackUid] = track;
    });
  }

  setNextSourceTracks(value: ITrack[]) {
    value.forEach((track) => {
      this.sourceTracks[track.trackUid] = track;
    });
  }

  removeSourceTracks() {
    this.sourceTracks = {};
    this.currentTrack = null;
  }

  setPlayerId(value: string | null) {
    this.playerId = value;
  }

  setCurrentTrack(uid: string) {
    this.currentTrack = this.sourceTracks[uid];
  }

  removeCurrentTrack() {
    this.currentTrack = null;
  }

  setIsPlaying(value: boolean) {
    this.isPlaying = value;
  }

  nextTrackHandler() {
    const keys = Object.keys(this.sourceTracks);

    if (this.currentTrack) {
      const currentIndex = keys.indexOf(this.currentTrack.trackUid);
      const newIndex = Math.min(currentIndex + 1, keys.length - 1);
      this.currentTrack = this.sourceTracks[keys[newIndex]];
    }
  }

  prevTrackHandler() {
    const keys = Object.keys(this.sourceTracks);

    if (this.currentTrack) {
      const currentIndex = keys.indexOf(this.currentTrack.trackUid);
      const newIndex = Math.max(currentIndex - 1, 0);
      this.currentTrack = this.sourceTracks[keys[newIndex]];
    }
  }
}

export const playerStore = new PlayerStore();
