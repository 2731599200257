import {
  IAttendanceCategory,
  IAttendanceCategoryValue,
  IAttendanceEmployee,
} from '../attendanceTable.types';
import { convertAbsenceSecondsToTime } from './convertAbsenceSecondsToTime';
import moment from 'moment';
import { attendanceStore } from '../../../../store/attendanceStore/AttendanceStore';
import { TWO_HOURS_SECONDS } from './attendance-constants';

export const getAttendanceCategoryValues = (
  attendanceCategories: IAttendanceCategory[],
  employee: IAttendanceEmployee,
): IAttendanceCategoryValue => {
  const { attendanceData, absenceData, isAdvance } = attendanceStore;
  const personalValue = attendanceData?.[employee.id] || null;
  const absenceValue = absenceData?.[employee.id] || null;
  const advancedAbsenceValue = Object.values(absenceValue ?? {}).filter(
    (value) => !isAdvance || (isAdvance && !(moment(value.date_absence).date() > 15)),
  );

  if (!personalValue) {
    return {
      workday: 0,
      sickLeave: 0,
      absence: 0,
      dayOff: 0,
      timeLeave: '',
      coefficient: 0,
      sumOfAbsence: 0,
      advancedAbsenceValue: [],
    };
  }

  const sumOfAbsence = advancedAbsenceValue.reduce((acc, item) => acc + item.duration_absence, 0);

  const countByCategory = (idCategory: number) =>
    Object.values(personalValue).filter(
      (value) =>
        value.v3_attendance_statuses?.id_category === idCategory &&
        (!isAdvance || (isAdvance && !(moment(value.obs_date).date() > 15))),
    ).length;

  const result: {
    workday: number;
    sickLeave: number;
    absence: number;
    dayOff: number;
  } = attendanceCategories?.reduce((acc, item) => {
    if (!acc[item.enTitle]) {
      acc[item.enTitle] = countByCategory(item.id);
    }

    return acc;
  }, {} as any);

  const coefficientNumerator =
    result.workday * employee?.duration_working_day -
    (sumOfAbsence > TWO_HOURS_SECONDS ? sumOfAbsence - TWO_HOURS_SECONDS : 0);
  const coefficientDenominator =
    (result.workday + result.sickLeave + result.absence) * employee?.duration_working_day;

  const coefficient =
    coefficientDenominator === 0
      ? 0
      : Math.round((coefficientNumerator / coefficientDenominator) * 10000) / 10000;

  return {
    ...result,
    timeLeave: convertAbsenceSecondsToTime(sumOfAbsence) ?? '',
    coefficient,
    sumOfAbsence,
    advancedAbsenceValue,
  };
};
