import { useEffect } from 'react';
import { userStore } from '../store/userStore/UserStore';
import { IPageConfig } from '../api/requests/pagesRequest';

export const useSetCurrentPage = (config: IPageConfig | undefined) => {
  useEffect(() => {
    if (config) {
      userStore.setCurrentPage(config);
    }
  }, [config]);
};
