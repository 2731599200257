import { socketApiStore } from '../../../store/socketApiStore/SocketApiStore';
import { attendanceStore } from '../../../store/attendanceStore/AttendanceStore';
import { useEffect } from 'react';
import { SocketApi } from '../socketApi';

export const useBroadcastAttendanceTable = () => {
  const { isSocketLoaded } = socketApiStore;
  const { updateAbsenceData, setAttendanceDataValues } = attendanceStore;

  useEffect(() => {
    if (isSocketLoaded) {
      SocketApi.getClient()?.on('setAttendanceBroadcast', attendanceStore.setAttendanceDataValues);
    }
  }, [isSocketLoaded, setAttendanceDataValues]);

  useEffect(() => {
    if (isSocketLoaded) {
      SocketApi.getClient()?.on('setAttendanceAbsenceDurationBroadcast', updateAbsenceData);
    }
  }, [isSocketLoaded, updateAbsenceData]);
};
