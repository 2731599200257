import { getDateFormat } from '../datepicker-tools/getDateFormat';
import moment from 'moment/moment';
import React, { useCallback, useRef, useState } from 'react';
import { EDatepickerTimeFormat } from '../datepicker.types';
import { DatepickerContext } from './DatepickerContext';
import { DATEPICKER_TIME } from '../datepicker-constants';
import { getYearRange } from '../datepicker-tools/getYearRange';

interface IDatepickerProviderProps {
  timeFormat?: keyof typeof EDatepickerTimeFormat;
  value: string | null;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  children: React.ReactNode;
  name?: string;
  disableRange?: 'year' | 'month';
}
const DatepickerProvider = ({
  value,
  onChange,
  timeFormat,
  children,
  name,
  disableRange,
}: IDatepickerProviderProps) => {
  const dateFormat = getDateFormat(timeFormat);
  const initialMomentValue = value ? moment(value?.trim(), dateFormat, true) : null;
  const [inputValue, setInputValue] = useState(value || '');
  const [momentValue, setMomentValue] = useState<moment.Moment | null>(initialMomentValue);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMonthPickerShow, setIsMonthPickerShow] = useState(false);
  const [isYearPickerShow, setIsYearPickerShow] = useState(false);
  const tableRef = useRef<HTMLDivElement>(null);
  const yearArray = getYearRange(momentValue);
  const timeList = timeFormat
    ? timeFormat === EDatepickerTimeFormat.timeWithSeconds
      ? Object.values(DATEPICKER_TIME)
      : [DATEPICKER_TIME.hour, DATEPICKER_TIME.minutes]
    : [];

  const setDateValues = useCallback(
    (momentValue: moment.Moment | null) => {
      const formattedValue = momentValue ? momentValue.format(dateFormat) : '';

      setMomentValue(momentValue);
      setInputValue(formattedValue);
      onChange(formattedValue);
    },
    [dateFormat, onChange],
  );

  const setNowDate = () => {
    const now = moment();
    setDateValues(now);
  };

  const monthPickerShowToggle = () => {
    setIsMonthPickerShow(!isMonthPickerShow);
  };

  const yearPickerShowToggle = () => {
    setIsYearPickerShow(!isYearPickerShow);
  };

  return (
    <DatepickerContext.Provider
      value={{
        name,
        disableRange,
        inputValue,
        momentValue,
        isMenuOpen,
        isMonthPickerShow,
        isYearPickerShow,
        timeList,
        tableRef,
        timeFormat,
        setDateValues,
        yearArray,
        setIsMenuOpen,
        setInputValue,
        setMomentValue,
        setNowDate,
        monthPickerShowToggle,
        yearPickerShowToggle,
      }}
    >
      {children}
    </DatepickerContext.Provider>
  );
};

export default DatepickerProvider;
