import { useCallback, useState } from 'react';
import { Field } from 'react-final-form';
import { FormLabel } from 'react-bootstrap';

import { unique } from '../../../../utils/uniqueGenerator';

import AppButton from '../../../ui/buttons/appButton/AppButton';
import AddTaskFileField from './AddTaskFileField';

interface IField {
  [key: string]: {
    value: string;
  };
}

const AddTaskFilesList = () => {
  const [fields, setFields] = useState<IField>({ [unique('filesField')]: { value: '' } });
 
  const handleAddField = () => {
    setFields((prevFields) => ({ ...prevFields, [unique('filesField')]: { value: '' } }));
  };

  const handleDeleteField = useCallback((key: string) => {
    setFields((prevFields) => {
      delete prevFields[key];
      return prevFields;
    });
  }, []);

  return (
    <div>
      <FormLabel className="fw-bold" htmlFor="taskFiles">
        Прикрепить файлы:
      </FormLabel>

      <Field name="taskFiles">
        {({ input }) => (
          <div className="d-flex flex-column gap-2 mb-2">
            {Object.keys(fields).map((key) => (
              <AddTaskFileField
                key={key}
                {...{ input, fields, handleDeleteField, fieldKey: key }}
              />
            ))}

            <div className="text-start">
              <AppButton variant="warning" onClick={handleAddField}>
                <i className="bi bi-plus me-1"></i>
                Добавить файл
              </AppButton>
            </div>
          </div>
        )}
      </Field>
    </div>
  );
};

export default AddTaskFilesList;
