import { SocketApi } from '../socketApi';
import { attendanceStore } from '../../../store/attendanceStore/AttendanceStore';
import { IAbsenceDataItem } from '../../../components/tables/attendanceTable/attendanceTable.types';

export interface ISetAbsenceDurationResponse {
  action: 'update' | 'delete';
  absenceData: IAbsenceDataItem;
}

export const setAttendanceAbsenceDuration = (data: IAbsenceDataItem) => {
  SocketApi.getClient()?.emit(
    'setAttendanceAbsenceDuration',
    data,
    attendanceStore.updateAbsenceData,
  );
};
