import moment from 'moment';

export const getYearRange = (date: moment.Moment | null) => {
  const startYear = date?.year();
  const yearArray = [];

  if (startYear) {
    for (let i = -6; i < 6; i++) {
      const year = moment({ year: startYear - i });
      yearArray.push(year);
    }
  }

  return yearArray.reverse();
};
