import { observer } from 'mobx-react-lite';
import { Spinner } from 'react-bootstrap';

import { attendanceStore } from '../../../../store/attendanceStore/AttendanceStore';

import {
  ATTENDANCE_CELL_SIZE,
  LOADING_INDEX,
  NO_VALUE_COLOR,
} from '../attendance-tools/attendance-constants';

interface IAttendanceCellProps {
  date: string;
  employeeId: number;
  rowIndex: number;
  dayOfMonth: string;
  isDateSameOrBefore: boolean;
}

const AttendanceCell = observer(function AttendanceCell({
  date,
  dayOfMonth,
  employeeId,
  rowIndex,
  isDateSameOrBefore,
}: IAttendanceCellProps) {
  const { attendanceData, absenceData, isAdvance } = attendanceStore;
  const personalValue = attendanceData?.[employeeId];
  const currentValue = personalValue?.[date];
  const isLoading = currentValue?.v3_attendance_statuses?.id === LOADING_INDEX;
  const isNoValue = isDateSameOrBefore && !currentValue;
  // const statusColor = currentValue ? STATUS_COLOR[currentValue.id_status] : undefined;
  const isHideForAdvance = isAdvance && Number(dayOfMonth) > 15;

  return isHideForAdvance ? (
    <div
      className="border"
      style={{
        // color: statusColor?.textColor,
        width: ATTENDANCE_CELL_SIZE,
        height: ATTENDANCE_CELL_SIZE,
      }}
      data-date={dayOfMonth}
      data-rowindex={rowIndex}
      date-fulldate={date}
    />
  ) : (
    <div
      className="att__table-cell border"
      title={`${currentValue?.v3_attendance_statuses?.title ?? 'Значение не выбрано'} ${
        absenceData?.[employeeId]?.[date]?.comment
          ? ` | Отсутствовал по причине: ${absenceData?.[employeeId]?.[date]?.comment}`
          : ''
      }`}
      style={{
        backgroundColor: isNoValue
          ? NO_VALUE_COLOR
          : currentValue?.v3_attendance_statuses?.bg_color || '',
        color: currentValue?.v3_attendance_statuses?.font_color,
        width: ATTENDANCE_CELL_SIZE,
        height: ATTENDANCE_CELL_SIZE,
      }}
      data-date={dayOfMonth}
      data-rowindex={rowIndex}
      date-fulldate={date}
    >
      <span>
        {!isLoading && currentValue?.v3_attendance_statuses?.short_title}
        {!isLoading && isNoValue && '?'}
      </span>

      {!!absenceData?.[employeeId]?.[date] && <span className="att__absence_icon" />}

      {isLoading && <Spinner variant="primary" size="sm" animation="border" role="status" />}
    </div>
  );
});

export default AttendanceCell;
