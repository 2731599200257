import { observer } from 'mobx-react-lite';
import { Dispatch, SetStateAction } from 'react';

import { userStore } from '../../../store/userStore/UserStore';

import MenuLink from './MenuLink/MenuLink';

type MenuNavProps = {
  isHovered: boolean;
  setIsHovered: Dispatch<SetStateAction<boolean>>;
};

const MAIN_PAGE_UID = '85859a48-bed0-4618-8de8-68e78cbe6462';

const MenuNav = observer(function MenuNav({ isHovered, setIsHovered }: MenuNavProps) {
  const { pages } = userStore;
  const currentPages = pages?.hasErrors ? [] : pages;
  const filteredPages = Object.values(currentPages ?? {}).filter(
    (page) => page.sParentUid === MAIN_PAGE_UID || page.sUid === MAIN_PAGE_UID,
  );

  return (
    <nav className="d-flex flex-column gap-3">
      {filteredPages.length > 0 &&
        filteredPages.map((config) => (
          <MenuLink
            key={config.sUid}
            config={config}
            isHovered={isHovered}
            setIsHovered={setIsHovered}
          />
        ))}
    </nav>
  );
});

export default MenuNav;
