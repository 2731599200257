import { useEffect } from 'react';
import { SocketApi } from '../../api/socketApi/socketApi';
import { userStore } from '../../store/userStore/UserStore';
import { socketApiStore } from '../../store/socketApiStore/SocketApiStore';

export const useSetSocketCurrentPage = () => {
  const { currentPage } = userStore;
  const { isSocketLoaded } = socketApiStore;

  useEffect(() => {
    if (isSocketLoaded && currentPage) {
      SocketApi.getClient()?.emit('setCurrentPage', { data: currentPage.sUid });
    }
  }, [currentPage, isSocketLoaded]);
};
