import AppButton from '../../../buttons/appButton/AppButton';
import { IAppDropdownOption } from '../app_dropdown.types';
import { useContext } from 'react';
import { AppDropdownContext } from '../dropdown-context/DropdownContext';

interface IButtonsProps {
  filteredOptions: IAppDropdownOption[];
}

const AppMultiselectMenuButtons = ({ filteredOptions }: IButtonsProps) => {
  const { value, onChange, disabledList } = useContext(AppDropdownContext);

  const handleSelectAll = () => {
    const newOptions = filteredOptions
      .filter((option) => !disabledList?.includes(option.value))
      .map((el) => el.value)
      .filter((option) => !value.includes(option));

    const newValue = [...value, ...newOptions];

    onChange(newValue);
  };

  const handleClearAll = () => {
    const newValue = value.filter((el: any) => !filteredOptions.find((opt) => opt.value === el));
    onChange(newValue);
  };

  return (
    <div className="px-2 mb-2 w-100 d-flex flex-wrap gap-2">
      <AppButton
        variant="outline-secondary"
        className="lh-sm flex-grow-1 justify-content-center"
        onClick={handleSelectAll}
      >
        Выбрать всё
      </AppButton>
      <AppButton
        variant="outline-secondary"
        className="lh-sm flex-grow-1 justify-content-center"
        onClick={handleClearAll}
      >
        Снять выделение
      </AppButton>
    </div>
  );
};

export default AppMultiselectMenuButtons;
