import { Spinner } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { Skeleton } from 'primereact/skeleton';

import { attendanceStore } from '../../../../../store/attendanceStore/AttendanceStore';
import { LOADING_INDEX, NO_VALUE_COLOR } from '../../attendance-tools/attendance-constants';

interface IAttendanceCellProps {
  date: string;
  employeeId: number;
  rowIndex: number;
  dayOfMonth: string;
  isDateSameOrBefore: boolean;
  isHoliday: boolean;
}

const AttendanceMobileCell = observer(
  ({
    date,
    dayOfMonth,
    employeeId,
    rowIndex,
    isDateSameOrBefore,
    isHoliday,
  }: IAttendanceCellProps) => {
    const { attendanceData, absenceData, isAdvance, isLoading: isSocketLoading } = attendanceStore;
    const personalValue = attendanceData?.[employeeId];
    const currentValue = personalValue?.[date];
    const isLoading = currentValue?.v3_attendance_statuses?.id === LOADING_INDEX;
    const isNoValue = isDateSameOrBefore && !currentValue;
    const isHideForAdvance = isAdvance && Number(dayOfMonth) > 15;

    const onClickHandler = () => {
      attendanceStore.setCellPosition('start', {
        rowIndex: rowIndex,
        date: Number(dayOfMonth),
        fullDate: date,
      });

      attendanceStore.setCellPosition('end', {
        rowIndex: rowIndex,
        date: Number(dayOfMonth),
        fullDate: date,
      });

      attendanceStore.setIsMobileMenuOpen(true);
    };

    return (
      <div key={date} style={{ width: '12%' }}>
        <div className={`border text-center ${isHoliday ? 'bg-danger text-white' : ''}`}>
          {dayOfMonth}
        </div>
        {isSocketLoading ? (
          <Skeleton height="30px" className="rounded-0" />
        ) : isHideForAdvance ? (
          <div className="border" style={{ height: '30px' }} />
        ) : (
          <div
            style={{
              backgroundColor: isNoValue
                ? NO_VALUE_COLOR
                : currentValue?.v3_attendance_statuses?.bg_color || '',
              color: currentValue?.v3_attendance_statuses?.font_color,
              height: '30px',
            }}
            data-date={dayOfMonth}
            data-rowindex={rowIndex}
            date-fulldate={date}
            className="border d-flex align-items-center justify-content-center position-relative"
            onClick={onClickHandler}
          >
            <span>
              {!isLoading && currentValue?.v3_attendance_statuses?.short_title}
              {!isLoading && isNoValue && '?'}
            </span>

            {!!absenceData?.[employeeId]?.[date] && <span className="att__absence_icon" />}

            {isLoading && <Spinner variant="primary" size="sm" animation="border" role="status" />}
          </div>
        )}
      </div>
    );
  },
);

export default AttendanceMobileCell;
