import { makeAutoObservable } from 'mobx';
import { NavigateFunction } from 'react-router-dom';

import { ROUTES } from '../../routes/routes';
import { isTokensExist } from '../../api/apiClient';
import { authStore, TAuthState } from '../authStore/AuthStore';
import { LS_API_APP, LS_API_TOKEN } from '../../api/api.constants';
import { SocketApi } from '../../api/socketApi/socketApi';
import { IPageConfig, IPagesData, pagesRequest } from '../../api/requests/pagesRequest';
import { IUserData, userRequest } from '../../api/requests/userRequest';

interface ILogoutUserProps {
  totalLogout?: boolean;
}

class UserStore {
  userInfo: IUserData | null = null;
  pages: IPagesData | null = null;
  currentPage: IPageConfig | null = null;
  isActiveTab: boolean = false;
  isUserClick: boolean = false;

  authStoreService: typeof authStore;

  constructor(authStoreService: typeof authStore) {
    makeAutoObservable(this);
    this.authStoreService = authStoreService;
  }

  public setUserInfo(data: IUserData | null) {
    this.userInfo = data;
  }

  public setPages(data: IPagesData | null) {
    this.pages = data;
  }

  public setIsUserClick = (value: boolean) => (this.isUserClick = value);

  setIsActiveTab(value: boolean) {
    this.isActiveTab = value;
  }

  setCurrentPage(currentPageData: IPageConfig) {
    this.currentPage = currentPageData;
  }

  logoutUser({ totalLogout = true }: ILogoutUserProps) {
    if (totalLogout) {
      localStorage.removeItem(LS_API_APP);
      localStorage.removeItem(LS_API_TOKEN);
    }
    this.setUserInfo(null);
    this.setPages(null);
    this.authStoreService.setAuthState(TAuthState.NO_AUTH);
    SocketApi.disconnectClient();
  }

  async fetchUserData(currentUrl: string, navigate: NavigateFunction) {
    if (isTokensExist() && this.userInfo === null) {
      const userResponse = await userRequest();
      const userData = userResponse?.dataArray;

      if (userData && !userResponse.hasErrors) {
        const pagesResponse = await pagesRequest();
        const pagesData = {
          ...pagesResponse?.dataArray,
          // '6a525a66-09a1-4061-ae24-465703924e81': {
          //   aCustomParams: null,
          //   bCustom: false,
          //   iId: 1488,
          //   iIdParent: 1,
          //   iIdType: 3,
          //   sAccessFolder: '/core/access/v3/tasks/',
          //   sPageTitle: 'TablePage',
          //   sParentUid: '85859a48-bed0-4618-8de8-68e78cbe6462',
          //   sTitle: 'TablePage',
          //   sUid: '6a525a66-09a1-4061-ae24-465703924e81',
          //   sUri: '/tablepage/',
          // },
        };

        if (!pagesResponse?.hasErrors) {
          this.setUserInfo(userData);
          this.setPages(pagesData);
          this.authStoreService.setAuthState(TAuthState.AUTH_SUCCESS);
          this.authStoreService.setAppIsLoaded();
          SocketApi.reconnectToServer();
          if (currentUrl.startsWith('/auth')) {
            navigate(ROUTES.ROOT);
          }
        } else {
          // Удаляем токены потому что они недействительны и переходим на форму авторизации
          this.logoutUser({});
          this.authStoreService.setAuthError('Страницы приложения не найдены!');
          navigate(ROUTES.AUTH);
        }
      } else {
        // Удаляем токены потому что они недействительны и переходим на форму авторизации
        this.logoutUser({});
        this.authStoreService.setAuthError('Пользователь не найден!');
        navigate(ROUTES.AUTH);
      }
    }
  }
}

export const userStore = new UserStore(authStore);
