import { Alert } from 'react-bootstrap';
import WaitingDots from '../ui/WaitingDots/WaitingDots';
import { socketApiStore } from '../../store/socketApiStore/SocketApiStore';
import { SocketApi } from '../../api/socketApi/socketApi';
import { observer } from 'mobx-react-lite';

const SocketDisconnectWarning = observer(() => {
  const { isSocketConnectError, isSocketLoaded } = socketApiStore;
  const isSocketExist = SocketApi.getClient();
  return (
    <>
      {!isSocketLoaded && isSocketExist && (
        <Alert variant="danger" className="mx-2 mt-1">
          Потеряно соединение с сервером. Ожидание подключения
          <WaitingDots />
        </Alert>
      )}

      {isSocketConnectError && !isSocketExist && (
        <Alert variant="danger" className="mx-2 mt-1">
          Ошибка подключения к серверу!
        </Alert>
      )}
    </>
  );
});

export default SocketDisconnectWarning;
