import { Alert, Table } from 'react-bootstrap';
import moment from 'moment';

import { IAttendanceCategoryValue, IAttendanceEmployee } from '../attendanceTable.types';
import { convertAbsenceSecondsToTime } from '../attendance-tools/convertAbsenceSecondsToTime';
import { DATEPICKER_DEFAULT_FORMAT } from '../../../ui/inputs/AppDatepicker/datepicker-constants';

import CloseModalButton from '../../../ui/buttons/CloseModalButton';
import { TWO_HOURS_SECONDS } from '../attendance-tools/attendance-constants';
import { screenStore } from '../../../../store/ScreenStore';

interface IAbsenceModalProps {
  employee: IAttendanceEmployee;
  categoryValue: IAttendanceCategoryValue;
}

const AttendanceAbsenceModal = ({ employee, categoryValue }: IAbsenceModalProps) => {
  const totalWithoutFreeHours = categoryValue.sumOfAbsence - TWO_HOURS_SECONDS;
  const { isMobile } = screenStore;

  return (
    <div className="modal__content" style={{ width: isMobile ? '95%' : '40%' }}>
      <div className="d-flex align-items-start justify-content-between gap-2 mb-2">
        <h4>{`${employee.surname ?? ''} ${employee.name ?? ''} ${employee.patronymic}`}</h4>
        <CloseModalButton />
      </div>

      {!isMobile && !!categoryValue.sumOfAbsence && (
        <Table bordered hover striped size="sm" style={{ tableLayout: 'fixed' }}>
          <colgroup>
            <col style={{ width: '30px' }} />
            <col style={{ width: '100px' }} />
            <col style={{ width: '150px' }} />
            <col />
            <col />
          </colgroup>
          <thead>
            <tr>
              <th className="text-center bg-primary text-white">№</th>
              <th className="text-center bg-primary text-white">Дата</th>
              <th className="text-center bg-primary text-white">Время отсутствия</th>
              <th className="text-center bg-primary text-white" colSpan={2}>
                Комментарий
              </th>
            </tr>
          </thead>
          <tbody>
            {categoryValue.advancedAbsenceValue.map((absenceData, index) => (
              <tr key={absenceData.uid}>
                <td className="text-center">{index + 1}</td>
                <td className="text-center">
                  {moment(absenceData.date_absence).format(DATEPICKER_DEFAULT_FORMAT)}
                </td>
                <td className="text-end">
                  {convertAbsenceSecondsToTime(absenceData.duration_absence)}
                </td>
                <td colSpan={2}>{absenceData.comment}</td>
              </tr>
            ))}
          </tbody>
          <tfoot className="table-info">
            <tr>
              <th colSpan={2}>Итого:</th>
              <th className="text-end">
                {convertAbsenceSecondsToTime(categoryValue.sumOfAbsence)}
              </th>
              <th>Вычесть из ЗП:</th>
              <th className="text-end">
                {totalWithoutFreeHours > 0
                  ? convertAbsenceSecondsToTime(totalWithoutFreeHours)
                  : undefined}
              </th>
            </tr>
          </tfoot>
        </Table>
      )}

      <div className="d-flex gap-1 flex-column mb-2">
        {isMobile &&
          !!categoryValue.sumOfAbsence &&
          categoryValue.advancedAbsenceValue.map((absenceData) => (
            <Alert variant="secondary" key={absenceData.uid} className="mb-0 py-1">
              <div>
                <span className="fw-bold me-2">Дата:</span>
                <span>{moment(absenceData.date_absence).format(DATEPICKER_DEFAULT_FORMAT)}</span>
              </div>
              <div>
                <span className="fw-bold me-2">Время отсутствия:</span>
                <span>{convertAbsenceSecondsToTime(absenceData.duration_absence)}</span>
              </div>

              <div>
                <span className="fw-bold me-2">Комментарий:</span>
                <span>{absenceData.comment}</span>
              </div>
            </Alert>
          ))}
        <Alert variant="info" className="mb-0 py-1">
          <div>
            <span className="fw-bold me-2">Общее время отсутствия:</span>
            <span>{convertAbsenceSecondsToTime(categoryValue.sumOfAbsence)}</span>
          </div>

          {totalWithoutFreeHours > 0 && (
            <div>
              <span className="fw-bold me-2">Вычесть из ЗП:</span>
              <span>{convertAbsenceSecondsToTime(totalWithoutFreeHours)}</span>
            </div>
          )}
        </Alert>
      </div>
      {categoryValue.coefficient !== null && (
        <Alert
          variant="info"
          className={`d-flex align-items-center gap-2 justify-content-center ${isMobile ? 'flex-column' : ''}`}
        >
          <div className="fw-bold">Расчёт коэффициента =</div>

          <div className="text-primary">
            <div className="border-bottom border-primary text-center pb-1">
              Был * Прод.раб.дня - Время отсут.
            </div>
            <div className="text-center">( Был + Болел + Отстут. ) * Прод.раб.дня</div>
          </div>

          {!isMobile && (
            <div className="border border-secondary-subtle" style={{ height: '40px' }} />
          )}

          <div className="text-dark">
            <div className="border-bottom border-dark text-center pb-1">
              {`${categoryValue.workday} * ${employee.duration_working_day} - ${
                categoryValue.sumOfAbsence > TWO_HOURS_SECONDS
                  ? categoryValue.sumOfAbsence - TWO_HOURS_SECONDS
                  : 0
              }`}
            </div>
            <div className="text-center">{`( ${categoryValue.workday} + ${categoryValue.sickLeave} + ${categoryValue.absence} ) * ${employee.duration_working_day}`}</div>
          </div>
          <div className="fw-bold">= {categoryValue.coefficient}</div>
        </Alert>
      )}
    </div>
  );
};

export default AttendanceAbsenceModal;
