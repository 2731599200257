import moment from 'moment';
import 'moment/locale/ru';

import { IAppDropdownOption } from '../../components/ui/inputs/AppDropdown/app_dropdown.types';

export const generateMonthOptions = (year: number): IAppDropdownOption[] => {
  moment.locale('ru');
  const LAST_MONTH_INDEX = 11;
  const currentYear = moment().year();
  // const monthIndex = year === moment().year() ? moment().month() + 1 : LAST_MONTH_INDEX;
  const monthIndex =
    year < currentYear
      ? LAST_MONTH_INDEX
      : year === currentYear
        ? moment().month() + 1
        : year > currentYear
          ? 0
          : 0;
  const monthOptions: IAppDropdownOption[] = [];

  for (let month = 0; month <= monthIndex; month++) {
    const monthTitle = moment().month(month).format('MMMM');

    monthOptions.push({
      label: monthTitle[0].toUpperCase() + monthTitle.slice(1),
      value: month,
    });
  }

  return monthOptions;
};
