import { useContext } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';

import { EDatepickerChangeType } from '../datepicker.types';

import { DatepickerContext } from '../datepicker-context/DatepickerContext';

import AppButton from '../../../buttons/appButton/AppButton';

import styles from '../AppDatepicker.module.scss';

const DatepickerYearRangeButtons = () => {
  const { momentValue, setMomentValue, yearArray, yearPickerShowToggle } =
    useContext(DatepickerContext);
  const startYear = yearArray[0].format('YYYY');
  const endYear = yearArray[yearArray.length - 1].format('YYYY');
  const changeYearRangeHandler = (type: EDatepickerChangeType) => {
    const operation = type === EDatepickerChangeType.increase ? 10 : -10;
    const newYear = momentValue?.clone().add(operation, 'year') || null;

    setMomentValue(newYear);
  };

  return (
    <div className={styles.buttons__wrapper}>
      <AppButton
        variant="outline-dark"
        className="border-0"
        onClick={() => changeYearRangeHandler(EDatepickerChangeType.decrease)}
      >
        <FaAngleLeft />
      </AppButton>

      <AppButton variant="outline-secondary" className="border-0" onClick={yearPickerShowToggle}>
        <span className={styles.calendar__title}>{`${startYear} - ${endYear}`}</span>
      </AppButton>

      <AppButton
        variant="outline-dark"
        className="border-0"
        onClick={() => changeYearRangeHandler(EDatepickerChangeType.increase)}
      >
        <FaAngleRight />
      </AppButton>
    </div>
  );
};

export default DatepickerYearRangeButtons;
