import { modalStore } from '../../../../../store/modal/ModalStore';

export const useTimePickerHandlers = () => {
  const { modalList } = modalStore;

  const onEnterTimePicker = () => {
    if (modalList.length > 0) {
      modalStore.setModalOverflow('hidden');
    }

    if (modalList.length === 0) {
      if (document.body.style.overflow === 'auto') {
        document.body.style.overflow = 'hidden';
        document.body.style.marginRight = '17px';
      }
    }
  };

  const onLeaveTimePicker = () => {
    if (modalList.length > 0) {
      modalStore.setModalOverflow('scroll');
    }

    if (modalList.length === 0) {
      if (document.body.style.overflow === 'hidden') {
        document.body.style.overflow = 'auto';
        document.body.style.marginRight = '0';
      }
    }
  };

  return { onEnterTimePicker, onLeaveTimePicker };
};
