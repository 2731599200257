import { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useInView } from 'react-intersection-observer';
import { Skeleton } from 'primereact/skeleton';

import { IAttendanceEmployee } from '../attendanceTable.types';

import { attendanceStore } from '../../../../store/attendanceStore/AttendanceStore';
import {
  ATTENDANCE_CELL_SIZE,
  ATTENDANCE_RESULT_SIZE,
} from '../attendance-tools/attendance-constants';

import AttendanceCell from './AttendanceCell';
import AttendanceCategoryBar from './AttendanceCategoryBar';

const ROOT_MARGIN = '800px 0px';

interface IAttendanceCellsListProps {
  employee: IAttendanceEmployee;
}

const AttendanceCellsList = observer(function CellsList({ employee }: IAttendanceCellsListProps) {
  const { currentDays, isLoading } = attendanceStore;
  const [isShow, setIsShow] = useState(false);
  const isShowCell = !isLoading && isShow;
  const isShowSkeleton = isLoading || !isShow;
  const { ref } = useInView({
    rootMargin: ROOT_MARGIN,
    onChange: useCallback((inView: boolean) => {
      setIsShow(inView);
    }, []),
  });
  const containerStyle = useMemo(
    () => ({
      width: `${currentDays.length * ATTENDANCE_CELL_SIZE + 5 * ATTENDANCE_RESULT_SIZE}px`,
      height: ATTENDANCE_CELL_SIZE,
    }),
    [currentDays.length],
  );

  return (
    <div ref={ref} className="d-flex" style={containerStyle}>
      {isShowSkeleton && <Skeleton className="h-100 border" />}

      {isShowCell &&
        currentDays?.map((dayData) => (
          <AttendanceCell
            key={dayData.date}
            date={dayData.date}
            employeeId={employee.id}
            rowIndex={employee.rowIndex}
            dayOfMonth={dayData.dayOfMonth}
            isDateSameOrBefore={dayData.isDateSameOrBefore}
          />
        ))}

      {isShowCell && <AttendanceCategoryBar {...{ employee }} />}
    </div>
  );
});

export default AttendanceCellsList;
