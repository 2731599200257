export enum EDatepickerChangeType {
  'increase' = 'increase',
  'decrease' = 'decrease',
}
export enum EDatepickerTimeFormat {
  'time' = 'time',
  'timeWithSeconds' = 'timeWithSeconds',
}

export enum EDatepickerTimeType {
  'hour' = 'hour',
  'minutes' = 'minutes',
  'seconds' = 'seconds',
}

export interface IDatepickerTime {
  id: number;
  type: EDatepickerTimeType;
  title: string;
  timeFormat: EDatepickerTimeFormat;
  format: 'HH' | 'mm' | 'ss';
  shortTitle: string;
}
