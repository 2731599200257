import { IAttendanceDataValue } from '../attendanceTable.types';

export const ATTENDANCE_CELL_SIZE = 28;
export const ATTENDANCE_RESULT_SIZE = 50;
export const MAX_SIZE_REQUEST = 800000;
export const LOADING_INDEX = 132;
export const TWO_HOURS_SECONDS = 7200;
export const DATE_FORMAT = 'YYYY-MM-DDT00:00:00.000[Z]';

export const NO_VALUE_COLOR = 'rgb(255, 191, 0)';
const VALUE_COLOR = 'rgb(255, 255, 0)';
const HOLIDAY_COLOR = 'rgb(241,219, 221)';
const VACATION_PREG_COLOR = 'rgb(239,239, 239)';
const MATERNITY_LEAVE_COLOR = 'rgb(217,217, 217)';
const EDUCATION_LEAVE = 'rgb(145,197, 123)';
const ILL_COLOR = 'rgb(190,228,239)';
const COMPLETED_COLOR = 'rgb(60,163,84)';
const ABSENCE_MOBILIZATION_COLOR = 'rgb(203,216,251)';
const HEALTH_SCREENING_COLOR = 'rgb(141,125,198)';
const GOV_DUTIES_COLOR = 'rgb(76,130,236)';

interface IColor {
  backgroundColor: string;
  textColor: 'red' | 'white' | '';
}

type IStatusColor = Record<IAttendanceDataValue['id_status'], IColor>;
export const STATUS_COLOR: IStatusColor = {
  1: { backgroundColor: VALUE_COLOR, textColor: '' },
  2: { backgroundColor: VALUE_COLOR, textColor: '' },
  3: { backgroundColor: VALUE_COLOR, textColor: '' },
  4: { backgroundColor: HOLIDAY_COLOR, textColor: '' },
  5: { backgroundColor: VALUE_COLOR, textColor: '' },
  6: { backgroundColor: VACATION_PREG_COLOR, textColor: '' },
  7: { backgroundColor: VALUE_COLOR, textColor: 'red' },
  8: { backgroundColor: VALUE_COLOR, textColor: '' },
  9: { backgroundColor: ILL_COLOR, textColor: '' },
  10: { backgroundColor: VALUE_COLOR, textColor: 'red' },
  11: { backgroundColor: COMPLETED_COLOR, textColor: 'red' },
  12: { backgroundColor: COMPLETED_COLOR, textColor: '' },
  13: { backgroundColor: 'transparent', textColor: '' },
  14: { backgroundColor: MATERNITY_LEAVE_COLOR, textColor: '' },
  15: { backgroundColor: EDUCATION_LEAVE, textColor: '' },
  16: { backgroundColor: GOV_DUTIES_COLOR, textColor: 'white' },
  17: { backgroundColor: ABSENCE_MOBILIZATION_COLOR, textColor: 'red' },
  18: { backgroundColor: ABSENCE_MOBILIZATION_COLOR, textColor: 'red' },
  19: { backgroundColor: HEALTH_SCREENING_COLOR, textColor: 'white' },
};
