import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';

import { attendanceStore } from '../../../../store/attendanceStore/AttendanceStore';
import { handleMouseScroll } from '../attendance-tools/handleMouseScroll';
import { getAttendanceCellData } from '../attendance-tools/getAttendanceCellData';
import { getAttendanceCellCoordinates } from '../attendance-tools/getAttendanceCellCoordinates';

import AttendanceDepartmentList from './AttendanceDepartmentList';
import AttendanceMask from './AttendanceMask';
import { userStore } from '../../../../store/userStore/UserStore';

const AttendanceBody = observer(() => {
  const tableRef = useRef<HTMLDivElement>(null);
  const { userInfo } = userStore;
  const isHasAccess = Object.values(userInfo?.permissions ?? {}).includes('ext_hr_write_all');

  // useAttendanceTableInit();
  // useBroadcastAttendanceTable();

  // Сброс позиций вне таблицы
  useEffect(() => {
    const documentResetPosition = (e: MouseEvent) => {
      if (tableRef.current && !tableRef.current.contains(e.target as Node)) {
        attendanceStore.resetPosition();
      }
    };

    document.addEventListener('mouseup', documentResetPosition);
    return () => {
      document.removeEventListener('mouseup', documentResetPosition);
    };
  }, [tableRef]);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isHasAccess) {
      const cell = (e.target as HTMLElement).closest('.att__table-cell');
      const cellMenu = (e.target as HTMLElement).closest('.att__table-menu__wrapper');
      const parentRect = e.currentTarget.getBoundingClientRect();

      if (!cellMenu) {
        attendanceStore.resetPosition();
      }

      if (cell && parentRect) {
        const { rowIndex, date, fullDate } = getAttendanceCellData(cell);
        const { x, y } = getAttendanceCellCoordinates(cell, parentRect);

        attendanceStore.setIsStartSelect(true);
        attendanceStore.setCellCoordinates('start', { x, y });
        attendanceStore.setCellPosition('start', { rowIndex, date, fullDate });
      }
    }
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isHasAccess) {
      if (attendanceStore.isStartSelect) {
        const cell = (e.target as HTMLElement).closest('.att__table-cell');
        const parentRect = e.currentTarget.getBoundingClientRect();

        if (cell && parentRect) {
          const { x, y } = getAttendanceCellCoordinates(cell, parentRect);
          const currentEndCoordinates = attendanceStore.endCellCoordinates;

          const isCoordinatesChanged =
            !currentEndCoordinates ||
            currentEndCoordinates.x !== x ||
            currentEndCoordinates.y !== y;

          if (cell && isCoordinatesChanged) {
            attendanceStore.setCellCoordinates('end', { x, y });
          }
        }
        // Автоматическая прокрутка страницы когда выделяются ячейки
        handleMouseScroll(e);
      }
    }
  };

  const handleMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isHasAccess) {
      const cell = (e.target as HTMLElement).closest('.att__table-cell');
      const cellMenu = (e.target as HTMLElement).closest('.att__table-menu__wrapper');
      const parentRect = e.currentTarget.getBoundingClientRect();

      if (cell && parentRect) {
        const { rowIndex, date } = getAttendanceCellData(cell);
        const { x, y } = getAttendanceCellCoordinates(cell, parentRect);

        if (!attendanceStore.endCellPosition) {
          attendanceStore.setCellCoordinates('end', { x, y });
          attendanceStore.setCellPosition('end', { rowIndex, date });
        }

        attendanceStore.setIsStartSelect(false);
      } else if (!cellMenu) {
        attendanceStore.resetPosition();
      }
    }
  };

  return (
    <div
      ref={tableRef}
      className="att__table"
      onMouseMove={handleMouseMove}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <AttendanceDepartmentList />
      <AttendanceMask />
    </div>
  );
});

export default AttendanceBody;
