import { EDatepickerTimeFormat, EDatepickerTimeType, IDatepickerTime } from './datepicker.types';

export const DATE_FORMATS = ['DD.MM.YYYY', 'D.M.YY', 'D.M.YYYY', 'DD.M.YYYY', 'DD.MM.YY'];
export const DATE_FORMATS_TIME = [
  'DD.MM.YYYY HH:mm',
  'D.M.YY HH:mm',
  'D.M.YYYY HH:mm',
  'DD.M.YYYY HH:mm',
  'DD.MM.YY HH:mm',
  'DD.MM.YYYY HH',
  'D.M.YY HH',
  'D.M.YYYY HH',
  'DD.M.YYYY HH',
  'DD.MM.YY HH',
];
export const DATE_FORMATS_SECONDS = [
  'DD.MM.YYYY HH:mm:ss',
  'D.M.YY HH:mm:ss',
  'D.M.YYYY HH:mm:ss',
  'DD.M.YYYY HH:mm:ss',
  'DD.MM.YY HH:mm:ss',
];

export const DATEPICKER_TIME: Record<string, IDatepickerTime> = {
  hour: {
    id: 1,
    type: EDatepickerTimeType.hour,
    title: 'Часы',
    shortTitle: 'ч.',
    timeFormat: EDatepickerTimeFormat.time,
    format: 'HH',
  },
  minutes: {
    id: 2,
    type: EDatepickerTimeType.minutes,
    title: 'Минуты',
    timeFormat: EDatepickerTimeFormat.time,
    shortTitle: 'мин.',
    format: 'mm',
  },
  seconds: {
    id: 3,
    type: EDatepickerTimeType.seconds,
    title: 'Секунды',
    timeFormat: EDatepickerTimeFormat.timeWithSeconds,
    shortTitle: 'сек.',
    format: 'ss',
  },
};

export const WEEK_DAYS = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
export const MONTH_TITLES_RU = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export const DATE_VALID_REGEX = /^[\d\s:.]*$/;
export const DATEPICKER_DEFAULT_FORMAT = 'DD.MM.YYYY';
export const DATEPICKER_MONTH_FORMAT = 'MMMM YYYY';
