import { useRef, useState, useTransition } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';

import AppButton from '../../../ui/buttons/appButton/AppButton';
import { GrClose } from 'react-icons/gr';
import { echoRequest } from '../../../../api/requests/echoRequest';
import CardFallbackSpinner from '../../../ui/spinners/CardFallbackSpinner';

interface IAddTaskFileFieldProps {
  input: any;
  fieldKey: string;
  fields: Record<string, { value: string }>;
  handleDeleteField: (key: string) => void;
}

const AddTaskFileField = ({
  input,
  fieldKey,
  fields,
  handleDeleteField,
}: IAddTaskFileFieldProps) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const currentField = fields[fieldKey];
  const [isPending, startTransition] = useTransition();

  const handleSetValue = () => {
    const currentFiles = Object.values(fields)
      .map((filed) => filed.value)
      .filter(Boolean);

    input.onChange(currentFiles);
  };

  const handleAddFiles = () => {
    startTransition(async () => {
      if (fileInputRef.current) {
        const files = fileInputRef.current.files;

        if (files) {
          const file = files[0];
          // const reader = new FileReader();
          // reader.onload = function (event) {
          //   // The file's text will be printed here
          //   console.log(event.target?.result);
          // };
          //
          // reader.readAsText(file);

          // ИЛИ ЧЕРЕЗ ФОРМ ДАТУ

          // const newFormData = new FormData();

          // if (files) {
          //   for (const file of files) {
          //     newFormData.append(file.name, file);
          //   }
          //
          //   await fetch('https://crm2.mtkrosberg.ru/test/other/echo/', {
          //     method: 'POST',
          //     body: newFormData,
          //     // headers: {
          //     //   'Content-Type': 'multipart/form-data',
          //     // },
          //   });
          // }

          if (file) {
            if (!input.value.includes(file.name)) {
              currentField.value = file.name;

              await echoRequest({ file: file.name });

              setErrorMessage(null);
            } else {
              setErrorMessage('Выбранный файл уже существует');
              fileInputRef.current.value = '';
              currentField.value = '';
            }
          } else {
            setErrorMessage(null);
            currentField.value = '';
          }
        }

        handleSetValue();
      }
    });
  };

  const deleteField = () => {
    handleDeleteField(fieldKey);

    handleSetValue();
  };

  return (
    <>
      <FormGroup controlId="formFile" className="d-flex flex-grow-1 gap-1">
        <FormControl
          ref={fileInputRef}
          type="file"
          onChange={handleAddFiles}
          className="rounded-1 shadow-none"
        />

        {isPending && (
          <div>
            <CardFallbackSpinner />
          </div>
        )}

        <AppButton variant="outline-danger" className="border-0" onClick={deleteField}>
          <GrClose />
        </AppButton>
      </FormGroup>

      {errorMessage && <div className="text-danger">{errorMessage}</div>}
    </>
  );
};

export default AddTaskFileField;
