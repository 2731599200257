export const ROUTES = {
  ROOT: '/',
  AUTH: '/auth',
  AUTH_URL: '/auth/:url',
  MAIN: '/main',
  TASKS: '/tasks',
  FINANCE: '/finance/',
  ORGANIZATIONS: '/clients',
  ORGANIZATION: '/clients/:uid',
  SERVICES: 'services/',
  ATTENDANCE: '/services/hr/attendance',
  HR: '/services/hr',
};
