import { observer } from 'mobx-react-lite';
import { attendanceStore } from '../../../../../store/attendanceStore/AttendanceStore';
import { IAttendanceStatus } from '../../attendanceTable.types';
import { setAttendanceRequestData } from '../../attendance-tools/setAttendanceRequestData';
import { getChunkRequestSize } from '../../attendance-tools/getChunkRequestSize';
import { setAttendanceValue } from '../../../../../api/socketApi/attendanceTable/setAttendanceValue';
import AttendanceAbsenceMenu from '../AttendanceAbsenceMenu';
import AppButton from '../../../../ui/buttons/appButton/AppButton';
import { GrClose } from 'react-icons/gr';
import { useAttendanceMaskPosition } from '../../attendance-hooks/useAttendanceMaskPosition';

const AttendanceMobileMenu = observer(function AttendanceTableMenu() {
  const { attendanceStatuses, resetPosition, isMobileMenuOpen } = attendanceStore;
  const { isHasValue } = useAttendanceMaskPosition();

  const handleOptionClick = async (option: IAttendanceStatus) => {
    const { requestDataList } = setAttendanceRequestData(option);

    const chunkSize = getChunkRequestSize(requestDataList);

    for (let i = 0; i < requestDataList.length; i += chunkSize) {
      const chunk = requestDataList.slice(i, i + chunkSize);

      await setAttendanceValue({ id_status: option.id, items: chunk });
    }

    resetPosition();
    attendanceStore.setIsMobileMenuOpen(false);
  };

  return (
    <div
      style={{ display: isMobileMenuOpen ? 'block' : 'none' }}
      className="att__table-mobile__menu_wrapper"
    >
      <div className="d-flex align-items-start justify-content-between gap-2">
        <h5>Выберите значение</h5>
        <AppButton
          variant="outline-dark"
          className="border-0 p-2"
          onClick={() => attendanceStore.setIsMobileMenuOpen(false)}
        >
          <GrClose />
        </AppButton>
      </div>
      <div className="d-flex flex-column">
        {attendanceStatuses.map((option) => (
          <AppButton
            key={option.id}
            variant="outline-primary"
            className="border-0 p-1"
            onClick={() => handleOptionClick(option)}
          >
            <div
              style={{
                backgroundColor: option.bg_color,
                color: option.font_color,
              }}
              className="att__table-menu-icon me-2"
            >
              {option.short_title}
            </div>
            {option.title}
          </AppButton>
        ))}
      </div>

      {isHasValue && (
        <>
          <hr />
          <AttendanceAbsenceMenu />
        </>
      )}
    </div>
  );
});

export default AttendanceMobileMenu;
