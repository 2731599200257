import moment from 'moment';

export const getCalendar = (date: moment.Moment | null) => {
  const currentDate = moment();
  const currentMonth = [];
  const firstDayOfMonth = moment({
    y: date ? date.year() : currentDate.year(),
    M: date ? date.month() : currentDate.month(),
    d: 1,
  });

  const firstDayIndex = firstDayOfMonth.weekday();

  firstDayOfMonth.add(-firstDayIndex, 'day');

  currentMonth.push(firstDayOfMonth.clone());
  for (let i = 0; i < 41; i++) {
    currentMonth.push(firstDayOfMonth.add(1, 'day').clone());
  }

  return currentMonth;
};
