import React from 'react';
import moment from 'moment';
import 'moment/locale/ru';

import { EDatepickerTimeFormat } from './datepicker.types';

import DatepickerProvider from './datepicker-context/DatepickerProvider';
import DatepickerContextWrapper from './datepicker-components/DatepickerContextWrapper';

interface IDatePickerProps {
  value: string | null;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  name?: string;
  timeFormat?: keyof typeof EDatepickerTimeFormat;
  disableRange?: 'year' | 'month';
}

const AppDatepicker = ({ timeFormat, value, onChange, name, disableRange }: IDatePickerProps) => {
  moment().locale('ru');

  return (
    <DatepickerProvider
      value={value}
      onChange={onChange}
      timeFormat={timeFormat}
      name={name}
      disableRange={disableRange}
    >
      <DatepickerContextWrapper />
    </DatepickerProvider>
  );
};

export default AppDatepicker;
