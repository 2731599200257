import { TAttendanceDepartments } from '../attendanceTable.types';

export function transformToPersonalStructure(
  groupedData: TAttendanceDepartments,
): TAttendanceDepartments {
  const allItems = Object.values(groupedData).flatMap((group) => group.items);

  // Сортируем сначала по surname, затем по name
  const sortedItems = [...allItems].sort((a, b) => {
    const surnameCompare = a.surname.localeCompare(b.surname);
    return surnameCompare !== 0 ? surnameCompare : a.name.localeCompare(b.name);
  });

  return {
    personal: {
      items: sortedItems,
    },
  };
}
