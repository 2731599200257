import { makeAutoObservable } from 'mobx';

import { IEditGeneralInitialState } from '../../components/company/types/change.types';
import { EDataLimit } from '../../components/company/types/financeTab.types';
import {
  IContactEmailItem,
  IContactPhoneItem,
  IContactTabItem,
} from '../../components/company/types/contactTab.types';
import {
  EFilterDataPresets,
  IFilterData,
} from '../../components/company/types/companyFilter.types';
import {
  ECompanyError,
  ECompanyExistField,
  ECompanyTabKeys,
  EFinanceTabKeys,
  ICompanies,
  ICompanyData,
  IFetchCompanyMainDataProps,
  IFetchCompanyTabDataProps,
  IFetchEventData,
} from '../../components/company/types/company.types';
import {
  IInformationActivities,
  IInformationCatManagers,
  IInformationGroups,
  IInformationTabAddress,
  IInformationTabData,
} from '../../components/company/types/informationTab.types';

import { screenStore } from '../ScreenStore';
import { companyRequest } from '../../api/requests/companyRequest';
import { companyTabRequest } from '../../api/requests/companyTabRequest';
import { filterDataRequest } from '../../api/requests/filterDataRequest';
import { companyListRequest, ICompanyListItem } from '../../api/requests/companyListRequest';
import { companyFinanceTabs } from '../../constants/companyFinanceTabs';
import { getTabData } from '../../utils/getTabData';
import { EEventItemType } from '../../components/company/types/eventTab.types';
import { getFormattedTracks } from '../../utils/getFormattedTracks';
import { playerStore } from '../PlayerStore/PlayerStore';

class CompanyStore {
  companies: ICompanies = {};
  mainCompany: ICompanyData | null = null;
  limit: EDataLimit = screenStore.isMobile ? EDataLimit.MOBILE : EDataLimit.DESKTOP;

  constructor() {
    makeAutoObservable(this);
  }

  setCurrentTab(id: string, tabKey: ECompanyTabKeys) {
    this.companies[id].currentTab = tabKey;
  }

  private setCompanyError(id: string, typeError: ECompanyError | null) {
    const errorData = { state: typeError };
    this.setCompaniesData(id, errorData);
  }

  setMainCompany(data: any) {
    this.mainCompany = { ...data };
  }

  setCompaniesData(id: string, data: any) {
    this.companies[id] = { ...this.companies[id], ...data };
  }

  setCompanyValue(id: string, field: ECompanyExistField, value: boolean) {
    const isExist = { [field]: value };
    this.setCompaniesData(id, isExist);
  }

  setEventTabState(id: string, value: any) {
    this.companies[id].eventTabState = value;
  }

  async fetchInitialCompanyData({
    uid,
    id,
    currentTab = ECompanyTabKeys.main,
    financeTab = EFinanceTabKeys.gantTable,
  }: {
    uid: string;
    id: string;
    currentTab?: ECompanyTabKeys;
    financeTab?: EFinanceTabKeys;
  }) {
    const mainDataResponse = await companyRequest(`${uid}`);

    if (mainDataResponse.hasErrors) {
      return undefined;
    }

    if (!mainDataResponse.hasErrors) {
      const companyMainData = mainDataResponse?.dataArray;
      const companyTabs = getTabData(companyMainData);

      const existCurrentTab = companyTabs?.[currentTab] ? currentTab : ECompanyTabKeys.main;
      const existFinanceTab = companyFinanceTabs?.[financeTab]
        ? financeTab
        : EFinanceTabKeys.gantTable;

      const finalMainData = {
        ...companyMainData?.aData,
        id,
        companyTabs,
        currentTab: existCurrentTab,
        financeTab: existFinanceTab,
      };

      this.setCompaniesData(id, finalMainData);
      this.setMainCompany(finalMainData);

      return finalMainData;
    }
  }

  async fetchCompanyMainData({
    id,
    uid,
    isMain,
    isModal,
    tabKey,
    financeTabKey,
  }: IFetchCompanyMainDataProps) {
    const mainDataResponse = await companyRequest(uid);

    if (mainDataResponse.hasErrors) {
      this.setCompanyError(id, ECompanyError.mainDataError);
      this.setCompanyValue(id, ECompanyExistField.mainDataExist, false);
    }

    if (!mainDataResponse.hasErrors) {
      const companyMainData = mainDataResponse?.dataArray;
      const companyTabs = getTabData(companyMainData);

      const existCurrentTab = tabKey
        ? companyTabs[tabKey]
          ? tabKey
          : ECompanyTabKeys.main
        : ECompanyTabKeys.main;

      const existCurrentFinanceTab = financeTabKey
        ? companyFinanceTabs[financeTabKey]
          ? financeTabKey
          : EFinanceTabKeys.gantTable
        : EFinanceTabKeys.gantTable;

      const finalMainData = {
        ...companyMainData?.aData,
        id,
        companyTabs,
        isModal,
        currentTab: existCurrentTab,
        financeTab: existCurrentFinanceTab,
      };

      this.setCompaniesData(id, finalMainData);

      if (isMain) this.setMainCompany(finalMainData);

      this.setCompanyValue(id, ECompanyExistField.mainDataExist, true);

      if (this.companies[id].mainDataExist) {
        await this.fetchCompanyTabData({ uid, id, tabKey: existCurrentTab });
      }
    }
  }

  async fetchCompanyTabData({ id, uid, tabKey, tabParams }: IFetchCompanyTabDataProps) {
    const contactTabParams = { bIncudeVcards: true };
    const currentTabParams = tabKey === ECompanyTabKeys.contacts ? contactTabParams : tabParams;

    this.setCompanyError(id, null);
    this.setCompanyValue(id, ECompanyExistField.isTabDataLoading, true);
    this.setCompanyValue(id, ECompanyExistField.tabDataExist, false);

    const companyTabDataResponse = await companyTabRequest(uid, tabKey, currentTabParams);

    if (companyTabDataResponse.hasErrors) {
      this.setCompanyError(id, ECompanyError.tabDataError);
      this.setCompanyValue(id, ECompanyExistField.tabDataExist, false);
    }

    if (!companyTabDataResponse.hasErrors) {
      const companyTabData = companyTabDataResponse?.dataArray;
      this.setCompaniesData(id, { currentTab: ECompanyTabKeys[tabKey], tabData: companyTabData });
      this.setCompanyValue(id, ECompanyExistField.tabDataExist, true);
    }

    this.setCompanyValue(id, ECompanyExistField.isTabDataLoading, false);
  }

  async fetchInitialEventData({ id, uid, filterParams }: IFetchEventData) {
    const initialTabParams = {
      iPage: 1,
      iLimitPerPage: this.limit,
      rFilter: filterParams,
    };

    this.setCompanyError(id, null);
    this.setCompanyValue(id, ECompanyExistField.isEventDataLoading, true);
    this.setCompanyValue(id, ECompanyExistField.eventDataExist, false);

    const companyEventDataResponse = await companyTabRequest(
      uid,
      ECompanyTabKeys.events,
      initialTabParams,
    );

    if (companyEventDataResponse.hasErrors) {
      this.setCompanyError(id, ECompanyError.tabDataError);
      this.setCompanyValue(id, ECompanyExistField.tabDataExist, false);
      this.setCompanyValue(id, ECompanyExistField.eventDataExist, false);
      this.setCompanyValue(id, ECompanyExistField.isEventDataLoading, false);
    }

    if (!companyEventDataResponse.hasErrors) {
      const companyTabData: any = companyEventDataResponse?.dataArray;

      const formattedData = {
        currentTab: ECompanyTabKeys.events,
        tabData: companyTabData,
        eventTabState: initialTabParams.rFilter,
        eventTabPage: companyTabData?.aData?.length < this.limit ? null : initialTabParams.iPage,
      };

      this.setCompaniesData(id, formattedData);

      this.setCompanyValue(id, ECompanyExistField.eventDataExist, true);
    }

    this.setCompanyValue(id, ECompanyExistField.isEventDataLoading, false);
  }

  async fetchCompanyEventsMoreData({ id, uid, filterParams }: IFetchEventData) {
    const company = this.companies[id];
    const eventTabPage = company ? company.eventTabPage : null;

    if (eventTabPage) {
      const eventsTabParams = {
        iPage: eventTabPage + 1,
        iLimitPerPage: this.limit,
        rFilter: filterParams,
      };

      this.setCompanyError(id, null);
      this.setCompanyValue(id, ECompanyExistField.isEventDataMoreLoading, true);

      const companyTabDataResponse = await companyTabRequest(
        uid,
        ECompanyTabKeys.events,
        eventsTabParams,
      );

      if (companyTabDataResponse.hasErrors) {
        this.setCompanyError(id, ECompanyError.tabDataError);
        this.setCompanyValue(id, ECompanyExistField.tabDataExist, false);
      }

      if (!companyTabDataResponse.hasErrors) {
        const nextEventTabData = companyTabDataResponse?.dataArray;
        const nextTabData = nextEventTabData?.aData;
        const nextUserData = nextEventTabData?.aUserDetails;
        const nextCallTabData = nextTabData?.filter((data) => data.sType === EEventItemType.call);

        if (Object.values(playerStore.sourceTracks).length > 0) {
          const nextTracks = getFormattedTracks({
            tabData: nextCallTabData,
            userData: nextUserData,
          });
          playerStore.setNextSourceTracks(nextTracks);
        }

        const newData = [...company?.tabData?.aData, ...nextTabData];

        const newUser = {
          ...company?.tabData?.aUserDetails,
          ...nextUserData,
        };

        const eventData = { aData: newData, aUserDetails: newUser };

        this.setCompaniesData(id, {
          currentTab: ECompanyTabKeys.events,
          tabData: eventData,
          eventTabState: eventsTabParams.rFilter,
          eventTabPage: nextEventTabData?.aData?.length < this.limit ? null : eventsTabParams.iPage,
        });
      }

      this.setCompanyValue(id, ECompanyExistField.isEventDataMoreLoading, false);
    }
  }

  async fetchCompanyFinanceData(
    uid: string,
    id: string,
    tabKey: ECompanyTabKeys,
    financeTabKey: EFinanceTabKeys,
    isMoreData?: boolean,
  ) {
    const company = this.companies[id];
    const limit = screenStore.isMobile ? EDataLimit.MOBILE : EDataLimit.DESKTOP;
    const financeTabParams = {
      sPresetName: financeTabKey,
      aParams: {
        type: 'default', // "external"
        disableCounts: false, // Отключает расчёт кол-ва для производительности
        pageLimit: limit, // записей на странице
        iPage: isMoreData ? company.tabData.iNextPage : 1, // номер страницы для загрузки
      },
    };

    this.setCompanyError(id, null);
    this.setCompanyValue(id, ECompanyExistField.tabDataExist, true);

    if (!isMoreData) this.setCompanyValue(id, ECompanyExistField.isFinanceDataLoading, true);
    if (isMoreData) this.setCompanyValue(id, ECompanyExistField.isFinanceDataMoreLoading, true);

    const financeTabDataResponse = await companyTabRequest(uid, tabKey, financeTabParams);

    if (financeTabDataResponse.hasErrors) {
      this.setCompanyError(id, ECompanyError.financeDataError);
      this.setCompanyValue(id, ECompanyExistField.financeDataExist, false);
    }

    if (!financeTabDataResponse.hasErrors) {
      const tabData = financeTabDataResponse?.dataArray;
      const data = Array.isArray(tabData.aData) ? tabData.aData : [];
      const aData = company.tabData?.aData ? [...company.tabData.aData, ...data] : data;
      const newValue = { ...tabData, aData };

      this.setCompaniesData(id, {
        currentTab: ECompanyTabKeys[tabKey],
        financeTab: financeTabKey,
        tabData: isMoreData ? newValue : tabData,
      });

      this.setCompanyValue(id, ECompanyExistField.financeDataExist, true);
    }

    if (!isMoreData) this.setCompanyValue(id, ECompanyExistField.isFinanceDataLoading, false);
    if (isMoreData) this.setCompanyValue(id, ECompanyExistField.isFinanceDataMoreLoading, false);
  }

  removeCompany(id: string) {
    delete this.companies[id];
  }

  removeMainCompany() {
    this.mainCompany = null;
  }

  // Установка начального состояния для общих данных
  async setGeneralInitialState(id: string, data: IInformationTabData) {
    const initialState: IEditGeneralInitialState = {
      clientTypes: data?.company_type_id,
      clientStatus: data?.company_status_id,
      sellersList: data?.seller_id,
      categoryOptions: data?.cut_abc_id,
      importantOptions: data?.b_client_vip ? 1 : 0,
      activityTypes: [],
      inn: data?.inn,
      kpp: data?.kpp,
      duplicatedArray: null,
    };

    if (data?.id_parent) {
      initialState.holding = await this.fetchHoldingData(data.id_parent);
    }

    const generalData = { generalInitialState: initialState };

    this.setCompaniesData(id, generalData);

    return generalData;
  }

  // Запрос данных холдинга
  async fetchHoldingData(companyId: number): Promise<ICompanyListItem[] | null> {
    const holdingResponse = await companyListRequest(1, { companyIdList: `${companyId}` });
    const holdingData = holdingResponse?.dataArray;

    return holdingData ? Object.values(holdingData.aData ?? {}) : null;
  }

  // Установка начального состояния для адресов
  setAddressInitialState(id: string, data: IInformationTabData) {
    const addressData = {
      addressInitialState: {
        areas: { areasValue: data?.id_obl, code: data?.region_phone_code }, //  Нужен ли телефонный код
        district: data?.id_region,
        organizationAddresses: this.formatAddresses(data?.json_addresses),
      },
    };

    this.setCompaniesData(id, addressData);
  }

  // Форматирование адресов
  private formatAddresses(addresses: IInformationTabAddress[]) {
    return (
      addresses?.map((el) => ({
        ...el,
        object_type: el.object_type_id,
        chosen: false,
        selected: false,
      })) || []
    );
  }

  // Установка начального состояния для данных организации
  setDataInitialState(id: string, data: IInformationTabData) {
    const companyData = {
      dataInitialState: {
        clientTypes: data?.company_type_id,
        landSize: data?.company_area,
        organizationLink: data?.site_url,
        activityTypes: this.extractActivityTypes(data?.json_activities),
        organizationComment: data?.description,
        foundationDate: data?.date_birthday || null,
        groupList: this.extractGroupIds(data?.json_groups),
        inn: data?.inn,
        kpp: data?.kpp,
        duplicatedArray: null,
      },
    };

    this.setCompaniesData(id, companyData);
  }

  // Извлечение идентификаторов активности
  private extractActivityTypes(activities: IInformationActivities[]) {
    return activities?.map((el) => el.id_activity) || [];
  }

  // Извлечение идентификаторов групп
  private extractGroupIds(groups: IInformationGroups[] | null) {
    return groups?.map((el) => el.id_group) || [];
  }

  // Установка начального состояния для техники
  setTechnicInitialState(id: string, data: IInformationTabData) {
    const technics = data?.json_techniks?.reduce<Record<string, any>>((acc, curr) => {
      acc[curr.uid as keyof typeof acc] = {
        technicType: curr.id_type,
        technicMark: curr.id_type === 4 ? curr.brand : curr.brand_id,
        technicModel: curr.id_type === 4 ? curr.model : curr.model_id,
        technicCount: curr.count,
        technicComment: curr.comment,
      };
      return acc;
    }, {});

    const technicData = { technicInitialState: { ...technics } };

    this.setCompaniesData(id, technicData);
  }

  // Установка начального состояния для изменения менеджера
  async setManagerInitialState(id: string, data: IInformationTabData) {
    const filterData = await this.fetchFilterData(EFilterDataPresets.CHANGE_MANAGER);

    const mainManager = data.main_manager;
    const categoryManagers = this.getCategoryManagers(data.json_cat_sales_managers);
    const selectedManagerList = { mainManager, catManagerList: [...categoryManagers] };

    const disabledManagerList = mainManager ? [mainManager.id] : [];
    const disabledCategoryList = categoryManagers.map((manager) => manager.cat_id);

    const managerList = this.formatManagerList(filterData);
    const categoryList = this.formatCategoryList(filterData);

    const managerData = {
      managerInitialState: {
        managerList,
        categoryList,
        selectedManagerList,
        disabledManagerList,
        disabledCategoryList,
      },
    };

    this.setCompaniesData(id, managerData);
  }

  // Запрос данных фильтра
  private async fetchFilterData(preset: EFilterDataPresets) {
    const response = await filterDataRequest(preset);
    return response.dataArray;
  }

  // Получение менеджеров по категориям
  private getCategoryManagers(categoryManagers: IInformationCatManagers | null) {
    return categoryManagers ? Object.values(categoryManagers) : [];
  }

  // Форматирование списка менеджеров
  private formatManagerList(filterData: IFilterData) {
    return Object.values(filterData?.aClientManagers ?? {}).flatMap((el) => ({
      label: el.sDepartmentTitle,
      items: Object.values(el.aItems).flatMap((item) => ({
        label: item.sFullName,
        value: item.iManagerId,
      })),
    }));
  }

  // Форматирование списка категорий
  private formatCategoryList(filterData: IFilterData) {
    return Object.values(filterData?.aCategorySalesList ?? {}).flatMap((el) => ({
      label: el.sFullTitle,
      value: el.iIdElement,
    }));
  }

  // Установка начального состояния для изменения контактов
  async setContactInitialState(id: string, data: IContactTabItem[]) {
    const contacts = data.map((contactData) => ({
      c_position: contactData.c_position,
      birthday: contactData.birthday,
      firstName: contactData.name.split(' ')[0],
      secondName: contactData.name.split(' ')[1],
      lastName: contactData.name.split(' ')[2],
      chosen: false,
      selected: false,
      phones: this.formatPhones(contactData.phones),
      emails: this.formatEmails(contactData.emails),
    }));

    const contactData = { contactInitialState: { ...contacts } };
    this.setCompaniesData(id, contactData);
  }

  // Форматирование номеров телефонов
  private formatPhones(phones: IContactPhoneItem[] | undefined) {
    return phones
      ? phones.map((phone) => ({
          ...phone,
          chosen: false,
          selected: false,
          phone: `${phone.code}${phone.phone}`,
        }))
      : [];
  }

  // Форматирование emails
  private formatEmails(emails: IContactEmailItem[] | null) {
    return emails
      ? emails.map((email) => ({
          ...email,
          chosen: false,
          selected: false,
        }))
      : [];
  }
}

export const companyStore = new CompanyStore();
