import React, { createContext } from 'react';
import { IAppDropdownGroupOption, IAppDropdownOption } from '../app_dropdown.types';

interface DropdownContext {
  value: any;
  onChange: (value: any) => void;
  inputLabel: string;
  isInputActive: any;
  initialOptions: IAppDropdownOption[];
  groupInitialOptions: IAppDropdownGroupOption[];
  type: 'dropdown' | 'multiselect';
  renderFunction: (optionList: IAppDropdownOption[]) => React.JSX.Element[];
  disabledList?: any[];
}

export const AppDropdownContext = createContext<DropdownContext>({
  value: null,
  inputLabel: '',
  isInputActive: undefined,
  initialOptions: [],
  groupInitialOptions: [],
  type: 'dropdown',
  onChange: () => {},
  renderFunction: () => [],
  disabledList: [],
});
