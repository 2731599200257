import { FaFile } from 'react-icons/fa';

import { ICommentFile, IReportFile, ITaskFile } from '../../task-types';

interface ITaskCardFileListProps {
  filesArray: ITaskFile[] | IReportFile[] | ICommentFile[];
}

const TaskCardFileList = ({ filesArray }: ITaskCardFileListProps) => {
  return (
    <div className="d-flex flex-wrap align-items-center gap-2">
      {filesArray.map((file) => (
        <div key={file.id} className="d-flex align-items-center gap-1">
          <FaFile className="text-primary" />
          <span>{file.filename}</span>
        </div>
      ))}
    </div>
  );
};

export default TaskCardFileList;
