import { observer } from 'mobx-react-lite';

import { IAttendanceEmployee } from '../attendanceTable.types';

import { getAttendanceCategoryValues } from '../attendance-tools/getAttendanceCategoryValues';
import { ATTENDANCE_RESULT_SIZE } from '../attendance-tools/attendance-constants';
import { attendanceStore } from '../../../../store/attendanceStore/AttendanceStore';
import { modalStore } from '../../../../store/modal/ModalStore';

import AttendanceAbsenceModal from './AttendanceAbsenceModal';
import { screenStore } from '../../../../store/ScreenStore';

interface IAttendanceCategoryBarProps {
  employee: IAttendanceEmployee;
}

const AttendanceCategoryBar = observer(function AttendanceCategoryBar({
  employee,
}: IAttendanceCategoryBarProps) {
  const { attendanceCategories } = attendanceStore;
  const { isMobile } = screenStore;
  const categoryValue = getAttendanceCategoryValues(attendanceCategories, employee);

  const handleAbsenceModal = () => {
    if (categoryValue.coefficient !== 0) {
      modalStore.openModal({
        children: <AttendanceAbsenceModal {...{ employee, categoryValue }} />,
      });
    }
  };

  return (
    <>
      <div
        className="att__table-index border border-dark-subtle"
        style={{ width: isMobile ? '100%' : ATTENDANCE_RESULT_SIZE }}
      >
        {categoryValue.workday ?? '-'}
      </div>
      <div
        className="att__table-index border border-dark-subtle"
        style={{ width: isMobile ? '100%' : ATTENDANCE_RESULT_SIZE }}
      >
        {categoryValue.sickLeave ?? '-'}
      </div>
      <div
        className="att__table-index border border-dark-subtle"
        style={{ width: isMobile ? '100%' : ATTENDANCE_RESULT_SIZE }}
      >
        {categoryValue.absence}
      </div>
      <div
        className="att__table-index border border-dark-subtle"
        style={{ width: isMobile ? '100%' : ATTENDANCE_RESULT_SIZE }}
      >
        {categoryValue.coefficient ? categoryValue.coefficient : '-'}
      </div>
      <div
        className={`att__table-index ${categoryValue.coefficient ? 'coefficient' : ''} border border-dark-subtle`}
        style={{ width: isMobile ? '100%' : ATTENDANCE_RESULT_SIZE }}
        onClick={handleAbsenceModal}
        role={categoryValue.coefficient ? 'button' : ''}
      >
        {categoryValue.timeLeave ? categoryValue.timeLeave : '-'}
      </div>
    </>
  );
});

export default AttendanceCategoryBar;
