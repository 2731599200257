import { SocketApi } from '../socketApi';
import { attendanceStore } from '../../../store/attendanceStore/AttendanceStore';

export const setAttendanceValue = (chunk: any): Promise<void> => {
  return new Promise((resolve, reject) => {
    SocketApi.getClient()?.emit('setAttendanceData', chunk, (response: any) => {
      if (response) {
        attendanceStore.setAttendanceDataValues(response);
        resolve();
      } else {
        reject(new Error('Failed to set attendance data'));
      }
    });
  });
};
