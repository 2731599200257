import { Tab } from 'react-bootstrap';

import { ETaskTab } from '../../task-types';

import TaskCommentTabForm from './TaskCommentTabForm';
import TaskCardCommentList from './TaskCardCommentList';

const TaskCardCommentTab = () => (
  <Tab.Pane eventKey={ETaskTab.comments}>
    <TaskCommentTabForm />
    <hr />
    <TaskCardCommentList />
  </Tab.Pane>
);

export default TaskCardCommentTab;
