// import AppButton from '../../../buttons/appButton/AppButton';
import { GrClose } from 'react-icons/gr';
// import styles from '../../AppDatepicker/AppDatepicker.module.scss';
// import React from 'react';

const AppDropdownResetButton = ({ handler }: { handler: () => void }) => {
  return (
    <>
      {/*<AppButton variant="outline-secondary" className="border-0 p-1" onClick={handler}>*/}
      {/*  <GrClose />*/}
      {/*</AppButton>*/}
      <GrClose className={` text-secondary`} onClick={handler} role="button" />
    </>
  );
};

export default AppDropdownResetButton;
