import { observer } from 'mobx-react-lite';
import { MdPerson } from 'react-icons/md';
import { LuClock } from 'react-icons/lu';

import { taskStore } from '../../../../store/tasks/TaskStore';
import { convertFromResponseDate } from '../../../../utils/format-date/convertFromResponseDate';

import TaskCardFileList from '../card-components/TaskCardFileList';

const TaskCardReportTab = observer(() => {
  const { taskCardData } = taskStore;

  return (
    <div className="d-flex flex-column align-items-start gap-2">
      {taskCardData?.cardData.reportData &&
        taskCardData?.cardData.reportData.map((report) => (
          <div key={report.id} className="border border-primary rounded-1 p-2 w-100">
            <div className="d-flex flex-wrap align-items-center justify-content-between gap-1 mb-1">
              <div className="d-flex align-items-center gap-1">
                <MdPerson className="fs-5 text-primary" />
                <span className="text-primary fw-bold me-5">{report.managerFrom.fullName}</span>
              </div>

              <div className="d-flex align-items-center gap-1">
                <LuClock className="fs-5" />
                <span className="text-muted">{convertFromResponseDate(report.date_create)}</span>
              </div>
            </div>

            <div className="d-flex flex-wrap align-items-center justify-content-between gap-1">
              <div dangerouslySetInnerHTML={{ __html: report.message }} />

              <TaskCardFileList filesArray={report.report_files} />
            </div>
          </div>
        ))}
    </div>
  );
});

export default TaskCardReportTab;
