import { IAttendanceCellPosition } from '../attendanceTable.types';

export const getAttendanceCellRange = (
  startCellPosition: IAttendanceCellPosition,
  endCellPosition: IAttendanceCellPosition,
) => {
  const startRowIndex = Math.min(startCellPosition.rowIndex, endCellPosition.rowIndex);
  const endRowIndex = Math.max(startCellPosition.rowIndex, endCellPosition.rowIndex);
  const startDate = Math.min(startCellPosition.date, endCellPosition.date);
  const endDate = Math.max(startCellPosition.date, endCellPosition.date);

  return { startRowIndex, endRowIndex, startDate, endDate };
};
