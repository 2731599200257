import { useCallback, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Skeleton } from 'primereact/skeleton';

import { IAttendanceEmployee } from '../../attendanceTable.types';

import { Accordion } from 'react-bootstrap';
import { attendanceStore } from '../../../../../store/attendanceStore/AttendanceStore';
import { observer } from 'mobx-react-lite';
import AttendanceMobileCell from './AttendanceMobileCell';
import AttendanceCategoryBar from '../AttendanceCategoryBar';

const ROOT_MARGIN = '100px 0px';

const AttendanceMobileItem = observer(({ employee }: { employee: IAttendanceEmployee }) => {
  const { currentDays } = attendanceStore;
  const employeeFullName = `${employee?.surname} ${employee?.name} ${employee?.patronymic}`;
  const [isShow, setIsShow] = useState(false);
  // const isShowSkeleton = isLoading || !isShow;
  // const isShowCell = !isLoading && isShow;
  const { ref } = useInView({
    rootMargin: ROOT_MARGIN,
    onChange: useCallback((inView: boolean) => {
      setIsShow(inView);
    }, []),
  });
  return (
    <div ref={ref}>
      {!isShow && <Skeleton className="border" height="30px" />}
      {isShow && (
        <Accordion className="rounded-1">
          <Accordion.Button className="shadow-none rounded-1 px-2 py-1">
            {employeeFullName}
          </Accordion.Button>
          <Accordion.Body className="bg-white p-1">
            <h6>{employee.post}</h6>
            <div className="d-flex flex-wrap row-gap-1">
              {currentDays?.map((dayData) => {
                const isHoliday = dayData.dayOfWeek === 'вс' || dayData.dayOfWeek === 'сб';
                
                return (
                  <AttendanceMobileCell
                    key={dayData.date}
                    date={dayData.date}
                    employeeId={employee.id}
                    rowIndex={employee.rowIndex}
                    dayOfMonth={dayData.dayOfMonth}
                    isDateSameOrBefore={dayData.isDateSameOrBefore}
                    isHoliday={isHoliday}
                  />
                );
              })}
            </div>

            <div className="d-flex mt-2">
              <div className="w-100 text-center border border-dark-subtle fw-bold">Был</div>
              <div className="w-100 text-center border border-dark-subtle fw-bold">Болел</div>
              <div className="w-100 text-center border border-dark-subtle fw-bold">Отсут.</div>
              <div className="w-100 text-center border border-dark-subtle fw-bold">Коэф.</div>
              <div className="w-100 text-center border border-dark-subtle fw-bold">Врем.</div>
            </div>
            <div className="d-flex">
              <AttendanceCategoryBar employee={employee} />
            </div>
          </Accordion.Body>
        </Accordion>
      )}
    </div>
  );
});

export default AttendanceMobileItem;
