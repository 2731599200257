import { socketApiStore } from '../../../store/socketApiStore/SocketApiStore';
import { useEffect } from 'react';
import { SocketApi } from '../socketApi';
import { attendanceStore } from '../../../store/attendanceStore/AttendanceStore';

interface ISocketError {
  moduleType: string;
  methodType?: string;
  message: string;
  data?: any;
}

export const useListenSocketErrors = () => {
  const { isSocketLoaded } = socketApiStore;

  useEffect(() => {
    if (isSocketLoaded) {
      SocketApi.getClient()?.on('socketError', (response: ISocketError) => {
        if (response.moduleType === 'attendanceError') {
          attendanceStore.setAttendanceError(response.message);
          console.log(response);
        }
      });
    }
  }, [isSocketLoaded]);
};
