import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ETaskSocketKey } from '../../../components/tasks/task-types';

import { SocketApi } from '../socketApi';
import { taskStore } from '../../../store/tasks/TaskStore';
import { socketApiStore } from '../../../store/socketApiStore/SocketApiStore';

export const useTaskCardData = () => {
  const { isSocketLoaded } = socketApiStore;
  const { taskId } = useParams();

  useEffect(() => {
    if (isSocketLoaded) {
      taskStore.setIsCardLoading(true);

      SocketApi.getClient()?.emit(
        ETaskSocketKey.getTaskCardData,
        { taskId },
        taskStore.setCardData,
      );
    }
  }, [isSocketLoaded, taskId]);

  useEffect(() => {
    return () => {
      console.log('return card');
    };
  }, []);
};
