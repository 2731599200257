import { apiClient, IBaseResponse } from '../apiClient';
import { CLIENT_URL } from '../api.constants';
import { ICompanyDataResponse } from '../../components/company/types/company.types';

export const companyRequest = async (
  uid: string | null,
): Promise<IBaseResponse<ICompanyDataResponse>> =>
  await apiClient(CLIENT_URL, {
    data: {
      sPresetName: 'clientTabs',
      rFilterData: {
        sElementUid: uid,
      },
      // iDebugSleepSec: 5,
    },
  });
