import { useContext } from 'react';
import moment from 'moment/moment';

import { DatepickerContext } from '../datepicker-context/DatepickerContext';
import { DATEPICKER_DEFAULT_FORMAT, WEEK_DAYS } from '../datepicker-constants';
import { getCalendar } from '../datepicker-tools/getCalendar';

import AppButton from '../../../buttons/appButton/AppButton';
import styles from '../AppDatepicker.module.scss';

const DatepickerCalendar = () => {
  const { inputValue, momentValue, setDateValues } = useContext(DatepickerContext);
  const calendarClickHandler = (date: moment.Moment) => {
    const newDate = moment(date).set({
      hour: momentValue?.hour(),
      minute: momentValue?.minute(),
      second: momentValue?.second(),
    });

    setDateValues(newDate);
  };

  const renderCalendar = () => {
    const cells = [];

    const currentMonth = getCalendar(momentValue);
    const startMonthDay = momentValue?.clone().startOf('month');
    const startMonthIndex = startMonthDay?.clone().weekday();
    const endMonthIndex = momentValue?.clone().daysInMonth();

    for (let i = 0; i < 6; i++) {
      for (let j = 0; j < 7; j++) {
        const index = i * 7 + j;
        const isCurrentMonth =
          index >= startMonthIndex! && index <= endMonthIndex! + (startMonthIndex! - 1);
        const formatDate = currentMonth[index].format(DATEPICKER_DEFAULT_FORMAT);
        const isCurrentDay = inputValue.split(' ')[0] === formatDate;

        cells.push(
          <div key={index} style={{ width: '13%' }}>
            <AppButton
              variant={isCurrentMonth ? 'outline-dark' : 'outline-secondary'}
              className={`w-100 ${isCurrentDay ? 'bg-dark text-white' : ''}`}
              onClick={() => calendarClickHandler(currentMonth[index])}
            >
              <span className="text-center w-100">{currentMonth[index]?.date()}</span>
            </AppButton>
          </div>,
        );
      }
    }

    return cells;
  };

  return (
    <div className="d-flex flex-column row-gap-1">
      <div className="d-flex">
        {WEEK_DAYS.map((day, index) => (
          <div key={index + day} className="text-center fw-bold flex-grow-1">
            {day}
          </div>
        ))}
      </div>
      <div className={styles.cell__wrapper}>{renderCalendar()}</div>
    </div>
  );
};

export default DatepickerCalendar;
