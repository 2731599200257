import { makeAutoObservable } from 'mobx';
import moment from 'moment/moment';
import 'moment/locale/ru';

import {
  IAttendanceCellCoordinates,
  IAttendanceCellPosition,
  IAttendanceCurrentDay,
  IAttendanceDataValue,
  IAttendanceStatus,
  IAttendanceTableData,
  TAbsenceData,
  TAttendanceData,
  TAttendanceDepartments,
  TAttendanceInitialEmployeeData,
} from '../../components/tables/attendanceTable/attendanceTable.types';
import { DATE_FORMAT } from '../../components/tables/attendanceTable/attendance-tools/attendance-constants';
import { ISetAbsenceDurationResponse } from '../../api/socketApi/attendanceTable/setAttendanceAbsenceDuration';

class AttendanceStore {
  attendanceData: TAttendanceData | null = null;
  attendanceDepartments: TAttendanceDepartments | null = null;
  attendanceStatuses: IAttendanceStatus[] = [];
  employeesData: TAttendanceInitialEmployeeData | null = null;
  absenceData: TAbsenceData | null = null;
  attendanceCategories: {
    id: number;
    title: string;
    short_title: string;
    enTitle: string;
  }[] = [];
  isLoading: boolean = false;
  isMobileMenuOpen: boolean = false;

  isStartSelect: boolean = false;
  startCellPosition: IAttendanceCellPosition | null = null;
  endCellPosition: IAttendanceCellPosition | null = null;
  startCellCoordinates: IAttendanceCellCoordinates | null = null;
  endCellCoordinates: IAttendanceCellCoordinates | null = null;

  startYear: number | null = moment().year();
  startMonth: number | null = moment().month();
  currentDate: Date | null = null;
  currentDays: IAttendanceCurrentDay[] = [];

  isAdvance: boolean = false;
  attendanceError: any = null;

  constructor() {
    makeAutoObservable(this);
    this.setCurrentDate();
  }

  setInitialData = (response: IAttendanceTableData) => {
    let iIndexRows = 0;

    const employeesData: TAttendanceInitialEmployeeData = {};

    for (const department in response.departments) {
      for (const user of response.departments[department].items) {
        user.rowIndex = iIndexRows;
        ++iIndexRows;

        if (!employeesData[user.rowIndex]) {
          employeesData[user.rowIndex] = user;
        }
      }
    }

    this.attendanceData = response.attendanceData;
    this.attendanceStatuses = response.attendanceStatuses;
    this.attendanceDepartments = response.departments;
    this.attendanceCategories = response.attendanceCategories;
    this.employeesData = employeesData;
    this.absenceData = response.absenceData;

    this.setIsLoading(false);
  };

  updateAbsenceData = (response: ISetAbsenceDurationResponse) => {
    if (response.action === 'update') {
      const { id_personal, date_absence } = response.absenceData;

      if (!this.absenceData) {
        this.absenceData = {};
      }

      if (this.absenceData?.[id_personal]?.[date_absence]) {
        this.absenceData[id_personal][date_absence] = response.absenceData;
      }

      if (!this.absenceData[id_personal]) {
        this.absenceData[id_personal] = {};
      }

      if (!this.absenceData[id_personal][date_absence]) {
        this.absenceData[id_personal][date_absence] = response.absenceData;
      }
    }

    if (response.action === 'delete') {
      const { id_personal, date_absence } = response.absenceData;
      if (this.absenceData?.[id_personal]?.[date_absence]) {
        delete this.absenceData[id_personal][date_absence];
      }
    }
  };

  setAttendanceError = (value: any) => (this.attendanceError = value);

  isAdvanceToggle = () => (this.isAdvance = !this.isAdvance);
  setIsLoading = (value: boolean) => (this.isLoading = value);
  setIsStartSelect = (value: boolean) => (this.isStartSelect = value);

  setIsMobileMenuOpen = (value: boolean) => {
    this.isMobileMenuOpen = value;

    if (value && document.body.style.overflowY !== 'hidden') {
      document.body.style.overflowY = 'hidden';
    }

    if (!value && document.body.style.overflowY === 'hidden') {
      document.body.style.overflowY = 'auto';
    }
  };

  setCellCoordinates(type: 'start' | 'end', value: IAttendanceCellCoordinates | null) {
    if (type === 'start') this.startCellCoordinates = value;
    if (type === 'end') this.endCellCoordinates = value;
  }

  setCellPosition(type: 'start' | 'end', value: IAttendanceCellPosition | null) {
    if (type === 'start') this.startCellPosition = value;
    if (type === 'end') this.endCellPosition = value;
  }

  setAttendanceDataValues = (dataArray: IAttendanceDataValue[]) => {
    dataArray.forEach((responseData) => {
      if (this.attendanceData) {
        const formattedDate = moment(responseData.obs_date).format(DATE_FORMAT);

        if (!this.attendanceData[responseData.id_personal]) {
          this.attendanceData[responseData.id_personal] = {};
        }

        if (!this.attendanceData[responseData.id_personal][formattedDate]) {
          this.attendanceData[responseData.id_personal][formattedDate] = responseData;
        }

        this.attendanceData[responseData.id_personal][formattedDate] = responseData;
      }
    });
  };

  setStartYear = (value: number) => {
    this.startYear = value;
    this.setCurrentDate();
  };

  setStartMonth = (value: number) => {
    this.startMonth = value;
    this.setCurrentDate();
  };

  private setCurrentDate() {
    const currentMoment = moment()
      .year(this.startYear ?? moment().year())
      .month(this.startMonth ?? moment().month())
      .startOf('month');
    const daysInMonth = currentMoment.daysInMonth();

    moment.locale('ru');

    this.currentDate = currentMoment.toDate();

    this.currentDays = Array.from({ length: daysInMonth }, (_, index) => {
      const dateMoment = currentMoment.clone().date(index + 1);

      return {
        date: dateMoment.format(DATE_FORMAT),
        dayOfWeek: dateMoment.format('dd'),
        dayOfMonth: dateMoment.format('DD'),
        isDateSameOrBefore: dateMoment.isSameOrBefore(moment(), 'day'),
      };
    });
  }

  resetPosition = () => {
    this.setCellPosition('start', null);
    this.setCellPosition('end', null);
    this.setCellCoordinates('start', null);
    this.setCellCoordinates('end', null);
    this.setIsStartSelect(false);
  };
}

export const attendanceStore = new AttendanceStore();
