import { observer } from 'mobx-react-lite';
import { GrClose } from 'react-icons/gr';
import { Form, InputGroup } from 'react-bootstrap';
import InputGroupText from 'react-bootstrap/InputGroupText';
import React, { KeyboardEvent, useCallback, useContext, useEffect, useRef } from 'react';

import { DatepickerContext } from '../datepicker-context/DatepickerContext';
import { screenStore } from '../../../../../store/ScreenStore';
import { DATE_VALID_REGEX } from '../datepicker-constants';
import { getInputDate } from '../datepicker-tools/getInputDate';

import styles from '../AppDatepicker.module.scss';

const DatepickerToggle = observer(() => {
  const { isMobile } = screenStore;
  const { inputValue, isMenuOpen, timeFormat, tableRef, name } = useContext(DatepickerContext);
  const { setDateValues, setInputValue, setIsMenuOpen, setNowDate } = useContext(DatepickerContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const isMobileButton = isMobile && inputValue && isMenuOpen;

  const handleFirstClick = () => {
    if (!inputValue) {
      setNowDate();
    }

    if (!isMenuOpen) {
      setIsMenuOpen(true);
    }
  };

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (DATE_VALID_REGEX.test(value)) {
      setInputValue(value);
    }

    if (!value) {
      setIsMenuOpen(false);
      setDateValues(null);
    }
  };

  const enterSubmitHandler = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setInputValueHandler();
      setIsMenuOpen(false);
    }
  };

  const handleMobileButton = () => {
    if (isMobileButton) {
      setInputValueHandler();
    }
  };

  const setInputValueHandler = useCallback(() => {
    if (timeFormat) {
      const inputDate = getInputDate(timeFormat, inputValue);

      if (inputDate && inputDate.isValid()) {
        setDateValues(inputDate);
      } else {
        setIsMenuOpen(false);
        setDateValues(null);
      }
    }
  }, [inputValue, setIsMenuOpen, setDateValues, timeFormat]);

  const handleReset = () => {
    setDateValues(null);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isInput = inputRef.current?.contains(event.target as Node);
      const isTable = tableRef?.current?.contains(event.target as Node);

      if (!isInput && !isTable) {
        setIsMenuOpen(false);

        if (inputValue) {
          setInputValueHandler(); // Нужен ли?
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [inputValue, setIsMenuOpen, setInputValueHandler, tableRef]);

  return (
    <InputGroup>
      <Form.Control
        id={name}
        className={`${styles.calendar__input} ${inputValue ? 'active__app_input' : ''}`}
        value={inputValue}
        onClick={handleFirstClick}
        onChange={handleChangeValue}
        onKeyDown={enterSubmitHandler}
        autoComplete="off"
        ref={inputRef}
      />

      {inputValue && (
        <GrClose
          className={`${styles.timepicker_reset_button} text-secondary`}
          onClick={handleReset}
        />
      )}

      <InputGroupText
        className={`${styles.calendar__input_group} ${inputValue ? 'active__app_input' : ''} ${isMobileButton ? styles.mobile : ''}`}
        onClick={handleMobileButton}
      >
        {isMobileButton && <i className="bi bi-check-lg text-white"></i>}
        {!isMobileButton && <i className="bi bi-calendar3"></i>}
      </InputGroupText>
    </InputGroup>
  );
});

export default DatepickerToggle;
