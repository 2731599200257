import AttendanceDaysList from './AttendanceDaysList';
import {
  ATTENDANCE_CELL_SIZE,
  ATTENDANCE_RESULT_SIZE,
} from '../attendance-tools/attendance-constants';

const AttendanceHeader = () => {
  return (
    <div className="att__table-header">
      <span className="att__table-index border" style={{ width: ATTENDANCE_CELL_SIZE }}>
        #
      </span>
      <span className="att__table-employee header border">Сотрудник</span>
      <span className="att__table-post border">Должность</span>

      <AttendanceDaysList />

      <span className="att__table-index border" style={{ width: ATTENDANCE_RESULT_SIZE }}>
        Был
      </span>
      <span className="att__table-index border" style={{ width: ATTENDANCE_RESULT_SIZE }}>
        Болел
      </span>
      <span
        className="att__table-index border text-white bg-danger"
        style={{ width: ATTENDANCE_RESULT_SIZE }}
      >
        Отсут.
      </span>
      <span className="att__table-index border" style={{ width: ATTENDANCE_RESULT_SIZE }}>
        Коэф.
      </span>
      <span
        className="att__table-index border text-white bg-danger"
        style={{ width: ATTENDANCE_RESULT_SIZE }}
      >
        Врем.
      </span>
    </div>
  );
};

export default AttendanceHeader;
