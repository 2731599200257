import { useState } from 'react';
import { Tab } from 'react-bootstrap';

import { ETaskTab } from '../../task-types';

import TaskCardNav from './TaskCardNav';
import TaskCardCommentTab from '../comment-tab/TaskCardCommentTab';
import TaskCardReportTab from '../report-tab/TaskCardReportTab';
import TaskCardHistoryTab from '../history-tab/TaskCardHistoryTab';

const TaskCardTabs = () => {
  const [currentTaskTab, setCurrentTaskTab] = useState<ETaskTab>(ETaskTab.comments);

  const handleSelectTab = (currentTab: string | null) => {
    setCurrentTaskTab((currentTab as ETaskTab) ?? ETaskTab.comments);
  };

  return (
    <Tab.Container defaultActiveKey={currentTaskTab} onSelect={handleSelectTab}>
      <TaskCardNav />

      <Tab.Content>
        {currentTaskTab === ETaskTab.comments && <TaskCardCommentTab />}
        {currentTaskTab === ETaskTab.reports && <TaskCardReportTab />}
        {currentTaskTab === ETaskTab.history && <TaskCardHistoryTab />}
      </Tab.Content>
    </Tab.Container>
  );
};

export default TaskCardTabs;
