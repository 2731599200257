import { observer } from 'mobx-react-lite';
import { Skeleton } from 'primereact/skeleton';

import { attendanceStore } from '../../../../store/attendanceStore/AttendanceStore';
import { ATTENDANCE_CELL_SIZE } from '../attendance-tools/attendance-constants';

const AttendanceDaysList = observer(function AttendanceDaysList() {
  const { currentDays, isLoading } = attendanceStore;
  const WIDTH = currentDays.length * ATTENDANCE_CELL_SIZE;

  return (
    <div className="d-flex" style={{ width: `${WIDTH}px`, height: '35px' }}>
      {isLoading && <Skeleton className="h-100 border" />}

      {!isLoading &&
        currentDays?.map((dayData) => {
          const isHoliday = dayData.dayOfWeek === 'вс' || dayData.dayOfWeek === 'сб';

          return (
            <div
              key={dayData.date}
              className={`att__table-header-days border ${isHoliday ? 'border-danger' : ''}`}
              style={{ width: ATTENDANCE_CELL_SIZE }}
            >
              <div className={isHoliday ? 'text-white bg-danger' : ''}>{dayData.dayOfWeek}</div>
              <hr className="m-0" />
              <div className={isHoliday ? 'text-white bg-danger' : ''}>{dayData.dayOfMonth}</div>
            </div>
          );
        })}
    </div>
  );
});

export default AttendanceDaysList;
