import moment from 'moment';
import { attendanceStore } from '../../../../store/attendanceStore/AttendanceStore';
import {
  IAttendanceCurrentDay,
  IAttendanceDepartment,
  IAttendanceEmployee,
  TAttendanceDepartments,
} from '../attendanceTable.types';
import { NO_VALUE_COLOR } from '../attendance-tools/attendance-constants';
import { getAttendanceCategoryValues } from '../attendance-tools/getAttendanceCategoryValues';

import 'moment/locale/ru';

const ATTENDANCE_CELL_SIZE = 25;
const ATTENDANCE_RESULT_SIZE = 35;
export const attendancePrint = (attendanceDepartments: TAttendanceDepartments | null) => {
  moment.locale('ru');
  const {
    startMonth,
    startYear,
    currentDays,
    absenceData,
    attendanceData,
    isAdvance,
    attendanceCategories,
  } = attendanceStore;
  const categoryBarStyle = {
    width: `${ATTENDANCE_RESULT_SIZE}px`,
  };

  const styleToString = (styleObj: Record<string, string>) => {
    return Object.entries(styleObj)
      .map(([key, value]) => `${key}: ${value}`)
      .join('; ');
  };
  // Функция, которая формирует HTML для печати
  const getPrintableHtml = () => {
    const renderTableHeader = () => {
      return `
            <tr>
              <th style="width: 30px">№</th>
              <th style="width: 100px">Сотрудник</th>
              <th style="width: 200px">Должность</th>
              ${renderHeaderMonthDays()}
              <th style="${styleToString(categoryBarStyle)}">Был</th>
              <th style="${styleToString(categoryBarStyle)}">Болел</th>
              <th style="${styleToString(categoryBarStyle)}">Отсут.</th>
              <th style="${styleToString(categoryBarStyle)}">Коэф.</th>
              <th style="${styleToString(categoryBarStyle)}">Врем.</th>
            </tr>`;
    };

    const renderHeaderMonthDays = () => {
      return `${currentDays
        ?.map((day) => `<th style="width: ${ATTENDANCE_CELL_SIZE}px">${day.dayOfMonth}</th>`)
        .join('')}`;
    };

    const renderDepartment = (department: IAttendanceDepartment) => {
      const renderDepartmentTitle = () =>
        department.title
          ? `<tr>
                <td colspan="${8 + (currentDays?.length || 0)}" class="department-title">
                  ${department.title}
                </td>
             </tr>`
          : '';

      return `
      ${renderDepartmentTitle()}
      ${department.items.map((employee, index) => renderEmployeeRow(employee, index)).join('')}`;
    };

    const renderEmployeeRow = (employee: IAttendanceEmployee, index: number) => {
      const employeeSurname = employee?.surname || '';
      const employeeName = employee?.name ? `${employee.name[0].toUpperCase()}.` : '';
      const employeePatronymic = employee.patronymic
        ? `${employee.patronymic[0].toUpperCase()}.`
        : '';
      const employeeShortName = `${employeeSurname} ${employeeName}${employeePatronymic}`;
      const employeeFullName = `${employee?.surname} ${employee?.name} ${employee?.patronymic}`;

      return `
              <tr class="employee-row">
                <td class="att__table-index">${index + 1}</td>
                <td class="att__table-employee ${employee.dissmis ? 'text-danger' : ''}" title="${employeeFullName}">
                   ${employeeShortName}
                   ${employee.dissmis ? '<span>(увол.)</span>' : ''}
                </td>
                <td class="att__table-post" title="${employee.post || ''}">
                   ${employee.post || '-'}
                </td>
                ${renderTableCellList(employee)}
                ${renderCategoryBar(employee)}
              </tr>`;
    };

    const renderTableCellList = (employee: any) => {
      return currentDays?.map((dayData) => renderTableCell(dayData, employee)).join('') || '';
    };

    const renderTableCell = (dayData: IAttendanceCurrentDay, employee: any) => {
      const personalValue = attendanceData?.[employee.id];
      const currentValue = personalValue?.[dayData.date];
      const isNoValue = dayData.isDateSameOrBefore && !currentValue;
      const absenceComment = !!absenceData?.[employee.id]?.[dayData.date];
      const isHideForAdvance = isAdvance && Number(dayData.dayOfMonth) > 15;

      return isHideForAdvance
        ? `<td
      style={{
        // color: statusColor?.textColor;
        width: ATTENDANCE_CELL_SIZE;
        height: ATTENDANCE_CELL_SIZE;
      }}
    />`
        : `<td class="att__table-cell" 
            style="
              width: ${ATTENDANCE_CELL_SIZE}px;
              height: ${ATTENDANCE_CELL_SIZE}px;
              background-color: ${isNoValue ? NO_VALUE_COLOR : currentValue?.v3_attendance_statuses?.bg_color || ''};
              color: ${currentValue?.v3_attendance_statuses?.font_color};
              position: relative;
            "
            title="${currentValue?.v3_attendance_statuses?.title || ''}${absenceComment ? ` | Причина отсутствия: ${absenceComment}` : ''}">
          ${currentValue?.v3_attendance_statuses?.short_title || (isNoValue ? '?' : '')}
          ${absenceComment ? '<div class="att__table-absence_info"/>' : ''}
        </td>`;
    };

    const renderCategoryBar = (employee: any) => {
      const categoryValue = getAttendanceCategoryValues(attendanceCategories, employee);

      return `
      <td class="att__table-index" style="${styleToString(categoryBarStyle)}">${categoryValue.workday ?? '-'}</td>
      <td class="att__table-index" style="${styleToString(categoryBarStyle)}">${categoryValue.sickLeave ?? '-'}</td>
      <td class="att__table-index" style="${styleToString(categoryBarStyle)}">${categoryValue.absence ?? '-'}</td>
      <td class="att__table-index" style="${styleToString(categoryBarStyle)}">${categoryValue.coefficient !== null ? categoryValue.coefficient : '-'}</td>
      <td class="att__table-index" style="${styleToString(categoryBarStyle)}">${categoryValue.timeLeave ?? '-'}</td>
    `;
    };

    return `
    <!DOCTYPE html>
    <html lang="ru">
      <head>
        <title></title>
        <style>
          table { border-collapse: collapse; width: 100%; table-layout: fixed; }
          th, td { border: 1px solid #ddd; }
          .department-title { 
            font-weight: bold; 
            text-align: center;
            background-color: #f2f2f2;
          }
          .employee-row td {
            vertical-align: middle;
          }
          .text-danger {
            color: #dc3545;
          }
          .att__table-index {
            text-align: center;
          }
          .att__table-employee {
            width: 100px;
            padding: 0 .25rem 0;
          }
          .att__table-post {
            width: 200px;
            padding: 0 .25rem 0;
          }
          .att__table-cell {
            text-align: center;
            position: relative;
          }
          .att__table-absence_info {
          position: absolute; top: 0; right: 0; width: 0; height: 0; border-left: 8px solid transparent; border-top: 8px solid red;
          }
          @page {
            size: auto;  /* Автоматический размер */
            margin: 5mm 8mm 5mm 8mm; /* Минимальные поля */
          }
          @media print {
            body { zoom: 60%; margin: 0 !important;padding: 0 !important; }
            table { font-size: 13px; }
            table {
              page-break-inside: auto;
            }
            tr {
              page-break-inside: avoid;
              page-break-after: auto;
            }
          }
        </style>
      </head>
      <body>
        <h1 style="margin: 0 .5rem .5rem;">Отчет по посещаемости за <span style="text-transform: capitalize">${moment()
          .month(startMonth ?? 0)
          .format('MMMM')}</span> ${startYear}</h1>
        <p style="margin: .5rem;">Сформирован: ${new Date().toLocaleDateString()}</p>
        <table>
          <thead>
            ${renderTableHeader()}
          </thead>
          <tbody>
            ${Object.values(attendanceDepartments ?? {})
              .map((department) => renderDepartment(department))
              .join('')}
          </tbody>
        </table>
      </body>
    </html>
  `;
  };

  // Функция для открытия окна печати
  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    printWindow?.document.write(getPrintableHtml());
    printWindow?.document.close();
    printWindow?.focus();
    printWindow?.print();
  };

  return { handlePrint };
};
