import React from 'react';
import moment from 'moment';
import { EDatepickerTimeFormat, IDatepickerTime } from '../datepicker.types';

interface IDatepickerContext {
  inputValue: string;
  name?: string;
  momentValue: moment.Moment | null;
  isMenuOpen: boolean;
  isMonthPickerShow: boolean;
  isYearPickerShow: boolean;
  timeList: IDatepickerTime[];
  yearArray: moment.Moment[];
  timeFormat?: keyof typeof EDatepickerTimeFormat;
  tableRef: React.RefObject<HTMLDivElement | null> | null;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDateValues: (momentValue: moment.Moment | null) => void;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  setMomentValue: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
  setNowDate: () => void;
  monthPickerShowToggle: () => void;
  yearPickerShowToggle: () => void;
  disableRange?: 'year' | 'month';
}

export const DatepickerContext = React.createContext<IDatepickerContext>({
  name: '',
  disableRange: undefined,
  inputValue: '',
  momentValue: null,
  isMenuOpen: false,
  isMonthPickerShow: false,
  isYearPickerShow: false,
  timeFormat: undefined,
  tableRef: null,
  timeList: [],
  yearArray: [],
  setIsMenuOpen: () => {},
  setDateValues: () => {},
  setInputValue: () => {},
  setMomentValue: () => {},
  setNowDate: () => {},
  monthPickerShowToggle: () => {},
  yearPickerShowToggle: () => {},
});
