import { Spinner } from 'react-bootstrap';

const CardFallbackSpinner = () => {
  return (
    <div className="w-100 d-flex align-items-center justify-content-center flex-grow-1">
      <Spinner variant="primary" animation="border" role="status" />
    </div>
  );
};

export default CardFallbackSpinner;
