import { observer } from 'mobx-react-lite';

import { IAttendanceEmployee } from '../attendanceTable.types';

import { ATTENDANCE_CELL_SIZE } from '../attendance-tools/attendance-constants';

import AttendanceCellsList from './AttendanceCellsList';

interface IEmployeeRowProps {
  employee: IAttendanceEmployee;
  index: number;
}

const AttendanceEmployeeRow = observer(function EmployeeRow({
  employee,
  index,
}: IEmployeeRowProps) {
  const employeeSurname = employee?.surname || '';
  const employeeName = employee?.name ? `${employee.name[0].toUpperCase()}.` : '';
  const employeePatronymic = employee.patronymic ? `${employee.patronymic[0].toUpperCase()}.` : '';
  const employeeShortName = `${employeeSurname} ${employeeName} ${employeePatronymic}`;
  const employeeFullName = `${employee?.surname} ${employee?.name} ${employee?.patronymic}`;

  return (
    <div className="d-flex flex-grow-1">
      <div className="att__table-index border" style={{ width: ATTENDANCE_CELL_SIZE }}>
        {index + 1}
      </div>

      <div
        title={employeeFullName}
        className={`att__table-employee border ${employee.dissmis ? 'text-danger' : ''}`}
      >
        <span>{employeeShortName}</span>
        {employee.dissmis && <span className="ms-1">(увол.)</span>}
      </div>

      <div title={employee.post} className="att__table-post border">
        <div className="att__table-post post-title">{employee.post}</div>
      </div>

      <AttendanceCellsList {...{ employee }} />
    </div>
  );
});

export default AttendanceEmployeeRow;
