import { makeAutoObservable } from 'mobx';

import {
  ETaskFilterPresets,
  IChangeTaskExecutorResponse,
  IChangeTaskObserverResponse,
  ITaskCardData,
  ITaskComment,
  ITaskItem,
  ITaskItemsFilterProps,
  ITasksItemsResponse,
  ITaskStatus,
} from '../../components/tasks/task-types';

class TaskStore {
  taskList: ITaskItem[] = [];
  taskCardData: ITaskCardData | null = null;
  nextPage: number | undefined = undefined;
  isItemsLoading: boolean = false;
  isNextItemsLoading: boolean = false;
  isCardLoading: boolean = false;
  taskItemsFilter: ITaskItemsFilterProps = {
    searchQuery: undefined,
    presets: ETaskFilterPresets.incoming,
  };

  constructor() {
    makeAutoObservable(this);
  }

  setTaskItemFilterValue = <K extends keyof ITaskItemsFilterProps>(
    key: K,
    value: ITaskItemsFilterProps[K],
  ) => {
    this.taskItemsFilter[key] = value;
  };

  setIsItemsLoading = (value: boolean) => (this.isItemsLoading = value);
  setIsNextItemsLoading = (value: boolean) => (this.isNextItemsLoading = value);
  setIsCardLoading = (value: boolean) => (this.isCardLoading = value);
  setNextPage = (value: number | undefined) => (this.nextPage = value);

  setTaskItemsData = (response: ITasksItemsResponse) => {
    this.resetItemsData();
    this.taskList = response.items;
    this.nextPage = response.page;
    this.setIsItemsLoading(false);
  };

  setNextItemsData = (response: ITasksItemsResponse) => {
    this.taskList = this.taskList.concat(response.items);
    this.nextPage = response.page;
    this.setIsNextItemsLoading(false);
  };

  setCardData = (response: ITaskCardData) => {
    console.log(response);
    this.resetCardData();
    this.taskCardData = response;
    this.setIsCardLoading(false);
  };

  addExecutors = (response: IChangeTaskExecutorResponse) => {
    if (this.taskCardData) {
      this.taskCardData.cardData.task_executors = this.taskCardData.cardData.task_executors?.concat(
        response.executors,
      );
      this.taskCardData.departmentsData = response.newDepartmentData;
    }
  };

  addObserver = (response: IChangeTaskObserverResponse) => {
    if (this.taskCardData) {
      this.taskCardData.cardData.task_observers = this.taskCardData.cardData.task_observers?.concat(
        response.observers,
      );

      this.taskCardData.departmentsData = response.newDepartmentData;
    }
  };

  updateDeadLine = ({ date_deadline }: { date_deadline: string }) => {
    if (this.taskCardData) {
      this.taskCardData.cardData.date_deadline = date_deadline;
    }
  };

  addNewComment = (newComment: ITaskComment) => {
    if (this.taskCardData) {
      this.taskCardData.cardData.commentData.push(newComment);
    }
  };

  updateExecutorStatus(response: { id_user: number; status: ITaskStatus }) {
    if (this.taskCardData) {
      const currentExecutor = this.taskCardData.cardData.task_executors?.find(
        (executor) => executor.id_crm === response.id_user,
      );
      if (currentExecutor) {
        currentExecutor.status = response.status;
      }
    }
  }

  private resetItemsData = () => {
    this.taskList = [];
    this.nextPage = undefined;
  };

  private resetCardData = () => (this.taskCardData = null);
}

export const taskStore = new TaskStore();
