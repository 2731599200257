import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { screenStore } from '../../../../store/ScreenStore';
import './appButton.scss';

interface ITestButtonProps {
  children: React.ReactNode;
  className?: string;
  variant?:
    | 'primary'
    | 'outline-primary'
    | 'secondary'
    | 'outline-secondary'
    | 'success'
    | 'outline-success'
    | 'danger'
    | 'outline-danger'
    | 'dark'
    | 'outline-dark'
    | 'warning'
    | 'outline-warning';
  size?: 'sm' | 'lg';

  [key: string]: any;
}

const COLOR_WHITE = '#fff';
const PRIMARY_COLOR = '#174699';
const PRIMARY_COLOR_TOUCH = '#143c82';
const SECONDARY_COLOR = '#6c757d';
const SECONDARY_COLOR_TOUCH = '#565e64';
const SUCCESS_COLOR = '#198754';
const SUCCESS_COLOR_TOUCH = '#146c43';
const DANGER_COLOR = '#dc3545';
const DANGER_COLOR_TOUCH = '#b02a37';
const DARK_COLOR = '#212529';
const DARK_COLOR_TOUCH = '#373b3e';
const WARNING_COLOR = '#ffc107';
const WARNING_COLOR_TOUCH = '#ffca2c';

const AppButton = observer(function AppButton({
  children,
  className,
  variant = 'primary',
  size = 'sm',
  ...rest
}: ITestButtonProps) {
  const [isTouched, setIsTouched] = useState(false);
  const { isMobile } = screenStore;
  const colorVariant = variant as keyof typeof VARIANT_COLOR;

  const VARIANT_COLOR = {
    primary: {
      text: COLOR_WHITE,
      background: isTouched ? PRIMARY_COLOR_TOUCH : PRIMARY_COLOR,
      border: PRIMARY_COLOR,
    },
    'outline-primary': {
      text: isTouched ? COLOR_WHITE : PRIMARY_COLOR,
      background: isTouched ? PRIMARY_COLOR : 'transparent',
      border: PRIMARY_COLOR,
    },
    secondary: {
      text: COLOR_WHITE,
      background: isTouched ? SECONDARY_COLOR_TOUCH : SECONDARY_COLOR,
      border: SECONDARY_COLOR,
    },
    'outline-secondary': {
      text: isTouched ? COLOR_WHITE : SECONDARY_COLOR,
      background: isTouched ? SECONDARY_COLOR : 'transparent',
      border: SECONDARY_COLOR,
    },
    success: {
      text: COLOR_WHITE,
      background: isTouched ? SUCCESS_COLOR_TOUCH : SUCCESS_COLOR,
      border: SUCCESS_COLOR,
    },
    'outline-success': {
      text: isTouched ? COLOR_WHITE : SUCCESS_COLOR,
      background: isTouched ? SUCCESS_COLOR : 'transparent',
      border: SUCCESS_COLOR,
    },
    danger: {
      text: COLOR_WHITE,
      background: isTouched ? DANGER_COLOR_TOUCH : DANGER_COLOR,
      border: DANGER_COLOR,
    },
    'outline-danger': {
      text: isTouched ? COLOR_WHITE : DANGER_COLOR,
      background: isTouched ? DANGER_COLOR : 'transparent',
      border: DANGER_COLOR,
    },
    dark: {
      text: COLOR_WHITE,
      background: isTouched ? DARK_COLOR_TOUCH : DARK_COLOR,
      border: DARK_COLOR,
    },
    'outline-dark': {
      text: isTouched ? COLOR_WHITE : DARK_COLOR,
      background: isTouched ? DARK_COLOR : 'transparent',
      border: DARK_COLOR,
    },
    warning: {
      text: DARK_COLOR,
      background: isTouched ? WARNING_COLOR_TOUCH : WARNING_COLOR,
      border: WARNING_COLOR,
    },
    'outline-warning': {
      text: isTouched ? DARK_COLOR : WARNING_COLOR,
      background: isTouched ? WARNING_COLOR : 'transparent',
      border: WARNING_COLOR,
    },
  };

  const BASE_STYLES = {
    border: '1px solid',
    padding: size === 'sm' ? '6px .75rem' : '.5rem 1rem',
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: size === 'sm' ? '.8rem' : '1rem',
    color: VARIANT_COLOR[colorVariant].text,
    borderColor: VARIANT_COLOR[colorVariant].border,
    backgroundColor: VARIANT_COLOR[colorVariant].background,
    borderRadius: '4px',
    opacity: rest.disabled ? 0.7 : 1,
  };

  return (
    <button
      className={`app__button${!isMobile ? `_${variant}` : ''} ${className ? className : ''}`}
      style={{ ...BASE_STYLES }}
      onTouchStart={() => isMobile && setIsTouched(true)}
      onTouchEnd={() => isMobile && setIsTouched(false)}
      type="button"
      {...rest}
    >
      {children}
    </button>
  );
});

export default AppButton;
