import './attendance-table.scss';
import { observer } from 'mobx-react-lite';

import { socketApiStore } from '../../../store/socketApiStore/SocketApiStore';

import SocketDisconnectWarning from '../../helpers/SocketDisconnectWarning';
import AttendanceDateBar from './attendance-components/AttendanceDateBar';
import AttendanceHeader from './attendance-components/AttendanceHeader';
import AttendanceBody from './attendance-components/AttendanceBody';
import { useListenSocketErrors } from '../../../api/socketApi/errors/useListenSocketErrors';
import { attendanceStore } from '../../../store/attendanceStore/AttendanceStore';
import { Alert } from 'react-bootstrap';
import { screenStore } from '../../../store/ScreenStore';
import AttendanceMobileBody from './attendance-components/attendance-mobile/AttendanceMobileBody';
import { useAttendanceTableInit } from '../../../api/socketApi/attendanceTable/useAttendanceTableInit';
import { useBroadcastAttendanceTable } from '../../../api/socketApi/attendanceTable/useBroadcastAttendanceTable';

const AttendanceTable = observer(function AttendanceTable() {
  const { isSocketLoaded } = socketApiStore;
  const { attendanceError } = attendanceStore;
  const { isMobile } = screenStore;

  useListenSocketErrors();
  useAttendanceTableInit();
  useBroadcastAttendanceTable();

  return (
    <>
      <SocketDisconnectWarning />
      {isSocketLoaded && !attendanceError && (
        <>
          <AttendanceDateBar />
          {!isMobile && (
            <>
              <AttendanceHeader />
              <AttendanceBody />
            </>
          )}
          {isMobile && <AttendanceMobileBody />}
        </>
      )}

      {attendanceError && <Alert variant="danger">{attendanceError}</Alert>}
    </>
  );
});

export default AttendanceTable;
